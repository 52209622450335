import { Component, OnInit } from '@angular/core';
import { ModalService } from 'angular-modal-simple';
import { finalize, Observable } from 'rxjs';
import { SessionService } from '../../../core/state/session/session.service';
import { SessionQuery } from '../../../core/state/session/session.query';
import { TranslationsService } from '../../../core/services/translations/translations.service';
import { Device } from '../../../core/state/session/session.interface';

@Component({
  selector: 'app-manage-devices-modal',
  templateUrl: './manage-devices-modal.component.html',
  styleUrls: ['./manage-devices-modal.component.scss'],
})
export class ManageDevicesModalComponent implements OnInit {
  submitting = false;

  loading = false;

  devices$: Observable<Device[]>;

  constructor(
    private modalService: ModalService,
    private sessionService: SessionService,
    private sessionQuery: SessionQuery,
    private translationsService: TranslationsService
  ) {
    this.devices$ = this.sessionQuery.devices$;
  }

  ngOnInit() {
    this.loadDevices();
  }

  remove(item, skip = false) {
    if (this.submitting) {
      return;
    }

    const msg = this.translationsService.getInstantTranslation('GENERAL.DELETE_ALERT');
    const confirm = skip || window.confirm(msg);

    if (confirm) {
      this.submitting = true;

      this.sessionService
        .deleteDevice(item.id)
        .pipe(
          finalize(() => {
            this.submitting = false;
          })
        )
        .subscribe();
    }
  }

  dismiss() {
    return this.modalService.close(ManageDevicesModalComponent);
  }

  private loadDevices() {
    this.loading = true;
    this.sessionService
      .getDevices()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe();
  }
}
