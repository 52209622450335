import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ModalService } from 'angular-modal-simple';
import { CookieStore } from './cookie.store';
import { ToastService } from '../../../toasts/services/toast-service/toast.service';
import { CookieQuery } from './cookie.query';
// eslint-disable-next-line import/no-cycle
import { CookieModalComponent } from '../../modals/cookie-modal/cookie-modal.component';

@Injectable({ providedIn: 'root' })
export class CookieService {
  constructor(
    @Inject(DOCUMENT) private document: any,
    private cookieStore: CookieStore,
    private cookieQuery: CookieQuery,
    private toastService: ToastService,
    private modalService: ModalService
  ) {}

  async init() {
    const hasAnswered = this.cookieQuery.answered();

    if (!hasAnswered) {
      this.askConsent();
      return;
    }

    this.setCookies();
  }

  enable() {
    this.cookieStore.update({ enabled: true });
    this.setCookies();
  }

  disable() {
    this.cookieStore.update({ enabled: false });
    this.setCookies();
  }

  private async askConsent() {
    const message = 'We use cookies to improve your experience';
    const acceptAll = 'Accept All';
    const detailsAll = 'Learn More';

    setTimeout(() => {
      this.toastService.info(message, {
        timeout: 0,
        button: acceptAll,
        button_alt: detailsAll,
        onClick: () => {
          this.enable();
        },
        onClickAlt: () => {
          this.modalService.display(CookieModalComponent);
        },
      });
    }, 2000);
  }

  private setCookies() {
    const isEnabled = this.cookieQuery.enabled();

    if (isEnabled) {
      return;
    }

    this.deleteAll();
  }

  // get all avaialble cookies
  private getAll(): { [key: string]: string } {
    const cookies: { [key: string]: string } = {};
    const { document } = this;

    if (document.cookie && document.cookie !== '') {
      document.cookie.split(';').forEach((currentCookie) => {
        const [cookieName, cookieValue] = currentCookie.split('=');
        cookies[this.safeDecodeURIComponent(cookieName.replace(/^ /, ''))] =
          this.safeDecodeURIComponent(cookieValue);
      });
    }

    return cookies;
  }

  // delete all cookies
  private deleteAll(ignoreList: string[] = []) {
    const cookies: any = this.getAll();

    ignoreList.forEach((item) => {
      delete cookies[item];
    });

    for (const key of Object.keys(cookies)) {
      this.delete(key);
    }
  }

  // set a cookie
  private set(name: string, value: string, expirationDate?: Date): void {
    let cookieString: string = `${encodeURIComponent(name)}=${encodeURIComponent(value)};`;

    if (expirationDate) {
      cookieString += `expires=${expirationDate.toUTCString()};`;
    }

    this.document.cookie = cookieString;
  }

  // delete a cookie
  private delete(name: string): void {
    this.set(name, '', new Date('Thu, 01 Jan 1970 00:00:01 GMT'));
  }

  // decodes URI
  private safeDecodeURIComponent(encodedURIComponent: string): string {
    try {
      return decodeURIComponent(encodedURIComponent);
    } catch {
      return encodedURIComponent;
    }
  }
}
