import { Inject, Injectable } from '@angular/core';
import * as Rollbar from 'rollbar';
import { environment } from '../../../../environments/environment';
import { EventCategory, EventName } from '../analytics/analytics.interface';
import { AnalyticsService } from '../analytics/analytics.service';
import { RollbarService } from '../rollbar/rollbar.service';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    private analyticsService: AnalyticsService
  ) {}

  public debug(payload: any): void {
    if (this.isEnabled()) {
      this.rollbar.debug(payload);
    }
  }

  public info(payload: any): void {
    if (this.isEnabled()) {
      this.analyticsService.track({
        name: EventName.Info,
        category: EventCategory.General,
        action: 'Info',
        label: payload,
      });

      this.rollbar.info(payload);
    }
  }

  public warning(payload: any): void {
    if (this.isEnabled()) {
      this.analyticsService.track({
        name: EventName.Warning,
        category: EventCategory.General,
        action: 'Info',
        label: payload,
      });

      this.rollbar.warning(payload);
    }
  }

  public error(payload: any): void {
    if (this.shouldDiscardPayload(payload)) {
      return;
    }

    if (this.isEnabled()) {
      this.analyticsService.error(payload, false);
      this.rollbar.error(payload?.message || payload);
    }
  }

  public critical(payload: any): void {
    if (this.shouldDiscardPayload(payload)) {
      return;
    }

    if (this.isEnabled()) {
      this.analyticsService.error(payload, true);
      this.rollbar.error(payload?.message || payload);
    }
  }

  private shouldDiscardPayload(payload: any) {
    // ignore auth and offline errors
    if ([0, 400, 401].indexOf(payload?.original?.status) > -1) {
      return true;
    }

    return false;
  }

  private isEnabled() {
    return environment.log.enabled;
  }
}
