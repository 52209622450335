import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { PopoverService } from './popover.service';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent {
  @ViewChild('list', { read: ElementRef, static: false }) list: ElementRef;

  @ViewChild('container', { read: ElementRef, static: false }) container: ElementRef;

  x: string;

  y: string;

  isBottom = false;

  sub: Subscription = null;

  constructor(public popoverService: PopoverService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.sub = this.popoverService.onOpen.subscribe(({ x, y, options }) => {
      if (!x || !y) {
        this.x = '-1000px';
        this.y = '-1000px';
        return;
      }

      if (options) {
        this.cdr.detectChanges();
      }

      this.calculatePos();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  close() {
    this.popoverService.close();
  }

  calculatePos() {
    let { x, y } = this.popoverService;
    const { width, height } = this.popoverService;
    const element = this.list.nativeElement;

    if (!element) {
      return;
    }

    const eWidth = element.offsetWidth / 2;
    const eHeight = element.offsetHeight;

    this.isBottom = false;

    if (x + eWidth > window.innerWidth) {
      x -= width / 2;
    } else {
      x += width / 2;
    }

    if (y + eHeight > window.innerHeight) {
      y -= eHeight + 5 + height;
      this.isBottom = true;
    } else {
      y += 10 + height;
    }

    if (x < 10) {
      x = 10;
    }

    if (y < 10) {
      y = 10;
    }

    this.x = `${x}px`;
    this.y = `${y}px`;
  }
}
