<button class="c-btn" [class.c-btn--lg]="size === 'lg'" [class.c-btn--sm]="size === 'sm'"
  [class.c-btn--icon]="!!icon" [class.c-btn--secondary]="variant === 'secondary'"
  [class.c-btn--terciary]="variant === 'terciary'" [class.c-btn--delete]="variant === 'delete'"
  [type]="type" [disabled]="disabled || loading">
  <span *ngIf="loading" class="c-btn__loading">
    <app-loading size="bare" class="c-btn__spinner"></app-loading>
  </span>

  <div [style.visibility]="loading ? 'hidden' : 'visible'">
    <app-icon class="c-btn__icon" *ngIf="!loading && icon" [name]="icon"></app-icon>
    <ng-content></ng-content>
  </div>

  <app-icon class="c-btn__arrow" *ngIf="hasArrow" name="chevron-forward-outline"></app-icon>
</button>