import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'locationTranslate',
})
export class LocationTranslatePipe implements PipeTransform {
  hash = {
    'us-based': 'US Based',
    international: 'International',
  };

  transform(value: any): any {
    return this.hash[value] || 'N/A';
  }
}
