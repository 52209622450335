<app-modal (close)="dismiss()">
  <h3 class="c-modal__title">
    {{ 'COOKIES.MODAL.TITLE' | translate }}
  </h3>

  <p class="c-modal__description">
    {{ 'COOKIES.MODAL.DESCRIPTION' | translate }}
  </p>

  <a [routerLink]="['/', 'tos']" class="c-link">
    {{ 'COOKIES.MODAL.PRIVACY_LINK' | translate }}
  </a>

  <div class="c-modal__footer">
    <app-button (click)="decline()" variant="secondary">
      {{ 'COOKIES.DECLINE_ALL_CTA' | translate }}
    </app-button>

    <app-button class="c-modal__btn" (click)="accept()">
      {{ 'COOKIES.ACCEPT_ALL_CTA' | translate }}
    </app-button>
  </div>
</app-modal>