import { Injectable } from '@angular/core';
import { Query, toBoolean } from '@datorama/akita';
import { subSeconds } from 'date-fns';
import { SessionStore, SessionState } from './session.store';

@Injectable({ providedIn: 'root' })
export class SessionQuery extends Query<SessionState> {
  isLoggedIn$ = this.select((state) => toBoolean(state.session.access_token));

  session$ = this.select((state) => state.session);

  user$ = this.select((state) => state.user);

  partners$ = this.select((state) => state.partners);

  devices$ = this.select((state) => state.devices);

  deviceFingerprint$ = this.select((state) => state.device_fingerprint);

  constructor(protected store: SessionStore) {
    super(store);
  }

  isLoggedIn(): boolean {
    return toBoolean(this.getValue().session.access_token);
  }

  isAdmin(): boolean {
    const { user } = this.getValue();
    return !user?.managed_by_partner && user?.is_admin;
  }

  getSession() {
    return this.getValue().session;
  }

  getUser() {
    return this.getValue().user;
  }

  getPartners() {
    return this.getValue().partners;
  }

  getDevices() {
    return this.getValue().devices;
  }

  getDeviceFingerprint() {
    return this.getValue().device_fingerprint;
  }

  getSigKey(offset = 0) {
    const sig = subSeconds(new Date(), offset);
    return sig.toISOString();
  }
}
