<div class="c-field" [class.c-field--disabled]="isDisabled">
  <input class="c-field__input" [type]="showPassword ? 'text' : 'password'"
    [autocomplete]="autocomplete" [placeholder]="placeholder || ''"
    [formControl]="ngControl.control" />

  <h6 class="c-field__label">
    {{ label | translate }}
  </h6>

  <a class="c-field__icon c-field__icon--right" (click)="togglePasswordVisibility()">
    <app-icon [name]="showPassword ? 'eye-off-outline' : 'eye-outline'"
      [title]="showPassword ? 'FIELDS.PASSWORD.HIDE_PASSWORD_VISIBILITY' : 'FIELDS.PASSWORD.SHOW_PASSWORD_VISIBILITY'">
    </app-icon>
  </a>

  <app-field-error-message class="c-field__notes" [currentControl]="ngControl.control">
  </app-field-error-message>

</div>