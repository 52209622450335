import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  @Input() name: string;

  @Input() src: string;

  @Input() size: 'md' | 'sm2' | 'sm' | 'xs' | 'full' = 'md';

  @Input() loading = false;

  @Input() online = false;

  avatarPlaceholder = '';

  ngOnInit() {
    if (this.name) {
      const words = this.name.split(' ');
      this.avatarPlaceholder = `${words[0]?.charAt(0)}${words?.pop().charAt(0)}`;
    }
  }
}
