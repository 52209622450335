import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { prettyPrintJson } from 'pretty-print-json';

@Pipe({
  name: 'code',
})
export class CodePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any): any {
    // covers codeblocks within <pre> tags
    const regex = /<pre>(.*?)<\/pre>/gms;

    let rtn = this.decodeHtml(value);
    let codeblocks = [];
    if (rtn.match(regex)) {
      [, ...codeblocks] = regex.exec(rtn);
    }

    if (codeblocks.length > 0) {
      for (const codeblock of codeblocks) {
        const code = JSON.parse(codeblock);
        const prettyCode = prettyPrintJson.toHtml(code, { lineNumbers: true, quoteKeys: true });
        rtn = rtn.replace(codeblock, `${prettyCode}`);
      }
    }

    return this.sanitizer.bypassSecurityTrustHtml(rtn);
  }

  private decodeHtml(html) {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }
}
