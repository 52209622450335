export enum EventCategory {
  General = 'general',
  Home = 'home',
  About = 'about',
  Careers = 'careers',
  Contact = 'contact',
  Platform = 'platform',
  Tos = 'tos',
  Register = 'register',
  Login = 'login',
  Dashboard = 'dashboard',
  Privacy = 'privacy',
  Faq = 'faq',
  Security = 'security',
}

export enum EventName {
  PageView = 'page_view',
  Login = 'login',
  Logout = 'logout',
  PasswordReset = 'password_reset',
  Search = 'search',
  SignUp = 'sign_up',
  Action = 'action',
  ViewItem = 'view_item',
  ViewItemList = 'view_item_list',
  ViewSearchResults = 'view_search_results',
  Warning = 'warning',
  Info = 'info',
}

export interface EventPayload {
  name: EventName;
  category?: EventCategory;
  label?: string;
  action?: string;
  value?: any;
}
