import { FormGroup } from '@angular/forms';

export function passwordMatchValidator(group: FormGroup) {
  const newPassword = group.get('password');
  const passwordConfirmation = group.get('password_confirmation');

  if (newPassword.pristine || passwordConfirmation.pristine) {
    return null;
  }

  if (newPassword.invalid || passwordConfirmation.invalid) {
    return null;
  }

  return newPassword.value === passwordConfirmation.value ? null : { notSame: true };
}
