import { Injectable } from '@angular/core';
import { enableAkitaProdMode, persistState, resetStores, PersistState } from '@datorama/akita';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  akitaStorage: PersistState;

  init() {
    if (environment.production) {
      enableAkitaProdMode();
    }

    this.persistStorage();
  }

  persistStorage() {
    if (!this.akitaStorage) {
      this.akitaStorage = persistState({
        key: 'MDWebStorage',
      });
    }
  }

  destroyStorage() {
    if (this.akitaStorage) {
      this.akitaStorage.destroy();
      this.akitaStorage = undefined;
    }
  }

  clearAll() {
    resetStores();
    if (this.akitaStorage) {
      this.akitaStorage.clearStore();
    }
  }
}
