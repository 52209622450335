import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import {
  Contact,
  Partner,
  Credential,
  SupportStaff,
  PanelUser,
  Documentation,
  Parameter,
  Service,
  Questionnaire,
  Medication,
  Compound,
  Invoice,
  Clinician,
  Supply,
  Voucher,
  Ticket,
  Webhook,
  PartnerFile,
  Review,
  EmailDomain,
  ShopifyProduct,
  ShopifyAssociation,
  PartnerStatistics,
} from './partner.interface';
import {
  Category,
  EmailTemplate,
  Specialty,
  Tag,
} from '../../../core/state/metadata/metadata.interface';

export interface PartnerState {
  id: string;
  module: 'partners' | 'specialties';
  partner: Partner;
  contacts: Contact[];
  credentials: Credential[];
  panelUsers: PanelUser[];
  supportStaffs: SupportStaff[];
  categories: Category[];
  services: Service[];
  questionnaires: Questionnaire[];
  medications: Medication[];
  compounds: Compound[];
  supplies: Supply[];
  invoices: Invoice[];
  clinicians: Clinician[];
  vouchers: Voucher[];
  tickets: Ticket[];
  specialties: Specialty[];
  parameters: { [key: string]: Parameter };
  documentations: { [key: string]: Documentation };
  domain: EmailDomain;
  shopify: {
    products: ShopifyProduct[];
    associations: ShopifyAssociation[];
  };
  statistics: PartnerStatistics;
  webhooks: Webhook[];
  tags: Tag[];
  files: PartnerFile[];
  reviews: Review<any>[];
  emailTemplates: EmailTemplate[];
}

export function createInitialState(): PartnerState {
  return {
    id: null,
    module: 'partners',
    partner: null,
    contacts: [],
    credentials: [],
    panelUsers: [],
    supportStaffs: [],
    categories: [],
    services: [],
    questionnaires: [],
    medications: [],
    compounds: [],
    supplies: [],
    invoices: [],
    clinicians: [],
    vouchers: [],
    tickets: [],
    specialties: [],
    parameters: {},
    documentations: {},
    webhooks: [],
    tags: [],
    files: [],
    reviews: [],
    statistics: {
      cache: {
        date: null,
        startDate: null,
        endDate: null,
        partner_id: null,
      },
    },
    domain: null,
    shopify: { products: [], associations: [] },
    emailTemplates: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'partner', resettable: true })
export class PartnerStore extends Store<PartnerState> {
  constructor() {
    super(createInitialState());
  }
}
