import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalService } from 'angular-modal-simple';
import { finalize, tap } from 'rxjs';
import { Ticket } from '../../state/partner.interface';
import { PartnerQuery, PartnerService } from '../../state';
import { TicketDetailsModalComponent } from '../ticket-details-modal/ticket-details-modal.component';
import { SessionQuery } from '../../../../core/state/session/session.query';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-create-ticket-modal',
  templateUrl: './create-ticket-modal.component.html',
  styleUrls: ['./create-ticket-modal.component.scss'],
})
export class CreateTicketModalComponent implements OnInit {
  partnerId: string;

  form = new FormGroup({
    title: new FormControl(null, Validators.required),
    description: new FormControl(null, Validators.required),
    type: new FormControl(null, Validators.required),
    product: new FormControl(null, Validators.required),
    is_urgent: new FormControl(false, Validators.required),
    requester_email: new FormControl(null, Validators.required),
  });

  submitting = false;

  types = [
    { label: 'Bug Report', value: 'bug-report' },
    { label: 'Feature Request', value: 'feature-suggestion' },
    { label: 'Help Request', value: 'question-help' },
    { label: 'Other', value: 'other' },
  ];

  products = [
    { label: 'API', value: 'api' },
    { label: 'Clinicians App', value: 'clinician-portal' },
    { label: 'Admin Panel', value: 'admin-panel' },
    { label: 'Intake Form', value: 'messaging-app' },
    { label: 'Messaging App', value: 'messaging-app' },
    { label: 'API Documentation', value: 'api-documentation' },
    { label: 'Other', value: 'other' },
  ];

  constructor(
    private modalService: ModalService,
    private partnerService: PartnerService,
    private partnerQuery: PartnerQuery,
    private sessionQuery: SessionQuery
  ) {}

  ngOnInit() {
    this.partnerId = this.partnerQuery.getPartnerId();

    const { email } = this.sessionQuery.getUser();
    this.form.get('requester_email').patchValue(email);
  }

  submit() {
    if (this.submitting || this.form.invalid) {
      return;
    }

    this.submitting = true;

    const { value } = this.form;

    const payload = {
      title: value.title,
      description: value.description,
      type: value.type,
      environment: environment.production ? 'production' : 'not-applicable',
      product: value.product,
      is_urgent: value.is_urgent,
      requester_email: value.requester_email,
    };

    this.partnerService
      .postTicket(this.partnerId, payload)
      .pipe(
        tap((response) => {
          this.submitting = false;

          this.dismiss();
          this.openTicket(response);
        }),
        finalize(() => {
          this.submitting = false;
        })
      )
      .subscribe();
  }

  dismiss() {
    if (this.submitting) {
      return null;
    }

    return this.modalService.close(CreateTicketModalComponent);
  }

  openTicket(ticket: Ticket) {
    this.modalService.display(TicketDetailsModalComponent, {
      data: {
        ticket,
      },
    });
  }
}
