import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
  @ViewChild('badge') badge;

  currentYear: number = null;

  appstoreURL = environment.config.appstoreURL;

  playstoreURL = environment.config.playstoreURL;

  ngOnInit() {
    const todayDate = new Date();
    this.currentYear = todayDate.getFullYear();
  }
}
