<app-modal (close)="dismiss()">
  <h3 class="c-modal__title">
    {{ 'MODALS.CHANGE_PASSWORD.TITLE' | translate }}
  </h3>

  <form [formGroup]="form">
    <app-field-password class="u-margin-bottom--md" label="MODALS.CHANGE_PASSWORD.PASSWORD"
      formControlName="password" [validatePasswordStrength]="true">
    </app-field-password>

    <app-field-password label="MODALS.CHANGE_PASSWORD.CONFIRM_PASSWORD"
      formControlName="password_confirmation">
    </app-field-password>
  </form>

  <div class="c-modal__footer">
    <app-button (click)="dismiss()" [disabled]="submitting" class="u-hide--xs" variant="secondary">
      {{ 'BUTTONS.CANCEL' | translate }}
    </app-button>

    <app-button class="c-modal__btn" (click)="submit()" [disabled]="submitting || form.invalid"
      [loading]="submitting">
      {{ 'BUTTONS.SAVE' | translate }}
    </app-button>
  </div>
</app-modal>