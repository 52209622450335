import { Pipe, PipeTransform } from '@angular/core';
import { isValid } from 'date-fns';

@Pipe({
  name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: string, short = false, future = false): any {
    const timestamp = Date.parse(value);

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(timestamp) === true) {
      return null;
    }

    const now = new Date().getTime();
    const date = new Date(timestamp);

    if (!isValid(date)) {
      return null;
    }

    // time since message was sent in seconds
    const delta = future
      ? Math.max(0, (date.getTime() - now) / 1000)
      : Math.max(0, (now - date.getTime()) / 1000);

    // format string
    if (delta < 10 && !short) {
      return 'Just now';
    }

    if (delta < 60) {
      // sent in last minute
      return `${Math.floor(delta)}s${short ? '' : ' ago'}`;
    }

    if (delta < 3600) {
      // sent in last hour
      return `${Math.floor(delta / 60)}m${short ? '' : ' ago'}`;
    }

    if (delta < 86400) {
      // sent on last day
      return `${Math.floor(delta / 3600)}h${short ? '' : ' ago'}`;
    }

    // sent more than one day ago
    return `${Math.floor(delta / 86400)}d${short ? '' : ' ago'}`;
  }
}
