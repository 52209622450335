import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalService } from 'angular-modal-simple';
import { finalize, tap } from 'rxjs';
import { SessionService } from '../../../core/state/session/session.service';

@Component({
  selector: 'app-edit-user-modal',
  templateUrl: './edit-user-modal.component.html',
  styleUrls: ['./edit-user-modal.component.scss'],
})
export class EditUserModalComponent implements OnInit {
  @Input() user;

  @Input() isSelf = false;

  partnerId: string;

  form = new FormGroup({
    name: new FormControl(null, Validators.required),
    email: new FormControl(null, Validators.required),
  });

  submitting = false;

  constructor(private modalService: ModalService, private sessionService: SessionService) {}

  ngOnInit() {
    this.setFields();
  }

  submit() {
    if (this.submitting || this.form.invalid) {
      return;
    }

    this.submitting = true;

    const payload = this.form.value;

    if (payload.email === this.user?.email) {
      delete payload.email;
    }

    this.sessionService
      .patchUser(payload)
      .pipe(
        tap(() => {
          this.submitting = false;
          this.dismiss();
        }),
        finalize(() => {
          this.submitting = false;
        })
      )
      .subscribe();
  }

  dismiss() {
    return this.modalService.close(EditUserModalComponent);
  }

  private setFields() {
    if (this.user) {
      const form = this.form.controls;

      form.name.setValue(this.user.name);
      form.email.setValue(this.user.email);
    }
  }
}
