/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { EventCategory, EventName, EventPayload } from './analytics.interface';
import { CookieQuery } from '../../cookie/states/cookie/cookie.query';

// The global site tag (gtag.js) is a JavaScript tagging framework
// and API that allows you to send event data to Google Analytics,
// Google Ads, and Google Marketing Platform.
// We declare gtag here in order to be able to access that API
// https://developers.google.com/analytics/devguides/collection/gtagjs
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function gtag(...args: any[]) {
  // eslint-disable-next-line prefer-rest-params
  window['dataLayer'].push(arguments);
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(public router: Router, private cookieQuery: CookieQuery) {
    this.init();

    this.cookieQuery.enabled$.subscribe((enabled) => {
      this.setCookies(enabled);
    });
  }

  async init() {
    // inject gtag
    if (!window['gtag']) {
      this.loadScript();
    }

    window['dataLayer'] = window['dataLayer'] || [];
    window['gtag'] = gtag;

    gtag('js', new Date());
    gtag('consent', 'default', {
      analytics_storage: 'denied',
      ad_storage: 'denied',
    });
    gtag('config', environment.analyticsKey, {
      send_page_view: false,
    });

    this.setCookies();
  }

  setCookies(enabled?) {
    const isEnabled = enabled || this.cookieQuery.enabled;
    if (isEnabled) {
      this.enable();
    } else {
      this.disable();
    }
  }

  enable() {
    gtag('consent', 'update', {
      analytics_storage: 'granted',
      ad_storage: 'granted',
    });
  }

  disable() {
    gtag('consent', 'update', {
      analytics_storage: 'denied',
      ad_storage: 'denied',
    });
  }

  pageView(page: EventCategory, label: string = null) {
    // tracks page opening event
    if (label) {
      gtag('event', EventName.PageView, {
        eventCategory: page,
        eventAction: 'OnEnter',
        eventLabel: label,
      });
    } else {
      // tracks actual page view
      gtag('event', EventName.PageView, {
        page_title: page,
        page_path: this.router.url,
      });
    }
  }

  track(payload: EventPayload) {
    gtag('event', payload.name, {
      eventCategory: payload.category,
      eventLabel: payload.label,
      eventAction: payload.action,
      eventValue: payload.value,
    });
  }

  error(description: string, fatal = false) {
    gtag('event', 'exception', {
      description,
      fatal,
    });
  }

  trackTimeSpent(page: EventCategory, name: string, date: Date, label?: string) {
    if (!date) {
      return;
    }

    const time = date.getTime();
    const dateNow = new Date();
    const timeNow = dateNow.getTime();
    const timeToEvent = timeNow - time;
    const secsToEvent = Math.ceil(timeToEvent / 1000);

    gtag('event', 'timing_complete', {
      name,
      event_category: page,
      label,
      value: secsToEvent,
    });
  }

  private loadScript() {
    return new Promise((resolve) => {
      const src = `https://www.googletagmanager.com/gtag/js?id=${environment.analyticsKey}`;
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = src;
      script.async = true;

      script.onload = () => {
        resolve(true);
      };

      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }
}
