import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface CookieState {
  enabled: boolean;
}

export function createInitialState(): CookieState {
  return {
    enabled: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'cookie' })
export class CookieStore extends Store<CookieState> {
  constructor() {
    super(createInitialState());
  }
}
