import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Observable, Subscription, filter, map, mergeMap, tap } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ModalService } from 'angular-modal-simple';
import { User } from '../../../core/state/session/session.interface';
import { SessionQuery } from '../../../core/state/session/session.query';
import { PartnerQuery } from '../../../modules/partner/state';
import { Partner, PartnerStatus } from '../../../modules/partner/state/partner.interface';
import { File } from '../../../core/services/files/files.interface';
import { SessionService } from '../../../core/state/session/session.service';
import { EditUserModalComponent } from '../../modals/edit-user-modal/edit-user-modal.component';
import { ChangePasswordModalComponent } from '../../modals/change-password-modal/change-password-modal.component';
import { environment } from '../../../../environments/environment';
import { CreateTicketModalComponent } from '../../../modules/partner/modals/create-ticket-modal/create-ticket-modal.component';
import { ManageDevicesModalComponent } from '../../modals/manage-devices-modal/manage-devices-modal.component';
import { TranslationsService } from '../../../core/services/translations/translations.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements AfterViewInit {
  @ViewChild('scrollElement', { static: true }) scrollElement: ElementRef;

  @ViewChildren('checkbox') checkboxes: QueryList<ElementRef>;

  user$: Observable<User>;

  partner$: Observable<Partner>;

  logo$: Observable<File>;

  expanded = false;

  hoverable = true;

  hasPartnerLinks = false;

  hasMultiplePartners = false;

  hasSlackButton = false;

  scrolled = false;

  isAdmin = false;

  header = 'default';

  private observer: IntersectionObserver;

  private routerSubscription: Subscription;

  constructor(
    private partnerQuery: PartnerQuery,
    private modalService: ModalService,
    private activatedRoute: ActivatedRoute,
    private sessionQuery: SessionQuery,
    private sessionService: SessionService,
    private changeDetectorRef: ChangeDetectorRef,
    private translationsService: TranslationsService,
    private router: Router
  ) {
    this.user$ = this.sessionQuery.user$;
    this.logo$ = this.partnerQuery.logo$;

    this.routerSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute), // Listen to activateRoute
        map((route) => {
          while (route.firstChild) {
            // eslint-disable-next-line no-param-reassign
            route = route.firstChild;
          }
          return route;
        }),
        mergeMap((route) => route.data),
        tap((data) => {
          // eslint-disable-next-line no-prototype-builtins
          this.header = data?.hasOwnProperty('header') ? data.header : 'default';
          this.isAdmin = this.sessionQuery.isAdmin();
        })
      )
      .subscribe();

    this.partner$ = this.partnerQuery.partner$.pipe(
      tap((partner) => {
        const enabledStatus =
          [PartnerStatus.New, PartnerStatus.Onboarding, PartnerStatus.Cancelled].indexOf(
            partner?.status
          ) === -1;

        this.hasPartnerLinks = this.sessionQuery.isAdmin() || enabledStatus;
        this.hasMultiplePartners = this.sessionQuery.getPartners().length > 1;
        this.hasSlackButton = partner?.status !== PartnerStatus.New;
      })
    );

    this.sessionQuery.partners$.subscribe(() => {
      this.hasMultiplePartners = this.sessionQuery.getPartners().length > 1;
    });
  }

  async ngAfterViewInit() {
    if (this.scrollElement) {
      this.scrolled = false;
      const options: IntersectionObserverInit = {
        root: document,
        rootMargin: '1px 20px 20px 20px',
      };

      this.observer = new IntersectionObserver(await this.onObserveElement.bind(this), options);
      this.observer.observe(this.scrollElement.nativeElement);
    }
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }

    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  updateObserver() {
    this.observer.unobserve(this.scrollElement.nativeElement);
    this.observer.observe(this.scrollElement.nativeElement);
  }

  async onObserveElement(data: IntersectionObserverEntry[]) {
    this.scrolled = !data[0].isIntersecting;

    if (!this.scrolled) {
      // check position
      this.scrolled = data[0].target.getBoundingClientRect().top < -20;
    }

    this.changeDetectorRef.detectChanges();
  }

  logout() {
    this.unsetExpanded();
    this.sessionService.postLogout().subscribe();
  }

  editProfile() {
    const user = this.sessionQuery.getUser();
    this.modalService.display(EditUserModalComponent, { data: { user, isSelf: true } });
  }

  editPassword() {
    const user = this.sessionQuery.getUser();
    this.modalService.display(ChangePasswordModalComponent, { data: { user } });
  }

  manageDevices() {
    const user = this.sessionQuery.getUser();
    this.modalService.display(ManageDevicesModalComponent, { data: { user } });
  }

  setupSlack(partnerId: string) {
    this.sessionService.setupSlack(partnerId).subscribe();
  }

  getAvatarActions() {
    return [
      {
        text: 'NAV.EDIT_PROFILE',
        handler: () => {
          this.editProfile();
        },
      },
      {
        text: 'NAV.CHANGE_PASSWORD',
        handler: () => {
          this.editPassword();
        },
      },
      {
        text: 'NAV.MANAGE_DEVICES',
        handler: () => {
          this.manageDevices();
        },
      },
      {
        text: 'NAV.CHANGE_PARTNER',
        visible: this.hasMultiplePartners,
        handler: () => {
          this.router.navigate(['/partner', 'switch']);
        },
      },
      {
        separator: true,
      },
      {
        text: 'NAV.LOGOUT',
        handler: () => {
          this.logout();
        },
      },
    ];
  }

  getMenuActions() {
    return [
      {
        text: 'NAV.API_DOCUMENTATION',
        handler: () => {
          window.open(environment.config.apiDocumentation, '_blank');
        },
      },
      {
        text: 'NAV.SEND_FEEDBACK',
        handler: () => {
          this.modalService.display(CreateTicketModalComponent);
        },
      },
      {
        text: 'NAV.TIPS_FAQ',
        handler: () => {
          const partnerId = this.partnerQuery.getPartnerId();
          this.router.navigate(['/partner', partnerId, 'faq']);
        },
      },
    ];
  }

  toggleExpanded(e) {
    e.stopPropagation();

    this.expanded = !this.expanded;
  }

  unsetExpanded() {
    this.expanded = false;
    this.hoverable = false;
    this.checkboxes?.forEach((checkbox) => {
      // eslint-disable-next-line no-param-reassign
      checkbox.nativeElement.checked = false;
    });

    setTimeout(() => {
      this.hoverable = true;
    }, 200);
  }
}
