import { Component } from '@angular/core';
import { ModalService } from 'angular-modal-simple';
import { tap } from 'rxjs';
import { Ticket } from '../../state/partner.interface';
import { PartnerService } from '../../state';

@Component({
  selector: 'app-ticket-details-modal',
  templateUrl: './ticket-details-modal.component.html',
  styleUrls: ['./ticket-details-modal.component.scss'],
})
export class TicketDetailsModalComponent {
  ticket: Ticket;

  loading = false;

  constructor(private modalService: ModalService, private partnerService: PartnerService) {}

  deleteTicket() {
    if (this.loading) {
      return;
    }

    this.loading = true;

    this.partnerService
      .deleteTicket(this.ticket.partner_id, this.ticket.id)
      .pipe(
        tap(() => {
          this.dismiss();
        })
      )
      .subscribe();
  }

  dismiss() {
    return this.modalService.close(TicketDetailsModalComponent);
  }
}
