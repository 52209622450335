import { Component, Input, Self, OnInit, Optional } from '@angular/core';
import {
  Validators,
  ControlValueAccessor,
  NgControl,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';

@Component({
  selector: 'app-field-input',
  templateUrl: './field-input.component.html',
  styleUrls: ['./field-input.component.scss'],
})
export class FieldInputComponent implements ControlValueAccessor, OnInit {
  @Input() type: 'text' | 'email' | 'search' = 'text';

  @Input() label: string;

  @Input() icon: string;

  @Input() placeholder = '';

  @Input() loading = false;

  @Input() changed = false;

  @Input() showOptional = true;

  value: string;

  isDisabled = false;

  isOptional = false;

  onChange: (_: any) => void = () => {};

  onTouched: () => void = () => {};

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(@Self() @Optional() public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  ngOnInit() {
    this.setValidators();
    this.checkRequired();
  }

  // FORM CONTROL FUNCTIONS
  updateChanges() {
    this.onChange(this.value);
  }

  writeValue(value: string): void {
    this.value = value;
    this.updateChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  // PRIVATE
  private checkRequired() {
    const { control } = this.ngControl;

    const validator = control.validator ? control.validator({} as AbstractControl) : <any>{};
    if (!validator || !validator.required) {
      this.isOptional = true;
    }

    if (this.type === 'search') {
      this.isOptional = false;
    }
  }

  private setValidators() {
    const { control } = this.ngControl;

    let validators = this.getValidators();
    validators = control.validator ? [control.validator, ...validators] : this.getValidators();

    control.setValidators(validators);
    control.updateValueAndValidity();
  }

  private getValidators(): ValidatorFn[] {
    const validators = [];

    if (this.type === 'email') {
      validators.push(Validators.email);
    }

    return validators;
  }
}
