<app-header></app-header>

<ng-container *ngIf="loading">
  <div class="e-app__wrapper">
    <div class="l-container l-container--sm">
      <app-loading></app-loading>
    </div>
  </div>
</ng-container>

<div class="e-app__content" [class.u-display--none]="loading">
  <router-outlet (activate)="onActivate()" id="main">
  </router-outlet>

  <app-footer></app-footer>
</div>


<app-toast-container></app-toast-container>
<app-popover></app-popover>