import { Directive, Input, Output, EventEmitter, HostListener } from '@angular/core';
import copyToClipboard from 'copy-to-clipboard';
import { ToastService } from '../../core/toasts/services/toast-service/toast.service';
import { TranslationsService } from '../../core/services/translations/translations.service';

@Directive({ selector: '[copy-clipboard]' })
export class CopyClipboardDirective {
  @Input('copy-clipboard') payload: string;

  @Output('copied') copied: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private toastService: ToastService,
    private translationsService: TranslationsService
  ) {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.preventDefault();
    if (!this.payload) return;

    // catch error should avoid "document is not focused" error on Chrome
    this.copy(this.payload);
  }

  copy(data: string) {
    const copied = copyToClipboard(data, { format: 'text/plain' });

    if (!copied) {
      // copied failed
      const alertMsg = this.translationsService.getInstantTranslation('ERROR.FAILED_COPY_MESSAGE');

      this.toastService.error(alertMsg);
      return;
    }

    // copied succesfully
    this.copied.emit(data);
    const alertMsg = this.translationsService.getInstantTranslation('GENERAL.COPIED');
    this.toastService.info(alertMsg);
  }
}
