import { Component, Input, Optional, Self, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl, ValidatorFn, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-field-select',
  templateUrl: './field-select.component.html',
  styleUrls: ['./field-select.component.scss'],
})
export class FieldSelectComponent implements ControlValueAccessor, OnInit {
  @Input() label: string;

  @Input() placeholder: string;

  @Input() changed = false;

  @Input() showOptional = true;

  @Input() options: string[] | { label: string; value: string }[] = [];

  value: string;

  isDisabled = false;

  isOptional = false;

  onChange: (_: any) => void = () => {};

  onTouched: () => void = () => {};

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(@Self() @Optional() public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  ngOnInit() {
    this.setValidators();
    this.checkRequired();
  }

  // FORM CONTROL FUNCTIONS
  setValue($event: any) {
    this.value = $event.detail.value;
    this.updateChanges();
  }

  updateChanges() {
    this.onChange(this.value);
  }

  writeValue(value: any): void {
    this.value = value;
    this.updateChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  // PRIVATE
  private checkRequired() {
    const { control } = this.ngControl;

    const validator = control.validator ? control.validator({} as AbstractControl) : <any>{};
    if (!validator || !validator.required) {
      this.isOptional = true;
    }
  }

  private setValidators() {
    const { control } = this.ngControl;

    let validators = this.getValidators();
    validators = control.validator ? [control.validator, ...validators] : this.getValidators();

    control.setValidators(validators);
    control.updateValueAndValidity();
  }

  private getValidators(): ValidatorFn[] {
    const validators = [];

    return validators;
  }
}
