<div class="c-toast" [class.c-toast--success]="toast?.type === 'success'"
  [class.c-toast--info]="toast?.type === 'info'" [class.c-toast--error]="toast?.type === 'error'"
  [class.c-toast--warning]="toast?.type === 'warning'" [class.c-toast--hidden]="!toast?.visible">
  <app-icon *ngIf="toast?.icon" [name]="toast?.icon" class="c-toast__icon"></app-icon>

  <ng-container *ngIf="toast?.message;">
    <div class="c-toast__label">
      {{ toast?.message }}
    </div>
  </ng-container>

  <span class="c-toast__button" *ngIf="toast?.button_alt;" (click)="handleClickAlt($event)">
    {{ toast?.button_alt }}
  </span>

  <span class="c-toast__button" *ngIf="toast?.button; else closeBtn" (click)="handleClick($event)">
    {{ toast?.button }}
  </span>

  <ng-template #closeBtn>
    <app-icon class="c-toast__close-icon" name="close-outline" (click)="handleClose($event)">
    </app-icon>
  </ng-template>
</div>