<div class="e-home">
  <div class="c-section c-section--title e-home__hero">
    <div class="l-wrapper">
      <div class="l-container">
        <div class="l-grid l-grid--2">
          <div class="l-grid__item u-flex-align--start">
            <h1 class="c-section__title u-margin-top--md">
              The only white-label telemedicine platform powered exclusively by physicians.
            </h1>

            <h5 class="c-section__description u-margin-top--md">
              You control the brand, we power the medicine.
            </h5>

            <div class="u-display--flex u-margin-top--lg">
              <a [routerLink]="['/', 'register']" class="c-btn c-btn--secondary">
                Get In Touch
              </a>
            </div>

            <div class="e-home__certification">
              <img alt="Legitscripts" width="126" height="39" src="./assets/images/legitscript.svg"
                loading="lazy" />

              <img alt="HIPAA" width="70" height="39" src="./assets/images/hipaa.svg"
                loading="lazy" />

              <img alt="SOC2" width="92" height="39" src="./assets/images/soc2.svg"
                loading="lazy" />
            </div>
          </div>

          <div class="l-grid__item">
            <div class="e-home__hero-animation">
              <video #heroVideo loop playsinline muted preload="none"
                poster="./assets/images/hero.webp">
                <source src="./assets/images/hero.mp4" type="video/mp4">
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="l-wrapper">
    <div class="l-container l-container--sm">
      <!-- Section 1 -->
      <div class="c-section c-section--vertical e-home__specialty">
        <h2 class="c-section__title">
          Need specialty care?
          <br>
          We've got you covered.
        </h2>

        <ul
          class="c-checklist c-checklist--spread c-checklist--icon u-margin-top--lg u-margin-bottom--lg">
          <li class="c-checklist__item">
            <app-icon class="c-checklist__icon" name="obgyn-outline"></app-icon>
            OB/GYN (Women's Health)
          </li>

          <li class="c-checklist__item">
            <app-icon class="c-checklist__icon" name="weight-loss-outline"></app-icon>
            Weight Loss
          </li>

          <li class="c-checklist__item">
            <app-icon class="c-checklist__icon" name="dermatology-outline"></app-icon>
            Dermatology
          </li>

          <li class="c-checklist__item">
            <app-icon class="c-checklist__icon" name="primary-care-outline"></app-icon>
            Primary Care
          </li>

          <li class="c-checklist__item">
            <app-icon class="c-checklist__icon" name="allergists-outline"></app-icon>
            Allergy/Immunology
          </li>

          <li class="c-checklist__item">
            <app-icon class="c-checklist__icon" name="pediatrics-outline"></app-icon>
            Pediatrics
          </li>

          <li class="c-checklist__item">
            <app-icon class="c-checklist__icon" name="internal-medicine-outline"></app-icon>
            Internal Medicine
          </li>

          <li class="c-checklist__item">
            <app-icon class="c-checklist__icon" name="urgent-care-outline"></app-icon>
            Urgent Care
          </li>
        </ul>

        <div class="u-display--flex u-margin-top--md">
          <a [routerLink]="['/', 'contact']" class="c-btn c-btn--secondary">
            Get In Touch
          </a>
        </div>
      </div>

      <!-- Section 2 -->
      <div class="c-section c-section--vertical e-home__speed">
        <h2 class="c-section__title">
          We're serious about speed.
        </h2>

        <div class="l-grid l-grid--2">
          <div class="l-grid__item">
            <h4>
              52 mins
            </h4>
            <h6 class="c-section__alt">
              Doctor Response Time*
            </h6>
            <p>
              Our proprietary assignment algorithm enables 24/7 SLA coverage and empowers doctors to
              reply to patients in under an hour.
            </p>
          </div>

          <div class="l-grid__item">
            <h4>
              7 days
            </h4>
            <h6 class="c-section__alt">
              Integration time
            </h6>
            <p>
              Our carefully crafted APIs and white label apps integrate seamlessly with your
              existing technology so you are ready to launch in days.
            </p>
          </div>
        </div>

        <div class="u-margin-bottom--lg u-hide--sm"></div>

        <a class="c-card c-card--v c-card--sm c-card--secondary c-link u-margin-top--lg"
          [href]="shopifyLink" target="_blank" rel=”nofollow”>
          <app-icon class="c-card__icon" name="shopify" [fill]="true" [stroke]="false"></app-icon>
          <h6 class="u-margin-bottom--none u-margin-right--sm">
            Get ready to launch in just one day using our Shopify extension
          </h6>
          <app-icon name="chevron-forward-circle-outline"></app-icon>
        </a>
      </div>
    </div>

    <!-- section 3 -->
    <div class="e-home__experience">
      <div class="l-container l-container--sm u-padding-bottom--none">
        <div class="c-section c-section--vertical u-padding-bottom--none">
          <img loading="lazy" src="./assets/images/serota.jpg" width="108" height="108"
            alt="Marc Serota" class="c-avatar c-avatar--xl u-margin-bottom--lg">

          <h1 class="c-section__title">
            Doctor-founded. Doctor-led.
          </h1>
          <h6 class="c-section__alt u-margin-top--lg u-margin-bottom--none">
            Experience Includes
          </h6>
        </div>
      </div>

      <div class="c-carousel c-carousel--flat">
        <img width="126" height="38" alt="logo-pt-1" src="./assets/images/experience/logo-1.png"
          class="e-home__pt-logo u-padding-left--md" loading="lazy">
        <img width="110" height="38" alt="logo-pt-2" src="./assets/images/experience/logo-2.png"
          class="e-home__pt-logo" loading="lazy">
        <img width="110" height="38" alt="logo-pt-3" src="./assets/images/experience/logo-3.png"
          class="e-home__pt-logo" loading="lazy">
        <img width="84" height="38" alt="logo-pt-4" src="./assets/images/experience/logo-4.png"
          class="e-home__pt-logo" loading="lazy">
        <img width="110" height="38" alt="logo-pt-5" src="./assets/images/experience/logo-5.png"
          class="e-home__pt-logo" loading="lazy">
        <img width="110" height="38" alt="logo-pt-6" src="./assets/images/experience/logo-6.png"
          class="e-home__pt-logo" loading="lazy">
        <img width="110" height="38" alt="logo-pt-7" src="./assets/images/experience/logo-7.png"
          class="e-home__pt-logo" loading="lazy">
        <img width="126" height="38" alt="logo-pt-8" src="./assets/images/experience/logo-8.png"
          class="e-home__pt-logo u-padding-right--md" loading="lazy">
      </div>
    </div>

    <div class="l-container l-container--sm">
      <!-- Section 3 - Continue -->
      <div class="c-section c-section--vertical e-home__experience2">
        <ng-container *ngIf="!showVideo; else youtubeVideo">
          <div class="e-home__experience2-thumbnail" (click)="showVideo = true">
            <img src="assets/images/youtube.webp" loading="lazy" alt="youtube-thumbnail">
          </div>
        </ng-container>

        <ng-template #youtubeVideo>
          <iframe title="Marc Serota Video"
            src="https://www.youtube-nocookie.com/embed/QMzBEFxEBTU?si=vkkJb__6sB2nshS-&disablekb=1&color=white&fs=0&playsinline=1&rel=0&autoplay=1"
            frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope;"
            referrerpolicy="strict-origin-when-cross-origin"></iframe>
        </ng-template>

        <p class="c-section__title u-max-width--38rem u-color--gray-700">
          MD Integrations' CEO, Dr. Marc Serota, graduated from medical school in 2007 at the age of
          23, is triple board certified in dermatology, pediatrics, and immunology/allergy, and is
          licensed in 45 states. Marc is also a national speaker in these specialty areas.
        </p>
      </div>

      <!-- Section 4 -->
      <div class="c-section c-section--vertical e-home__business">
        <h2 class="c-section__title u-max-width--36rem">
          Easy for startups and flexible for larger enterprises.
        </h2>

        <ul
          class="c-checklist c-checklist--spread  u-max-width--50rem u-margin-top--lg u-margin-bottom--lg">
          <li class="c-checklist__item">
            <app-checkmark />
            Direct-to-consumer brands
          </li>

          <li class="c-checklist__item">
            <app-checkmark />
            Digital Health Companies
          </li>

          <li class="c-checklist__item">
            <app-checkmark />
            Labs and Diagnostics
          </li>

          <li class="c-checklist__item">
            <app-checkmark />
            Pharmaceutical Companies
          </li>

          <li class="c-checklist__item">
            <app-checkmark />
            Weight Loss Companies
          </li>

          <li class="c-checklist__item">
            <app-checkmark />
            Retailers
          </li>

          <li class="c-checklist__item">
            <app-checkmark />
            Pharmacies
          </li>

          <li class="c-checklist__item">
            <app-checkmark />
            Wellness Companies
          </li>
        </ul>

        <div class="u-display--flex u-margin-top--md">
          <a [routerLink]="['/', 'solutions']" class="c-btn c-btn--secondary">
            View Solutions
          </a>
        </div>
      </div>

      <!-- Section 5 -->
      <div class="c-section c-section--vertical">
        <h2 class="c-section__title u-max-width--42rem">
          Comprehensive virtual care across all 50 states, DC, and Guam.
        </h2>

        <p class="c-section__description u-margin-top--lg u-text-align--center">
          Our physician-only clinical network ensures your patients receive the highest quality
          consults from coast to coast.
        </p>

        <div class="e-home__map">
          <video #mapVideo loop playsinline muted preload="none">
            <source src="./assets/images/map.mp4" type="video/mp4">
          </video>

          <div class="l-grid l-grid--2">
            <div class="l-grid__item">
              <h3>
                100K
              </h3>
              <h5>
                Visits Per Month
              </h5>
            </div>

            <div class="l-grid__item">
              <h3>
                24/7/365
              </h3>
              <h5>
                Doctor Availability
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Section 7 -->
    <div class="e-home__benefits c-card c-card--primary">
      <div class="l-container">
        <div class="c-section">
          <div class="u-display--flex u-margin-bottom--lg">
            <span rel=”nofollow” class="c-btn c-btn--clear"
              [class.c-btn--active]="toggle === 'patients'" (click)="setToggle('patients')">
              Patients
            </span>

            <span rel=”nofollow” class="c-btn c-btn--clear u-margin-left--xs"
              [class.c-btn--active]="toggle === 'partners'" (click)="setToggle('partners')">
              Partners
            </span>
          </div>

          <ng-container *ngIf="toggle === 'patients'; else partnerBenefitsTemplate">
            <h1 class="u-margin-bottom--xl">
              Stellar patient experience that <br> increases retention and revenue.
            </h1>

            <div class="l-grid l-grid--4 u-margin-bottom--md">
              <div class="l-grid__item">
                <div class="u-width--100">
                  <app-icon name="clipboard-outline"></app-icon>
                  <h6>
                    Simple intake forms
                  </h6>
                  <p>
                    Use MDI's simple white-label intake forms for specific therapeutic areas.
                    Clinical protocols have already been medically reviewed.
                  </p>
                </div>
              </div>

              <div class="l-grid__item">
                <div class="u-width--100">
                  <app-icon name="medkit-outline"></app-icon>
                  <h6>
                    Expert doctors and specialists only
                  </h6>
                  <p>
                    Your visits will be seen by board certified MD's specialized in your specific
                    therapeutic area.
                  </p>
                </div>
              </div>

              <div class="l-grid__item">
                <div class="u-width--100">
                  <app-icon name="stopwatch-outline"></app-icon>
                  <h6>
                    Average response time is less than one hour
                  </h6>
                  <p>
                    Our assignment algorithm has been optimized to exceed industry
                    averages, having the best SLA's and response times around.
                  </p>
                </div>
              </div>

              <div class="l-grid__item">
                <div class="u-width--100">
                  <app-icon name="phone-portrait-outline"></app-icon>
                  <h6>
                    Native iOS and Android mobile apps
                  </h6>
                  <p>
                    Our mobile applications make receiving and providing care seamless. We built our
                    mobile application for the physician experience first.
                  </p>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-template #partnerBenefitsTemplate>
            <h1 class="u-margin-bottom--xl">
              Unparalleled partner <br> service and support.
            </h1>

            <div class="l-grid l-grid--4 u-margin-bottom--md">
              <div class="l-grid__item">
                <div class="u-width--100">
                  <app-icon name="responsive-outline" [fill]="true" [stroke]="false"></app-icon>
                  <h6>
                    Flexible white label solutions
                  </h6>
                  <p>
                    Highly customizable intake form and a feature-rich messaging experience covering
                    the entire patient flow.
                  </p>
                </div>
              </div>

              <div class="l-grid__item">
                <div class="u-width--100">
                  <app-icon name="concierge-outline" [fill]="true" [stroke]="false"></app-icon>
                  <h6>
                    High-touch concierge
                  </h6>
                  <p>
                    An integration team with developers and medical staff dedicated to make your
                    integration process smooth.
                  </p>
                </div>
              </div>

              <div class="l-grid__item">
                <div class="u-width--100">
                  <app-icon name="slack" [fill]="true" [stroke]="false"></app-icon>
                  <h6>
                    Dedicated developer Slack channel
                  </h6>
                  <p>
                    Ask questions and get support directly from the team building the product via
                    instant messaging.
                  </p>
                </div>
              </div>

              <div class="l-grid__item">
                <div class="u-width--100">
                  <app-icon name="lock-closed-outline"></app-icon>
                  <h6>
                    Best-in-class security
                  </h6>
                  <p>
                    Industry leading security & privacy practices with SOC2, HIPAA and ISO
                    compliance.
                  </p>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>


    <!-- Section 6 -->
    <div class="e-home__testimonials">
      <div class="l-container">
        <div class="c-section">
          <h6 class="c-section__alt u-margin-bottom--xl">
            What Our Partners Are Saying
          </h6>

          <div class=" l-grid l-grid--2 u-margin-bottom--md">
            <div class="l-grid__item">
              <app-icon name="quotes-outline"></app-icon>
              <div class="u-width--100">
                <h5>
                  With MDI's robust network of board-certified doctors, we feel confident that we
                  have a partner that can grow with us as we expand our health services to more
                  patients.
                </h5>
                <h5 class="u-margin-top--md">
                  — MDI Client
                </h5>
              </div>
            </div>
            <div class="l-grid__item"></div>
          </div>

          <div class="l-grid l-grid--2">
            <div class="l-grid__item"></div>
            <div class="l-grid__item">
              <app-icon name="quotes-outline"></app-icon>
              <div class="u-width--100">
                <h5>
                  Our team was able to complete the integration in days. Once we started welcoming
                  our first patients, the admin tool was easy for our support team to use, response
                  times to patients were fast, and they have a stellar medical team.
                </h5>
                <h5 class="u-margin-top--md">
                  — MDI Client
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="l-container l-container--sm e-home__cta">
      <!-- Section 8 -->
      <div class="c-section c-section--vertical u-margin-top--lg u-margin-bottom--lg">
        <h2 class="c-section__title u-max-width--36rem"
          [innerHTML]="'The only thing friendlier than our service is the \<u>pricing\</u>.'"> </h2>

        <ul class="c-checklist c-checklist--column u-margin-top--lg u-margin-bottom--lg">
          <li class="c-checklist__item">
            <app-checkmark />
            No charge for the Professional Corporation aka "Medical Group"
          </li>

          <li class="c-checklist__item">
            <app-checkmark />
            Free messaging and refills for one year
          </li>
        </ul>

        <div class="u-display--flex u-margin-top--md">
          <a [routerLink]="['/', 'contact']" class="c-btn c-btn--secondary">
            Get a Quote
          </a>
        </div>
      </div>
    </div>
  </div>
</div>