import { throwError, Observable } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { SessionQuery } from '../../state/session/session.query';
import { StorageService } from '../../services/storage/storage.service';
import { TranslationsService } from '../../services/translations/translations.service';
import { SessionService } from '../../state/session/session.service';

@Injectable({
  providedIn: 'root',
})
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private sessionQuery: SessionQuery,
    private sessionService: SessionService,
    private storage: StorageService,
    private injector: Injector
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isLoggedUser = this.sessionQuery.isLoggedIn();
    const isPristine = request.headers.get('pristine');
    const isUnauth = request.headers.get('unauthenticated');

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { access_token, token_type } = this.sessionQuery.getSession();

    const hasAuth = isLoggedUser && !isPristine && !isUnauth;
    const signedRequest = this.addSignatureHeader(request);
    const requestWithVersion = this.addVersionHeader(signedRequest);
    const requestModified = hasAuth
      ? this.addAuthenticationHeader(requestWithVersion, token_type, access_token)
      : requestWithVersion;

    return next.handle(requestModified).pipe(
      timeout(environment.api.maxWaiting),
      catchError((err) => {
        if (isPristine) {
          throw err;
        }

        return this.customErrorHandler(err);
      })
    );
  }

  private addSignatureHeader(request: HttpRequest<any>): HttpRequest<any> {
    // proposedly in another service to make it difficult to find when minified
    const sig = this.sessionService.getSigKey();
    const header = ['m', 'u', 's', 'k', 'c', 'e', 'h', 'C'].reverse().join('');
    return request.clone({
      headers: request.headers.set(header, `${sig}`),
    });
  }

  private addVersionHeader(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      headers: request.headers.set('Version', '2'),
    });
  }

  private addAuthenticationHeader(
    request: HttpRequest<any>,
    tokenType: string,
    accessToken: string
  ): HttpRequest<any> {
    return request.clone({
      headers: request.headers.set(environment.api.authHeader, `${tokenType} ${accessToken}`),
    });
  }

  private customErrorHandler(error: any) {
    const translate = this.injector.get(TranslationsService);
    let errorMessage: string;

    if (
      error === 'ERROR_NO_INTERNET' ||
      error === 'ERR_INTERNET_DISCONNECTED' ||
      (error?.status === 0 && !window.navigator.onLine)
    ) {
      errorMessage = translate.getInstantTranslation('HTTP_ERRORS.NO_INTERNET_CONNECTION');
    } else if (error?.status === 429 || error?.name === 'TimeoutError') {
      errorMessage = translate.getInstantTranslation('HTTP_ERRORS.THROTTLE_ERROR');
    } else {
      errorMessage = translate.getInstantTranslation('HTTP_ERRORS.DEFAULT_MESSAGE');

      if (error?.error?.message) {
        errorMessage = error.error.message;
      }
    }

    const isLoggedUser = this.sessionQuery.isLoggedIn();
    if (error.status === 401 && isLoggedUser) {
      this.sessionService.doLogout();
    }

    return throwError({ message: errorMessage, original: error });
  }
}
