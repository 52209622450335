import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class PopoverService {
  options: any[];

  x: number;

  y: number;

  width: number;

  height: number;

  onOpen = new BehaviorSubject<{
    x: number;
    y: number;
    width: number;
    height: number;
    options: any[];
  }>({
    x: null,
    y: null,
    width: null,
    height: null,
    options: [],
  });

  constructor(private router: Router) {
    this.router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this.close();
      }
    });
  }

  open(options: any[], x: number, y: number, width: number, height: number) {
    this.options = options;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;

    this.onOpen.next({ x, y, width, height, options });
  }

  close() {
    this.options = [];

    this.x = null;
    this.y = null;
  }

  reset() {
    this.options = [];
    this.x = null;
    this.y = null;
    this.width = null;
    this.height = null;

    this.onOpen.next({ x: null, y: null, width: null, height: null, options: [] });
  }
}
