import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalService } from 'angular-modal-simple';
import { finalize, tap } from 'rxjs';
import { SessionService } from '../../../core/state/session/session.service';
import { passwordMatchValidator } from '../../../core/validators/password-match/password-match.validator';
import { passwordStrengthValidator } from '../../../core/validators/password-strength/password-strenght.validator';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss'],
})
export class ChangePasswordModalComponent implements OnInit {
  @Input() user;

  form = new FormGroup(
    {
      password: new FormControl(null, [Validators.required, passwordStrengthValidator]),
      password_confirmation: new FormControl(null, Validators.required),
    },
    { validators: passwordMatchValidator }
  );

  submitting = false;

  constructor(private modalService: ModalService, private sessionService: SessionService) {}

  ngOnInit() {
    if (!this.user) {
      this.dismiss();
    }
  }

  submit() {
    if (this.submitting || this.form.invalid) {
      return;
    }

    this.submitting = true;

    const payload = this.form.value;

    this.sessionService
      .patchUser(payload)
      .pipe(
        tap(() => {
          this.submitting = false;
          this.dismiss();
        }),
        finalize(() => {
          this.submitting = false;
        })
      )
      .subscribe();
  }

  dismiss() {
    return this.modalService.close(ChangePasswordModalComponent);
  }
}
