import { File } from '../../../core/services/files/files.interface';
import {
  State,
  StatisticChannelItem,
  StatisticItem,
} from '../../../core/state/metadata/metadata.interface';
import { User } from '../../../core/state/session/session.interface';

export enum QuestionType {
  integer = 'integer',
  boolean = 'boolean',
  string = 'string',
  text = 'text',
  date = 'date',
  range = 'range',
  ordering = 'ordering',
  single_option = 'single_option',
  multiple_option = 'multiple_option',
  file = 'file',
  informational = 'informational',
}

export enum OfferableType {
  Medication = 'App\\Models\\PartnerMedication',
  Compound = 'App\\Models\\PartnerCompound',
  Service = 'App\\Models\\PartnerService',
  Supply = 'App\\Models\\PartnerSupply',
}

export enum PartnerStatus {
  New = 'new',
  Onboarding = 'onboarding',
  Integrating = 'integrating',
  Review = 'review',
  Ready = 'ready',
  Live = 'live',
  Cancelled = 'canceled',
  Suspended = 'suspended',
}

export interface Address {
  address: string;
  address2?: string;
  zip_code: string;
  state?: State;
  state_name?: string;
  city_name: string;
  is_sync?: boolean;
}

export interface Partner {
  name: string;
  id: string;
  active?: boolean;
  patient_message_capability?: 'integrated' | 'notification_email';
  support_message_capability?: 'integrated' | 'notification_email';
  partner_notes?: string;
  customer_support_email?: string;
  operations_support_email?: string;
  escalation_support_email?: string;
  contract_file_id?: string;
  insurance_file_id?: string;
  contract_file?: File;
  insurance_file?: File;
  address?: Address;
  operation_country_id: string;
  provides_medications?: boolean;
  can_unlock_phi?: boolean;
  messaging_app_background_color: string;
  messaging_app_primary_color: string;
  messaging_app_secondary_color: string;
  thank_you_note_footer?: string;
  thank_you_note_header?: string;
  about: string;
  website_url: string;
  support_hours?: string;
  support_location?: string;
  use_intake_form?: boolean;
  is_voucher_reminder?: boolean;
  is_statistics_enabled?: boolean;
  enable_icd_bmi?: boolean;
  enable_av_flow?: boolean;
  enable_auto_reassignment?: boolean;
  enable_automatic_sync_flow?: boolean;
  is_review_required?: boolean;
  is_global_statistics_enabled?: boolean;
  use_messaging?: boolean;
  charge_async_follow_up_case?: string;
  charge_async_new_case?: string;
  charge_sync_follow_up_case?: string;
  charge_sync_new_case?: string;
  clinician_due_sync_case?: string;
  clinician_due_async_case?: string;
  clinician_due_sync_followup_encounter?: string;
  clinician_due_async_followup_encounter?: string;
  vouched_integration_charge?: string;
  text_message_integration_charge?: string;
  is_auto_dl_flow?: boolean;
  status: PartnerStatus;
  logo_file_id: string;
  logo?: File;
  review_evidence_id?: string;
  review_evidence_date?: string;
  parent_company?: string;
  shopify_id?: string;
  shopify_url?: string;
  is_shopify_active?: boolean;
  text_message_integration_type: 'automatic' | 'disabled';
  vouched_integration_type:
    | 'patients-automatically'
    | 'patients-partially'
    | 'manually'
    | 'disabled';
  force_hold_status?: boolean;
  force_is_additional_approval_needed?: boolean;
  custom_style?: string;
  review_notes?: string;
  created_at?: string;
}

export interface Contact {
  id?: string;
  partner_id?: string;
  full_name?: string;
  job_title: string;
  first_name: string;
  last_name: string;
  email: string;
}

export interface Credential {
  id?: string;
  secret?: string;
  name: string;
  environment: {
    identifier: string;
  };
}

export interface Invoice {
  id: string;
  partner_id: string;
  invoice_number: string;
  invoice_file_id: string;
  due_date: string;
  reference_month: string;
  bill_name: string;
  bill_email: string;
  include_additional_charges: boolean;
  detailed_report: boolean;
  created_at: string;
  updated_at: string;
  file: File;
}

export interface EmailDomain {
  name: string;
  from: string;
  verification_token?: string;
  dkim_token?: string[];
  status?: 'new' | 'ready';
}

export interface ShopifyPagination {
  data: ShopifyProduct[];
  meta: {
    next_page: string;
    previous_page: string;
  };
}

export interface ShopifyProduct {
  title: string;
  id: string;
  variants: ShopifyVariant[];
  image: {
    src: string;
  };
}

export interface ShopifyVariant {
  title: string;
  sku: string;
  id: number;
  product_id: number;
  price: string;
}

export interface ShopifyAssociation {
  id: string;
  shopify_product_id: string;
  shopify_variant_id: string;
  partner_questionnaire_id?: string;
  active?: boolean;
  questionnaire?: Questionnaire;
  offerings?: {
    offerable_id: string;
    offerable: Medication | Compound | Service | Supply;
    offerable_type: OfferableType;
  }[];
  shopify_product_payload?: ShopifyProduct;
  variant?: ShopifyVariant;
}

export interface PanelUser {
  id?: string;
  email: string;
  name: string;
  full_name?: string;
}

export interface SupportStaff {
  id?: string;
  first_name: string;
  last_name: string;
  email: string;
  active?: boolean;
  managed_by_partner?: boolean;
  photo_id?: string;
  photo?: File;
  can_unlock_phi: boolean;
  permissions: { name: 'live' | 'sandbox' }[];
}

export interface Clinician {
  id?: string;
  first_name: string;
  last_name: string;
  email: string;
  active?: boolean;
  managed_by_partner?: boolean;
  photo_id?: string;
  bio_details?: string;
  full_name?: string;
  case_assignment_availability?: boolean;
  is_message_availability?: boolean;
  is_out_of_office?: boolean;
  profile_url?: string;
  specialty?: string;
  phone_number?: string;
  priority?: string;
  suffix?: string;
  permissions: { name: string }[];
}

export interface Documentation {
  id?: string;
  key: string;
  title: string;
  description: string;
  type: 'information' | 'process';
}

export interface Parameter {
  id?: string;
  key: string;
  value: string;
  type: 'web' | 'patient' | 'clinician';
}

export interface Review<T> {
  action: 'create' | 'update' | 'delete' | 'restore';
  id: string;
  model_id: string;
  model_type: string;
  parent_model_id?: string;
  parent_model_type?: string;
  user?: User;
  model?: T;
  payload: T;
}

export interface Supply {
  order?: number;
  name: string;
  ndc: string;
  dosespot_supply_id: number;
  title: string;
  supply_id?: string;
  effective_date?: number;
  days_supply: number;
  pharmacy_notes: string;
  directions: string;
  quantity: string;
  refills: number;
  dispense_unit_id?: string;
  dispense_unit?: string;
  id?: string;
  pharmacy_id?: string;
  pharmacy_name?: string;
  force_pharmacy: boolean;
  metadata: string;
  thank_you_note?: string;
  clinical_note?: string;
  upcharge: number;
  clinician_extra_fee: number;
  partner_id?: string;
  is_additional_approval_needed?: boolean;
  is_important?: boolean;
  review: Review<Supply>;
  active?: boolean;
  specialty_id?: string;
}

export interface Service {
  order?: number;
  title: string;
  service_type: string;
  directions: string;
  description: string;
  additional_note: string;
  include_drivers_license: boolean;
  send_pdf_file_automatically: boolean;
  id?: string;
  metadata: string;
  thank_you_note?: string;
  clinical_notes?: string;
  upcharge: number;
  clinician_extra_fee: number;
  is_additional_approval_needed?: boolean;
  is_important?: boolean;
  partner_id?: string;
  review: Review<Service>;
  active?: boolean;
  specialty_id?: string;
}

export interface Compound {
  order?: number;
  name: string;
  title: string;
  effective_date?: number;
  days_supply: number;
  pharmacy_notes: string;
  directions: string;
  quantity: string;
  refills: number;
  dispense_unit_id?: string;
  dispense_unit?: string;
  id?: string;
  partner_id?: string;
  pharmacy_id?: string;
  pharmacy_name?: string;
  force_pharmacy: boolean;
  allow_substitutions: boolean;
  metadata: string;
  thank_you_note?: string;
  clinical_note?: string;
  upcharge: number;
  clinician_extra_fee: number;
  is_additional_approval_needed?: boolean;
  is_important?: boolean;
  review: Review<Compound>;
  active?: boolean;
  specialty_id?: string;
}

export interface Medication {
  order?: number;
  name?: string;
  strength?: string;
  title: string;
  medication_id?: string;
  effective_date?: number;
  days_supply: number;
  directions: string;
  quantity: string;
  refills: number;
  dispense_unit_id?: string;
  dispense_unit?: string;
  id?: string;
  ndc?: string;
  partner_id?: string;
  pharmacy_id?: string;
  pharmacy_name?: string;
  pharmacy_notes: string;
  force_pharmacy: boolean;
  allow_substitutions: boolean;
  metadata: string;
  clinical_note?: string;
  thank_you_note?: string;
  dosespot_rxcui: string;
  upcharge: number;
  clinician_extra_fee: number;
  is_important?: boolean;
  is_insurance_check_needed?: boolean;
  is_additional_approval_needed?: boolean;
  review: Review<Medication>;
  active?: boolean;
  specialty_id?: string;
}

export interface Questionnaire {
  id?: string;
  partner_id?: string;
  name: string;
  intro_title: string;
  intro_description: string;
  outro_title: string;
  outro_description: string;
  redirect_url?: string;
  redirect_title?: string;
  has_pharmacy: boolean;
  always_ask_weight_height: boolean;
  ask_blood_pressure: boolean;
  is_pending_review?: boolean;
  terms_of_service?: string;
  created_at?: string;
  updated_at?: string;
  questions?: Question[];
  offerings?: Offering[];
  review?: Review<Questionnaire>;
  active?: boolean;
  is_show_welcome?: boolean;
  is_show_thanks?: boolean;
  specialty_id?: string;
}

export interface Offering {
  created_at: string;
  deleted_at: string;
  id: string;
  offerable_id: string;
  offerable_type: string;
  offerable: Medication | Compound | Service | Supply;
  partner_questionnaire_id: string;
  rules: Rule[];
  rule_type?: 'and' | 'or' | 'not';
  updated_at: string;
  review?: Review<Offering>;
}

export interface Question {
  id: string;
  title: string;
  description?: string;
  label?: string;
  placeholder?: string;
  order?: number;
  default_value?: string;
  display_in_pdf?: boolean;
  is_important?: boolean;
  is_critical?: boolean;
  is_optional?: boolean;
  is_visible?: boolean;
  has_next_button?: boolean;
  has_back_button?: boolean;
  is_pending_review?: boolean;
  type: QuestionType;
  options?: Option[];
  rules?: Rule[];
  rule_type?: 'and' | 'or' | 'not';
  files: File[];
  review?: Review<Question>;
  specialty_id?: string;
  partner_id?: string;
}

export interface Rule {
  id?: string;
  title: string;
  type: 'and' | 'or' | 'not';
  requirements: Requirement[];
  review?: Review<Rule>;
}

export interface Requirement {
  id?: string;
  when?: string;
  based_on:
    | 'question'
    | 'age'
    | 'gender'
    | 'pregnancy'
    | 'blood_pressure'
    | 'bmi'
    | 'height'
    | 'weight'
    | 'state'
    | 'prescription';
  conditional_answer: 'equals' | 'contains' | 'start_with' | 'end_with' | '<' | '>' | '<=' | '>=';
  required_answer: any;
  required_question_id: string;
  required_question?: Question;
  review?: Review<Requirement>;
}

export interface Option {
  id: string;
  title: string;
  is_important?: boolean;
  is_critical?: boolean;
  is_changed?: boolean;
  icon?: any;
  icon_type?: 'emoji' | 'icon' | 'image';
  order?: number;
  review?: Review<Option>;
}

export interface Patient {
  abbreviated_name: string;
  active: boolean;
  address_id: string;
  allergies: string;
  created_at: string;
  current_medications: string;
  driver_license_id: string;
  email: string;
  first_name: string;
  full_name: string;
  gender: number;
  height: number;
  id: string;
  last_name: string;
  metadata: string;
  partner_id: string;
  phone_number: string;
  phone_type: string;
  prefix: string;
  pregnancy: boolean;
  updated_at: string;
  weight: number;
  is_live: boolean;
}

export interface Encounter {
  id: string;
  patient_id: string;
  partner_id: string;
  reference_case_id: string;
  is_sync: boolean;
  case_type: 'new' | 'follow-up';
  case_assignment_id: string;
  case_status_id: string;
  service_pdf_exported: boolean;
  is_chargeable: boolean;
  partner_amount_charged: number;
  metadata: string;
  created_at: string;
  updated_at: string;
}

export interface ExtendedEncounter extends Encounter {
  case_services: Service[];
  case_prescriptions: any[];
  case_questions: Question[];
  patient: Patient;
  case_status: {
    id: string;
    name: string;
  };
}

export interface Voucher {
  case_id: string;
  created_at: string;
  demo: boolean;
  expires_at: string;
  id: string;
  partner_id: string;
  pharmacy_id?: number;
  pharmacy_name?: string;
  payload: VoucherPayload;
  updated_at: string;
  expired: boolean;
}

export interface VoucherPayload {
  questionnaire_id: string;
  patient_id?: string;
  hold_status: boolean;
  demo?: boolean;
  clinician_id?: string;
  expires_at: string;
  medications?: { id: string }[];
  compounds?: { id: string }[];
  supplies?: { id: string }[];
  diseases?: { id: string }[];
}

export interface Ticket {
  created_at: string;
  created_by_panel_user_id: string;
  description: string;
  environment: string;
  id: string;
  is_urgent: boolean;
  partner_id: string;
  product: string;
  requester_email: string;
  status: string;
  title: string;
  type: string;
  updated_at: string;
}

export interface TestWebhook {
  event: string;
  metadata?: string;
  encounter_id?: string;
  voucher_id?: string;
  patient_id?: string;
  message_id?: string;
  channel?: string;
  from?: string;
  message?: string;
}

export interface Webhook {
  id?: string;
  identifier?: string;
  partner_id?: string;
  webhook_url: string;
  webhook_secret: string;
  webhook_authorization: string;
}

export interface PartnerFile {
  created_at: string;
  deleted_at: string;
  id: string;
  mime_type: string;
  name: string;
  path: string;
  pivot: {
    created_at: string;
    file_id: string;
    partner_id: string;
    title: string;
    type: string;
    updated_at: string;
  };
  thumbnail_path: string;
  thumbnail_url: string;
  updated_at: string;
  url: string;
}

export interface WebhookLog {
  id: string;
  queue: string;
  payload: string;
  status: string;
  dispatch: string;
  attempt: number;
  sqs: string;
  webhook: string;
  delay_seconds: 0;
  partner_environment_id: string;
  partner_id: string;
  model_type: string;
  model_id: string;
  created_at: string;
  updated_at: string;
  deleted_at: null;
}

export interface AuditLog {
  id: string;
  event: string;
  new_values: any;
  old_values: string;
  user: User;
  user_type: string;
  created_at: string;
  updated_at: string;
  deleted_at: null;
}

export interface PartnerStatistics {
  cache: {
    date: string;
    startDate: string;
    endDate: string;
    partner_id: string;
  };
  cases: {
    count_cases_chargeable?: StatisticItem[];
    count_cases_completed_cancelled?: StatisticItem[];
    count_cases_extended_care_new?: StatisticItem[];
    count_cases_non_chargeable?: StatisticItem[];
    count_cases_routine_new?: StatisticItem[];
    count_extended_care_follow_up?: StatisticItem[];
    count_routine_follow_up?: StatisticItem[];
  };
  sms: {
    count_sms?: StatisticItem[];
  };
  clinician_message_response_time: {
    average_response_time_seconds?: number;
  };
  support_message_response_time: {
    average_response_time_seconds?: number;
  };
  clinician_message_response_time_per_full_month: {
    average_response_time_seconds?: StatisticItem[];
  };
  support_message_response_time_per_full_month: {
    average_response_time_seconds?: StatisticItem[];
  };
  message_resolved: {
    channel: string;
    count_dismissed_messages: number;
    count_messages: number;
    dismissed_by_user_type_name: string;
    perc_dismissed_messages: number;
    user_type_name: string;
  }[];
  patient_age: {
    count_patients?: { age_interval: string; value: number }[];
  };
  questionnaire_dropout: {
    count_questionnaires_dropout?: { dropped_from: string; value: number }[];
    average_dropout_by_page?: { dropped_from: string; value: number }[];
  };
  vouchers: {
    count_cases?: number;
    count_cases_generated_by_vouchers?: number;
    count_cases_not_generated_by_vouchers?: number;
    perc_cases_generated_by_vouchers?: number;
    perc_cases_not_generated_by_vouchers?: number;
  };
  voucheds: {
    count_voucheds_runs?: StatisticItem[];
  };
  questionnaire_completion: {
    count_questionnaires?: number;
    count_questionnaires_finished?: number;
    count_questionnaires_not_finished?: number;
    perc__questionnaires_finished?: number;
    perc_questionnaires_not_finished?: number;
  };
  questionnaire_completion_time: {
    average_completion_time_min?: number;
  };
  patient_states: {
    count_patients?: { states_name: string; value: number }[];
  };
  case_completion_time: {
    average_completion_time_seconds_new?: StatisticItem[];
    average_completion_time_seconds_follow_up?: StatisticItem[];
  };
  patient_gender: {
    count_patients?: { gender_txt: string; value: number }[];
  };
  case_statuses: {
    count_cases_status_assigned?: number;
    count_cases_status_cancelled?: number;
    count_cases_status_completed?: number;
    count_cases_status_created?: number;
    count_cases_status_processing?: number;
    count_cases_status_support?: number;
    count_cases_status_waiting?: number;
  };
  patient_activity: {
    count_patient_active_last_year?: number;
    count_patient_inactive_last_year?: number;
    count_patient_total?: number;
  };
  patient_turnaround_time: {
    avg_turnaround_time_sec?: number;
  };
  patient_turnaround_time_interval: {
    perc_patient_per_turnaround_interval?: {
      patient_turnaround_interval: string;
      value: number;
    }[];
  };
  churn_rate: {
    count_patients?: {
      patient_status: string;
      patient_status_category: string;
      value: number;
    };
    perc_patients_per_status?: {
      patient_status: string;
      patient_status_category: string;
      value: number;
    };
  };
  message_text_length: {
    avg_text_length?: {
      channel: string;
      user_type_name: string;
      value: number;
    }[];
  };
  message_dismissed_ratio: {
    count_dismissed_messages?: {
      patient: StatisticChannelItem;
      support: StatisticChannelItem;
    };
    count_not_dismissed_messages?: {
      patient: StatisticChannelItem;
      support: StatisticChannelItem;
    };
    count_total_messages?: {
      patient: StatisticChannelItem;
      support: StatisticChannelItem;
    };
    perc_dismissed_messages?: {
      patient: StatisticChannelItem;
      support: StatisticChannelItem;
    };
  };
  message_dismissed_ratio_by_user_type: {
    count_dismissed_messages?: {
      patient: StatisticChannelItem[];
      support: StatisticChannelItem[];
    };
    perc_dismissed_messages?: {
      patient: StatisticChannelItem[];
      support: StatisticChannelItem[];
    };
  };
}

export interface EmailStyle {
  id: string;
  model_type: string;
  model_id: string;
  key: string;
  value: string;
  scope: string;
  metadata: string;
  emailed_at: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}
