<div class="c-field c-field--textarea" [class.c-field--disabled]="isDisabled"
  [class.c-field--changed]="changed">
  <textarea class="c-field__input" [class.c-field__input--placeholder]="placeholder"
    [placeholder]="(placeholder | translate) || ''" [rows]="rows" [formControl]="ngControl.control">
  </textarea>

  <h6 class="c-field__label">
    {{ label | translate }}

    <span *ngIf="isOptional && showOptional">
      ({{ 'LABEL.OPTIONAL' | translate }})
    </span>
  </h6>

  <p class="c-field__notes">
    <span *ngIf="maxLength" [class.u-color--error]="ngControl.control.value?.length >= maxLength">
      {{value?.length || 0}}/{{ maxLength }}
    </span>

    <app-field-error-message [currentControl]="ngControl.control"></app-field-error-message>
  </p>
</div>