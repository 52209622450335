<app-modal (close)="dismiss()">
  <h3 class="c-modal__title u-margin-bottom--sm">
    {{ 'MODALS.MANAGE_DEVICES.TITLE' | translate }}
  </h3>

  <ng-container *ngIf="!loading; else loadingState">
    <p class="c-modal__description">
      {{ 'MODALS.MANAGE_DEVICES.DESCRIPTION' | translate }}
    </p>

    <ng-container *ngIf="devices$ | async as devices">
      <ng-container *ngIf="devices?.length > 0; else emptyState">
        <div class="c-list c-list--double">
          <ng-container *ngFor="let item of devices">
            <div class="c-list__item">
              <div class="c-list__content">
                <h6 class="c-list__first">
                  {{ item.device_browser }} on {{ item.device_name }}
                </h6>
                <p class="c-list__second">
                  {{ item.created_at | date: 'MMM dd' }}, {{ item.device_location }}
                </p>
              </div>

              <div class="c-list__status" [title]="item.expired_at | date: 'MMM dd'">
                Exp. {{ item.expired_at | timeAgo:true:true }}
              </div>

              <div class="c-list__action u-margin-left--sm">
                <div class="c-link c-link--colored" [class.c-link--disabled]="submitting"
                  (click)="remove(item)">
                  <app-icon name="trash-outline"></app-icon>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #loadingState>
    <app-loading></app-loading>
  </ng-template>

  <ng-template #emptyState>
    <div class="c-emptystate c-emptystate--xs u-margin-top--lg u-margin-bottom--lg">
      <p class="c-emptystate__label">
        {{ 'MODALS.MANAGE_DEVICES.EMPTY_STATE' | translate }}
      </p>
    </div>
  </ng-template>

  <div class="c-modal__footer">
    <app-button (click)="dismiss()" [disabled]="submitting" class="u-hide--xs" variant="secondary">
      {{ 'BUTTONS.CLOSE' | translate }}
    </app-button>
  </div>
</app-modal>