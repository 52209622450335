import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit {
  @Input() name = '';

  @Input() src = '';

  @Input() title = '';

  @Input() fill = false;

  @Input() stroke = true;

  ngOnInit() {
    if (this.name) {
      this.src = `./assets/icons/${this.name}.svg`;
    }
  }
}
