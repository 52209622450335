<footer class="e-footer l-wrapper">
  <div class="l-container">
    <div class="l-grid l-grid--2">
      <div class="l-grid__item">
        <a [title]="'FOOTER.HOME' | translate" [routerLink]="['./']">
          <img class="e-footer__logo" alt="Footer Logo" height="28" width="270"
            src="./assets/images/logo-white.svg" loading="lazy" />
        </a>

        <p class="u-font-size--sm2 u-margin-top--sm">
          {{ 'FOOTER.HOME_ALT' | translate}}
        </p>

        <nav class="e-footer__nav e-footer__nav--store">
          <a class="c-link e-footer__nav-item" target="_blank" [href]="appstoreURL"
            [title]="'FOOTER.APP_STORE' | translate">
            <app-icon name="logo-apple-appstore" [fill]="true"></app-icon>
          </a>

          <a class="c-link e-footer__nav-item" target="_blank" [href]="playstoreURL"
            [title]="'FOOTER.PLAY_STORE' | translate">
            <app-icon name="logo-google-playstore" [fill]="true"></app-icon>
          </a>
        </nav>

        <p class="e-footer__cc">
          © {{ currentYear }} {{ 'FOOTER.CC' | translate}}
        </p>
      </div>

      <div class="l-grid__item">
        <div class="e-footer__wrapper">
          <nav class="e-footer__nav u-hide--md">
            <a class="c-link e-footer__nav-item" [routerLink]="['./', 'about']">
              {{ 'FOOTER.COMPANY' | translate}}
            </a>

            <a class="c-link e-footer__nav-item" [routerLink]="['./', 'clinicians']">
              {{ 'FOOTER.CARE_TEAM' | translate}}
            </a>

            <a class="c-link e-footer__nav-item" [routerLink]="['./', 'solutions']">
              {{ 'FOOTER.SOLUTIONS' | translate}}
            </a>

            <a class="c-link e-footer__nav-item" [routerLink]="['./', 'faq']">
              {{ 'FOOTER.FAQ' | translate}}
            </a>
          </nav>

          <nav class="e-footer__nav u-hide--md">
            <a class="c-link e-footer__nav-item" [routerLink]="['./', 'contact']">
              {{ 'FOOTER.GET_IN_TOUCH' | translate}}
            </a>

            <a class="c-link e-footer__nav-item" [routerLink]="['./', 'login']">
              {{ 'FOOTER.LOG_IN' | translate}}
            </a>

            <a class="c-link e-footer__nav-item" [routerLink]="['./', 'register']">
              {{ 'FOOTER.SIGN_UP' | translate}}
            </a>

            <a class="c-link e-footer__nav-item" href="http://status.mdintegrations.com/"
              target="_blank">
              {{ 'FOOTER.SERVICE_STATUS' | translate}}
            </a>
          </nav>

          <nav class="e-footer__nav e-footer__nav--alt">
            <a class="c-link e-footer__nav-item" [routerLink]="['./', 'tos']">
              {{ 'FOOTER.TOS' | translate}}
            </a>

            <a class="c-link e-footer__nav-item" [routerLink]="['./', 'data']">
              {{ 'FOOTER.PRIVACY' | translate}}
            </a>

            <a class="c-link e-footer__nav-item" [routerLink]="['./partner', 'security']">
              {{ 'FOOTER.SECURITY' | translate}}
            </a>
          </nav>
        </div>

        <div class="e-footer__certification u-hide--md">
          <img loading="lazy" alt="HIPAA" width="70" height="39"
            src="./assets/images/hipaa-white.svg" />

          <img loading="lazy" alt="SOC2" width="92" height="39"
            src="./assets/images/soc2-white.svg" />

          <a href="https://legitscript.com" target="_blank" title="Verify LegitScript Approval">
            <img loading="lazy" src="https://static.legitscript.com/seals/11418033.png"
              alt="LegitScript approved" width="100" height="86" />
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="l-container u-padding-top--none">
    <hr class="u-margin-top--sm u-margin-bottom--sm">

    <iframe loading="lazy" title="MDI Status" class="e-footer__badge"
      src="https://status.mdintegrations.com/badge?theme=dark" width="230" height="30"
      frameborder="0" scrolling="no"></iframe>
  </div>
</footer>