import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { PopoverService } from './popover.service';

export interface Delegate<T> {
  (...args: any[]): T;
}

@Directive({
  selector: '[popover]',
})
export class PopoverDirective {
  @Input('popover') popover;

  listenerEvent;

  constructor(private popoverService: PopoverService, private elementRef: ElementRef) {}

  @HostListener('click', ['$event'])
  click(e) {
    e.stopPropagation();
    e.preventDefault();

    const pos = this.elementRef.nativeElement.getBoundingClientRect();

    const { x, y, width, height } = pos;

    let buttons = this.popover;

    if (typeof buttons === 'function') {
      buttons = this.popover();
    }

    this.popoverService.open(buttons, x, y, width, height);
    this.bindEvents();
  }

  @HostListener('document:click', ['$event'])
  close() {
    this.popoverService.reset();

    this.listenerEvent = null;

    document.removeEventListener('scroll', this.listenerEvent);
    window.removeEventListener('resize', this.listenerEvent);
  }

  private bindEvents() {
    this.listenerEvent = this.close.bind(this);

    document.addEventListener('scroll', this.listenerEvent);
    window.addEventListener('resize', this.listenerEvent);
  }
}
