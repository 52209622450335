import { Component, OnInit } from '@angular/core';
import { ModalService } from 'angular-modal-simple';
// eslint-disable-next-line import/no-cycle
import { CookieService } from '../../states/cookie/cookie.service';

@Component({
  selector: 'app-cookie-modal',
  templateUrl: './cookie-modal.component.html',
  styleUrls: ['./cookie-modal.component.scss'],
})
export class CookieModalComponent implements OnInit {
  constructor(private modalService: ModalService, private cookieService: CookieService) {}

  ngOnInit() {}

  accept() {
    this.cookieService.enable();
    this.dismiss();
  }

  decline() {
    this.cookieService.disable();
    this.dismiss();
  }

  dismiss() {
    return this.modalService.close(CookieModalComponent);
  }
}
