import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { AngularSvgIconModule, SvgIconRegistryService } from 'angular-svg-icon';
import { HttpClientModule } from '@angular/common/http';
import { ButtonComponent } from './components/button/button.component';
import { IconComponent } from './components/icon/icon.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ModalComponent } from './components/modal/modal.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { CheckmarkComponent } from './components/checkmark/checkmark.component';
import { CopyClipboardDirective } from './directives/copy-to-clipboard.directive';
import { CodePipe } from './pipes/code.pipe';
import { LocationTranslatePipe } from './pipes/locationTranslate.pipe';
import { PopoverComponent } from './components/popover/popover.component';
import { PopoverDirective } from './components/popover/popover.directive';

@NgModule({
  declarations: [
    ButtonComponent,
    IconComponent,
    HeaderComponent,
    FooterComponent,
    LoadingComponent,
    ModalComponent,
    AvatarComponent,
    CheckmarkComponent,
    LocationTranslatePipe,
    CodePipe,
    CopyClipboardDirective,
    PopoverComponent,
    PopoverDirective,
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    TranslateModule.forChild({}),
  ],
  exports: [
    TranslateModule,
    ButtonComponent,
    IconComponent,
    HeaderComponent,
    FooterComponent,
    LoadingComponent,
    ModalComponent,
    AvatarComponent,
    CheckmarkComponent,
    LocationTranslatePipe,
    CodePipe,
    CopyClipboardDirective,
    PopoverComponent,
    PopoverDirective,
  ],
  providers: [SvgIconRegistryService, LocationTranslatePipe, CodePipe, CopyClipboardDirective],
})
export class SharedModule {}
