import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() size: 'lg' | '' | 'sm' = '';

  @Input() variant: 'primary' | 'secondary' | 'terciary' | 'delete' = 'primary';

  @Input('arrow') hasArrow: boolean;

  @Input() icon: string;

  @Input() type: string = 'button';

  @Input() loading: boolean;

  @Input() disabled: boolean;
}
