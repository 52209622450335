import { Injectable } from '@angular/core';
import { Query, toBoolean } from '@datorama/akita';
import { CookieState, CookieStore } from './cookie.store';

@Injectable({ providedIn: 'root' })
export class CookieQuery extends Query<CookieState> {
  enabled$ = this.select((state) => toBoolean(state.enabled));

  constructor(protected store: CookieStore) {
    super(store);
  }

  answered(): boolean {
    return this.getValue().enabled !== null;
  }

  enabled(): boolean {
    return toBoolean(this.getValue().enabled);
  }
}
