<span class="e-header__scroller" #scrollElement></span>
<header class="e-header l-wrapper" [class.e-header--fixed]="scrolled"
  [class.e-header--expanded]="expanded" (click)="unsetExpanded()">
  <div class="e-header__container l-container">
    <ng-container *ngIf="header !== 'default'; else registerLogo">
      <ng-container *ngIf="user$ | async as user; else registerLogo">
        <a class="e-header__logo e-header__logo--lg"
          [routerLink]="['partner', (partner$ | async)?.id || 'switch']">
          <img width="33" height="33" alt="MD Integrations Logo"
            src=" ./assets/images/logo-icon.svg" />
        </a>
      </ng-container>
    </ng-container>

    <ng-template #registerLogo>
      <a class="e-header__logo" [routerLink]="['./']">
        <img width="260" height="33" alt="MD Integrations Logo" src="./assets/images/logo.svg" />
      </a>
    </ng-template>

    <nav class="e-header__nav" [class.e-header__nav--hover]="hoverable"
      (click)="$event.stopPropagation();">
      <div class="e-header__wrapper">
        <ng-container *ngIf="!(user$ | async)">
          <a class="e-header__logo" [routerLink]="['./']">
            <img width="260" height="33" alt="MD Integrations Logo"
              src="./assets/images/logo.svg" />
          </a>
        </ng-container>

        <button aria-label="Close Menu" name="Close Menu"
          class="e-header__button e-header__button--close" (click)="unsetExpanded()">
          <app-icon name="close-outline"></app-icon>
        </button>

        <ng-container *ngIf="user$ | async as user">
          <ng-container *ngIf="header === 'admin'">
            <div class="e-header__nav-separator u-margin-left--sm u-hide--sm"></div>

            <h3 class="e-header__nav-item e-header__nav-title">
              <span class="u-text-ellipsis">
                {{ 'PARTNERS.SECTION_1.TITLE' | translate}}
              </span>
            </h3>

            <div class="u-margin-right--auto"></div>

            <a class="c-link e-header__nav-menu u-hide--md" [routerLink]="['./partner', 'switch']"
              (click)="unsetExpanded()">
              <app-icon name="swap-horizontal-outline" [title]="'NAV.SWITCH_PARTNER' | translate">
              </app-icon>
            </a>

            <div class="e-header__nav-separator u-hide--sm"></div>
          </ng-container>

          <ng-container *ngIf="header === 'no-partner'">
            <div class="e-header__nav-separator u-margin-left--sm u-hide--sm"></div>

            <h3 class="e-header__nav-item e-header__nav-title">
              <span class="u-text-ellipsis">
                {{ 'PARTNERS.SECTION_2.TITLE' | translate}}
              </span>
            </h3>

            <div class="u-margin-right--auto"></div>
          </ng-container>

          <ng-container *ngIf="header === 'partner'">
            <ng-container *ngIf="partner$ | async as partner">
              <div class="e-header__nav-separator u-margin-left--sm u-hide--sm"></div>

              <h3 class="e-header__nav-item e-header__nav-title">
                <a class="c-link u-text-ellipsis" [routerLink]="['./partner', partner.id]"
                  [routerLinkActiveOptions]="{exact : true}" routerLinkActive="c-link--active"
                  (click)="unsetExpanded()">
                  <span class="u-text-ellipsis u-margin-top--xs" [title]="partner.name">
                    {{ partner.name }}
                  </span>
                </a>
              </h3>

              <ng-container *ngIf="hasPartnerLinks">
                <label class="e-header__nav-item">
                  <input class="e-header__nav-toggle" type="checkbox" #checkbox>

                  <a class="c-link">
                    {{ 'NAV.ACCOUNT' | translate}}
                    <app-icon name="chevron-down-outline"></app-icon>
                  </a>

                  <div class="e-header__nav-list">
                    <div class="e-header__nav-list-wrapper">
                      <div class="l-container">
                        <div class="e-header__nav-list-item">
                          <app-icon class="e-header__nav-list-icon" name="business-outline">
                          </app-icon>
                          <a class="c-link e-header__nav-list-heading"
                            [routerLink]="['./partner', partner.id, 'details']"
                            routerLinkActive="c-link--active" (click)="unsetExpanded()">
                            {{ 'NAV.COMPANY' | translate}}
                            <app-icon name="chevron-forward-outline"></app-icon>
                          </a>
                          <a class="c-link c-link--colored e-header__nav-list-sublink"
                            [routerLink]="['./partner', partner.id, 'details']"
                            routerLinkActive="c-link--active" fragment="details"
                            (click)="unsetExpanded()">
                            {{ 'NAV.COMPANY_DETAILS' | translate}}
                          </a>
                          <a class="c-link c-link--colored e-header__nav-list-sublink"
                            [routerLink]="['./partner', partner.id, 'details']"
                            routerLinkActive="c-link--active" fragment="compliance"
                            (click)="unsetExpanded()">
                            {{ 'NAV.COMPLIANCE' | translate}}
                          </a>
                          <a class="c-link c-link--colored e-header__nav-list-sublink"
                            [routerLink]="['./partner', partner.id, 'details']"
                            routerLinkActive="c-link--active" fragment="documents"
                            (click)="unsetExpanded()">
                            {{ 'NAV.DOCUMENTS' | translate}}
                          </a>
                          <a class="c-link c-link--colored e-header__nav-list-sublink"
                            [routerLink]="['./partner', partner.id, 'details']"
                            routerLinkActive="c-link--active" fragment="admins"
                            (click)="unsetExpanded()">
                            {{ 'NAV.ADMIN_USERS' | translate}}
                          </a>
                        </div>

                        <div class="e-header__nav-list-item">
                          <app-icon class="e-header__nav-list-icon" name="logo-apple-ar"></app-icon>
                          <a class="c-link e-header__nav-list-heading"
                            [routerLink]="['./partner', partner.id, 'branding']"
                            routerLinkActive="c-link--active" (click)="unsetExpanded()">
                            {{ 'NAV.BRANDING' | translate}}
                            <app-icon name="chevron-forward-outline"></app-icon>
                          </a>
                          <a class="c-link c-link--colored e-header__nav-list-sublink"
                            [routerLink]="['./partner', partner.id, 'branding']"
                            routerLinkActive="c-link--active" fragment="branding"
                            (click)="unsetExpanded()">
                            {{ 'NAV.LOGO_COLORS' | translate}}
                          </a>
                          <a class="c-link c-link--colored e-header__nav-list-sublink"
                            [routerLink]="['./partner', partner.id, 'branding']"
                            routerLinkActive="c-link--active" fragment="emails"
                            (click)="unsetExpanded()">
                            {{ 'NAV.EMAILS' | translate}}
                          </a>
                          <a class="c-link c-link--colored e-header__nav-list-sublink"
                            [routerLink]="['./partner', partner.id, 'branding']"
                            routerLinkActive="c-link--active" fragment="domain"
                            (click)="unsetExpanded()">
                            {{ 'NAV.DOMAIN' | translate}}
                          </a>
                        </div>

                        <div class="e-header__nav-list-item">
                          <app-icon class="e-header__nav-list-icon" name="cash-outline"></app-icon>
                          <a class="c-link e-header__nav-list-heading"
                            [routerLink]="['./partner', partner.id, 'financial']"
                            routerLinkActive="c-link--active" (click)="unsetExpanded()">
                            {{ 'NAV.FINANCIALS' | translate}}
                            <app-icon name="chevron-forward-outline"></app-icon>
                          </a>
                          <a class="c-link c-link--colored e-header__nav-list-sublink"
                            [routerLink]="['./partner', partner.id, 'financial']"
                            routerLinkActive="c-link--active" fragment="rates"
                            (click)="unsetExpanded()">
                            {{ 'NAV.RATES' | translate}}
                          </a>
                          <a class="c-link c-link--colored e-header__nav-list-sublink"
                            [routerLink]="['./partner', partner.id, 'financial']"
                            routerLinkActive="c-link--active" fragment="invoices"
                            (click)="unsetExpanded()">
                            {{ 'NAV.INVOICES' | translate}}
                          </a>
                          <a class="c-link c-link--colored e-header__nav-list-sublink"
                            [routerLink]="['./partner', partner.id, 'financial']"
                            routerLinkActive="c-link--active" fragment="help"
                            (click)="unsetExpanded()">
                            {{ 'NAV.EXPORT_ENCOUNTERS' | translate}}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </label>

                <label class="e-header__nav-item">
                  <input class="e-header__nav-toggle" type="checkbox" #checkbox>

                  <a class="c-link">
                    {{ 'NAV.INTEGRATION' | translate}}
                    <app-icon name="chevron-down-outline"></app-icon>
                  </a>

                  <div class="e-header__nav-list">
                    <div class="e-header__nav-list-wrapper">
                      <div class="l-container">
                        <div class="e-header__nav-list-item">
                          <app-icon class="e-header__nav-list-icon" name="color-wand-outline">
                          </app-icon>
                          <a class="c-link e-header__nav-list-heading"
                            [routerLink]="['./partner', partner.id, 'apps']"
                            routerLinkActive="c-link--active" (click)="unsetExpanded()">
                            {{ 'NAV.SETUP_APPS' | translate}}
                            <app-icon name="chevron-forward-outline"></app-icon>
                          </a>
                          <a class="c-link c-link--colored e-header__nav-list-sublink"
                            [routerLink]="['./partner', partner.id, 'apps']"
                            routerLinkActive="c-link--active" fragment="white_label"
                            (click)="unsetExpanded()">
                            {{ 'NAV.WHITE_LABEL' | translate}}
                          </a>
                          <a class="c-link c-link--colored e-header__nav-list-sublink"
                            [routerLink]="['./partner', partner.id, 'apps']"
                            routerLinkActive="c-link--active" fragment="analytics"
                            (click)="unsetExpanded()">
                            {{ 'NAV.ANALYTICS' | translate}}
                          </a>
                          <a class="c-link c-link--colored e-header__nav-list-sublink"
                            [routerLink]="['./partner', partner.id, 'apps']"
                            routerLinkActive="c-link--active" fragment="notifications"
                            (click)="unsetExpanded()">
                            {{ 'NAV.NOTIFICATIONS' | translate}}
                          </a>
                          <a class="c-link c-link--colored e-header__nav-list-sublink"
                            [routerLink]="['./partner', partner.id, 'apps']"
                            routerLinkActive="c-link--active" fragment="workflows"
                            (click)="unsetExpanded()">
                            {{ 'NAV.WORKFLOWS' | translate}}
                          </a>
                        </div>

                        <div class="e-header__nav-list-item">
                          <a class="c-link c-link--colored e-header__nav-list-sublink u-margin-top--xl"
                            [routerLink]="['./partner', partner.id, 'apps']"
                            routerLinkActive="c-link--active" fragment="shopify"
                            (click)="unsetExpanded()">
                            {{ 'NAV.SHOPIFY' | translate}}
                          </a>
                          <a class="c-link c-link--colored e-header__nav-list-sublink"
                            [routerLink]="['./partner', partner.id, 'apps']"
                            routerLinkActive="c-link--active" fragment="help"
                            (click)="unsetExpanded()">
                            {{ 'NAV.HELP' | translate}}
                          </a>
                        </div>

                        <div class="e-header__nav-list-item">
                          <app-icon class="e-header__nav-list-icon" name="cube-outline"></app-icon>
                          <a class="c-link e-header__nav-list-heading"
                            [routerLink]="['./partner', partner.id, 'tech']"
                            routerLinkActive="c-link--active" (click)="unsetExpanded()">
                            {{ 'NAV.RESOURCES' | translate}}
                            <app-icon name="chevron-forward-outline"></app-icon>
                          </a>
                          <a class="c-link c-link--colored e-header__nav-list-sublink"
                            [routerLink]="['./partner', partner.id, 'tech']"
                            routerLinkActive="c-link--active" fragment="credentials"
                            (click)="unsetExpanded()">
                            {{ 'NAV.CREDENTIALS' | translate}}
                          </a>
                          <a class="c-link c-link--colored e-header__nav-list-sublink"
                            [routerLink]="['./partner', partner.id, 'tech']"
                            routerLinkActive="c-link--active" fragment="webhooks"
                            (click)="unsetExpanded()">
                            {{ 'NAV.WEBHOOKS' | translate}}
                          </a>
                          <a class="c-link c-link--colored e-header__nav-list-sublink"
                            [routerLink]="['./partner', partner.id, 'tech']"
                            routerLinkActive="c-link--active" fragment="test_bench"
                            (click)="unsetExpanded()">
                            {{ 'NAV.TEST_BENCH' | translate}}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </label>

                <label class="e-header__nav-item">
                  <input class="e-header__nav-toggle" type="checkbox" #checkbox>

                  <a class="c-link">
                    {{ 'NAV.OPERATIONS' | translate}}
                    <app-icon name="chevron-down-outline"></app-icon>
                  </a>

                  <div class="e-header__nav-list">
                    <div class="e-header__nav-list-wrapper">
                      <div class="l-container">
                        <div class="e-header__nav-list-item">
                          <app-icon class="e-header__nav-list-icon" name="storefront-outline">
                          </app-icon>
                          <a class="c-link e-header__nav-list-heading"
                            [routerLink]="['./partner', partner.id, 'operations']"
                            routerLinkActive="c-link--active" fragment="email"
                            (click)="unsetExpanded()">
                            {{ 'NAV.MANAGEMENT' | translate}}
                            <app-icon name="chevron-forward-outline"></app-icon>
                          </a>
                          <a class="c-link c-link--colored e-header__nav-list-sublink"
                            [routerLink]="['./partner', partner.id, 'operations']"
                            routerLinkActive="c-link--active" fragment="support_process"
                            (click)="unsetExpanded()">
                            {{ 'NAV.SUPPORT_PROCESS' | translate}}
                          </a>
                          <a class="c-link c-link--colored e-header__nav-list-sublink"
                            [routerLink]="['./partner', partner.id, 'operations']"
                            routerLinkActive="c-link--active" fragment="support_staff"
                            (click)="unsetExpanded()">
                            {{ 'NAV.SUPPORT_STAFF' | translate}}
                          </a>
                          <a class="c-link c-link--colored e-header__nav-list-sublink"
                            [routerLink]="['./partner', partner.id, 'operations']"
                            routerLinkActive="c-link--active" fragment="clinicians"
                            (click)="unsetExpanded()">
                            {{ 'NAV.CLINICIANS' | translate}}
                          </a>
                          <a class="c-link c-link--colored e-header__nav-list-sublink"
                            [routerLink]="['./partner', partner.id, 'operations']"
                            routerLinkActive="c-link--active" fragment="tags"
                            (click)="unsetExpanded()">
                            {{ 'NAV.TAGS' | translate}}
                          </a>
                        </div>

                        <div class="e-header__nav-list-item">
                          <app-icon class="e-header__nav-list-icon" name="medkit-outline">
                          </app-icon>
                          <a class="c-link e-header__nav-list-heading"
                            [routerLink]="['./partner', partner.id, 'offerings']"
                            routerLinkActive="c-link--active" (click)="unsetExpanded()">
                            {{ 'NAV.OFFERINGS' | translate}}
                            <app-icon name="chevron-forward-outline"></app-icon>
                          </a>
                          <a class="c-link c-link--colored e-header__nav-list-sublink"
                            [routerLink]="['./partner', partner.id, 'offerings']"
                            routerLinkActive="c-link--active" fragment="medications"
                            (click)="unsetExpanded()">
                            {{ 'NAV.MEDICATIONS' | translate}}
                          </a>
                          <a class="c-link c-link--colored e-header__nav-list-sublink"
                            [routerLink]="['./partner', partner.id, 'offerings']"
                            routerLinkActive="c-link--active" fragment="compounds"
                            (click)="unsetExpanded()">
                            {{ 'NAV.COMPOUNDS' | translate}}
                          </a>
                          <a class="c-link c-link--colored e-header__nav-list-sublink"
                            [routerLink]="['./partner', partner.id, 'offerings']"
                            routerLinkActive="c-link--active" fragment="supplies"
                            (click)="unsetExpanded()">
                            {{ 'NAV.SUPPLIES' | translate}}
                          </a>
                          <a class="c-link c-link--colored e-header__nav-list-sublink"
                            [routerLink]="['./partner', partner.id, 'offerings']"
                            routerLinkActive="c-link--active" fragment="services"
                            (click)="unsetExpanded()">
                            {{ 'NAV.SERVICES' | translate}}
                          </a>
                        </div>

                        <div class="e-header__nav-list-item">
                          <app-icon class="e-header__nav-list-icon" name="clipboard-outline">
                          </app-icon>
                          <a class="c-link e-header__nav-list-heading"
                            [routerLink]="['./partner', partner.id, 'intakes']"
                            routerLinkActive="c-link--active" (click)="unsetExpanded()">
                            {{ 'NAV.INTAKE' | translate}}
                            <app-icon name="chevron-forward-outline"></app-icon>
                          </a>
                          <a class="c-link c-link--colored e-header__nav-list-sublink"
                            [routerLink]="['./partner', partner.id, 'intakes']"
                            routerLinkActive="c-link--active" fragment="questionnaires"
                            (click)="unsetExpanded()">
                            {{ 'NAV.QUESTIONNAIRES' | translate}}
                          </a>
                          <a class="c-link c-link--colored e-header__nav-list-sublink"
                            [routerLink]="['./partner', partner.id, 'intakes']"
                            routerLinkActive="c-link--active" fragment="vouchers"
                            (click)="unsetExpanded()">
                            {{ 'NAV.VOUCHERS' | translate}}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </label>
              </ng-container>

              <ng-container *ngIf="hasMultiplePartners">
                <div class="e-header__nav-item u-show--md">
                  <a class="c-link" (click)="unsetExpanded()" [routerLink]="['/partner', 'switch']">
                    {{ 'NAV.CHANGE_PARTNER' | translate}}
                  </a>
                </div>
              </ng-container>

              <div class="u-margin-right--auto"></div>

              <ng-container *ngIf="!isAdmin; else adminToolbar">
                <a class="c-link e-header__nav-menu u-hide--md"
                  [popover]="getMenuActions.bind(this)">
                  <app-icon name="apps" [fill]="true"></app-icon>
                </a>
              </ng-container>

              <ng-template #adminToolbar>
                <a class="c-link e-header__nav-menu u-hide--md"
                  [routerLink]="['./partner', partner.id, 'offerings']" (click)="unsetExpanded()">
                  <app-icon name="rx-outline" [title]="'NAV.OFFERINGS' | translate"></app-icon>
                </a>

                <a class="c-link e-header__nav-menu u-hide--md"
                  [routerLink]="['./partner', partner.id, 'intakes']" (click)="unsetExpanded()">
                  <app-icon name="clipboard-icon" [title]="'NAV.INTAKE' | translate"></app-icon>
                </a>
              </ng-template>

              <ng-container *ngIf="hasSlackButton">
                <a class="c-link e-header__nav-menu u-hide--md" (click)="setupSlack(partner.id)">
                  <app-icon name="logo-slack" [title]="'NAV.SLACK' | translate" [fill]="true">
                  </app-icon>
                </a>
              </ng-container>

              <ng-container *ngIf="isAdmin">
                <a class="c-link e-header__nav-menu u-hide--md"
                  [routerLink]="['./partner', 'switch']" (click)="unsetExpanded()">
                  <app-icon name="swap-horizontal-outline"
                    [title]="'NAV.SWITCH_PARTNER' | translate"></app-icon>
                </a>
              </ng-container>

              <div class="e-header__nav-separator u-hide--sm"></div>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="header === 'default'">
          <div class="u-margin-left--auto"></div>

          <div class="e-header__nav-item u-show--md">
            <a class="c-link" [routerLink]="'./'" routerLinkActive="c-link--active"
              (click)="unsetExpanded()">
              {{ 'NAV.HOME' | translate}}
            </a>
          </div>

          <div class="e-header__nav-item">
            <a class="c-link" [routerLink]="'./about'" routerLinkActive="c-link--active"
              (click)="unsetExpanded()">
              {{ 'NAV.COMPANY' | translate}}
            </a>
          </div>

          <div class="e-header__nav-item">
            <a class="c-link" [routerLink]="'./clinicians'" routerLinkActive="c-link--active"
              (click)="unsetExpanded()">
              {{ 'NAV.CLINICIANS' | translate}}
            </a>
          </div>

          <div class="e-header__nav-item">
            <a class="c-link" [routerLink]="'./solutions'" routerLinkActive="c-link--active"
              (click)="unsetExpanded()">
              {{ 'NAV.SOLUTIONS' | translate}}
            </a>
          </div>

          <div class="e-header__nav-item u-show--md">
            <a class="c-link" [routerLink]="'./contact'" routerLinkActive="c-link--active"
              (click)="unsetExpanded()">
              {{ 'NAV.CONTACT' | translate}}
            </a>
          </div>

          <div class="e-header__nav-item u-show--md">
            <a class="c-link" [routerLink]="'./faq'" routerLinkActive="e-header__nav-item--active"
              (click)="unsetExpanded()">
              {{ 'NAV.FAQ' | translate}}
            </a>
          </div>

          <div class="e-header__nav-separator"></div>
        </ng-container>

        <ng-container *ngIf="user$ | async as user; else registerButton">
          <ng-container *ngIf="header === 'default'">
            <div class="e-header__nav-item">
              <a class="c-link" [routerLink]="['/partner', 'switch']" (click)="unsetExpanded()"
                rel="nofollow">
                {{ 'NAV.GOTO_DASHBOARD' | translate}}
              </a>
            </div>
          </ng-container>

          <a class="c-link e-header__nav-user u-hide--md" [popover]="getAvatarActions.bind(this)">
            <app-avatar size="sm2" class="e-header__avatar" [name]="user.name">
            </app-avatar>
          </a>

          <div class="e-header__nav-item u-show--md">
            <a class="c-link" (click)="logout(); unsetExpanded()" rel="nofollow">
              {{ 'NAV.LOGOUT' | translate}}
            </a>
          </div>
        </ng-container>

        <ng-template #registerButton>
          <div class="e-header__nav-item u-hide--md">
            <a class="c-link" [routerLink]="'./login'">
              {{ 'NAV.LOGIN' | translate}}
            </a>
          </div>

          <div class="e-header__nav-item">
            <a class="c-btn e-header__nav-btn" [routerLink]="'./register'"
              (click)="unsetExpanded()">
              {{ 'NAV.SIGNUP' | translate}}
            </a>
          </div>

          <div class="e-header__nav-item u-show--md">
            <a class="c-btn c-btn--clear e-header__nav-btn u-margin-top--sm"
              [routerLink]="'./login'" (click)="unsetExpanded()">
              {{ 'NAV.LOGIN' | translate}}
            </a>
          </div>
        </ng-template>
      </div>
    </nav>

    <button aria-label="Open Menu" name="Open Menu" class="e-header__button"
      (click)="toggleExpanded($event)">
      <app-icon name="menu-outline"></app-icon>
    </button>
  </div>
</header>