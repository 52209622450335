export const environment = {
  production: true,
  environment: 'development',
  api: {
    endpoint: 'https://api.mdintegrations.dev',
    timeEndpoint: 'https://9dawfaq8n4.execute-api.us-east-2.amazonaws.com/production/time',
    maxWaiting: 30000,
    authHeader: 'Authorization',
    notificationRefreshInterval: 20000,
  },
  log: {
    enabled: true,
    payload: {},
    accessTokenPublic: '56d316daa9354d37862b6ec12630257b',
  },
  config: {
    privacyLink: 'https://mdintegrations.com/terms-of-service/#privacy',
    demoQuestionnaire:
      'https://patient.mdintegrations.dev?token=51c73571-c533-4e98-9391-c4035e478707',
    apiDocumentation: 'https://api.mdintegrations.com',
    patientAppURL: 'https://patient.mdintegrations.dev',
    cliniciansAppURL: 'https://app.mdintegrations.dev',
    appstoreURL: 'https://apps.apple.com/br/app/md-integrations/id1630699932',
    playstoreURL: 'https://play.google.com/store/apps/details?id=com.mdintegrations.app',
    shopifyPluginURL: 'https://apps.shopify.com/md-integrations-connect',
    financialTeamEmail: 'financial@mdintegrations.com',
    onboardingTeamEmail: 'onboarding@mdintegrations.com',
    statisticsTimeoutInHours: 4,
    test: {
      clinicianId: '17f9519e-895a-40fe-864f-02fe16e640fa',
      adminId: '56c588b2-a54f-41c3-9135-7b5d11db473a',
      supportId: 'f12a77ee-b410-4054-a95b-c2612ac66f72',
    },
  },
  recaptchaKey: '6Leo2WAmAAAAACpFDWpb_FVml__MW24NoCcyRzEd',
  availableLanguages: ['en'],
  analyticsKey: 'G-GL0JEJ726Z',
};
