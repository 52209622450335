import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-field-error-message',
  templateUrl: './field-error-message.component.html',
  styleUrls: ['./field-error-message.component.scss'],
})
export class FieldErrorMessageComponent implements AfterViewInit, OnDestroy {
  @Input() currentControl: FormControl;

  errorMessage = null;

  errorValue = null;

  private controlSubscription;

  ngAfterViewInit() {
    this.controlSubscription = this.currentControl.valueChanges.subscribe(() => {
      this.getErrorMessage();
    });
  }

  ngOnDestroy() {
    if (this.controlSubscription) {
      this.controlSubscription.unsubscribe();
    }
  }

  private getErrorMessage() {
    this.errorMessage = null;
    this.errorValue = null;

    if (this.currentControl.errors) {
      Object.keys(this.currentControl.errors).forEach((key) => {
        this.errorMessage = this.getValidatorErrorMessage(key);
        this.errorValue = this.currentControl.errors[key];
      });
    }
  }

  private getValidatorErrorMessage(validatorName: string) {
    switch (validatorName) {
      case 'email':
        return 'FORM_VALIDATION_MESSAGES.EMAIL';

      case 'minlength':
        return 'FORM_VALIDATION_MESSAGES.MIN_LENGTH';

      case 'maxlength':
        return 'FORM_VALIDATION_MESSAGES.MAX_LENGTH';

      case 'required':
        return 'FORM_VALIDATION_MESSAGES.REQUIRED';

      case 'weakPassword':
        return 'FORM_VALIDATION_MESSAGES.WEAK_PASSWORD';

      default:
        return 'FORM_VALIDATION_MESSAGES.INVALID';
    }
  }
}
