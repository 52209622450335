import { Injectable } from '@angular/core';
import { tap, startWith, throttleTime, pairwise, map, filter, switchMap } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { PartnerQuery } from './partner.query';
import { PartnerService } from './partner.service';

@Injectable({ providedIn: 'root' })
export class FlowService {
  constructor(private partnerQuery: PartnerQuery, private partnerService: PartnerService) {}

  getParamSubscription(target: string, form: FormGroup) {
    return this.partnerQuery.parameters$
      .pipe(
        tap((params) => {
          Object.keys(params)
            .map((key) => {
              return [key, ...key.split('.')];
            })
            .filter(([, scope]) => {
              return scope === target;
            })
            .forEach(([key, , item]) => {
              const field = form.get(item);

              if (!field) {
                return;
              }

              field.setValue(params[key].value === 'true');
            });
        })
      )
      .subscribe();
  }

  getFormSubscription(partnerId: string, target: string, form: FormGroup) {
    return form.valueChanges
      .pipe(
        startWith(form.value),
        throttleTime(200),
        pairwise(),
        map(([oldValues, newValues]) => {
          const key = Object.keys(newValues).find((k) => newValues[k] !== oldValues[k]);
          return { key, value: newValues[key] };
        }),
        filter(({ key }) => {
          return !!key;
        }),
        switchMap(({ key, value }) =>
          this.partnerService.setParameter(partnerId, `${target}.${key}`, value)
        )
      )
      .subscribe();
  }
}
