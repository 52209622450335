import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Device, Session, User } from './session.interface';
import { Partner } from '../../../modules/partner/state/partner.interface';

export interface SessionState {
  session: Session;
  device_fingerprint: string;
  user: User;
  partners: Partner[];
  devices: Device[];
}

export function createInitialState(): SessionState {
  return {
    session: {
      expires_in: undefined,
      access_token: undefined,
      token_type: undefined,
      refresh_token: undefined,
    },
    device_fingerprint: null,
    user: null,
    partners: [],
    devices: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'session', resettable: true })
export class SessionStore extends Store<SessionState> {
  constructor() {
    super(createInitialState());
  }
}
