import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { differenceInHours } from 'date-fns';
import { PartnerState, PartnerStore } from './partner.store';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class PartnerQuery extends Query<PartnerState> {
  partner$ = this.select((state) => state.partner);

  logo$ = this.select((state) => state.partner?.logo);

  contacts$ = this.select((state) => state.contacts);

  specialties$ = this.select((state) => state.specialties);

  credentials$ = this.select((state) => state.credentials);

  panelUsers$ = this.select((state) => state.panelUsers);

  supportStaffs$ = this.select((state) => state.supportStaffs);

  documentations$ = this.select((state) => state.documentations);

  categories$ = this.select((state) => state.categories);

  parameters$ = this.select((state) => state.parameters);

  questionnaires$ = this.select((state) => state.questionnaires);

  medications$ = this.select((state) => state.medications);

  compounds$ = this.select((state) => state.compounds);

  supplies$ = this.select((state) => state.supplies);

  services$ = this.select((state) => state.services);

  invoices$ = this.select((state) => state.invoices);

  clinicians$ = this.select((state) => state.clinicians);

  webhooks$ = this.select((state) => state.webhooks);

  tickets$ = this.select((state) => state.tickets);

  tags$ = this.select((state) => state.tags);

  files$ = this.select((state) => state.files);

  reviews$ = this.select((state) => state.reviews);

  domain$ = this.select((state) => state.domain);

  emailTemplates$ = this.select((state) => state.emailTemplates);

  emailTemplatesKeys$ = this.select((state) =>
    state.emailTemplates.reduce((acc, item) => {
      acc[item.key] = item;
      return acc;
    }, {})
  );

  statistics$ = this.select((state) => state.statistics);

  statisticsCache$ = this.select((state) => state.statistics?.cache);

  shopifyProducts$ = this.select((state) => state.shopify.products);

  shopifyAssociations$ = this.select((state) =>
    state.shopify.associations?.map((item) => {
      if (!item.shopify_variant_id) {
        return item;
      }

      const variant = item.shopify_product_payload.variants.find(
        (vr) => `${vr.id}` === item.shopify_variant_id
      );
      return {
        ...item,
        variant,
      };
    })
  );

  isPatientConsentFilled$ = this.select(
    (state) => !!state.documentations.patient_consent_plan?.description
  );

  isPatientIdentityPlanFilled$ = this.select(
    (state) => !!state.documentations.patient_identity_plan?.description
  );

  isInsuranceDetailsFilled$ = this.select(
    (state) => !!(state.documentations.insurance && state.documentations.hipaa_plan?.description)
  );

  areRollouAndServicesFilled$ = this.select(
    (state) =>
      state.documentations.staged_rollout?.description === 'true' &&
      !!state.documentations.rollout_locations?.description
  );

  constructor(protected store: PartnerStore) {
    super(store);
  }

  getPartnerId() {
    return this.getValue().id;
  }

  getModule() {
    return this.getValue().module;
  }

  getPartner() {
    return this.getValue().partner;
  }

  getPartnerLogo() {
    return this.getValue().partner?.logo;
  }

  getContacts() {
    return this.getValue().contacts;
  }

  getCredentials() {
    return this.getValue().credentials;
  }

  getTags() {
    return this.getValue().tags;
  }

  getFiles() {
    return this.getValue().files;
  }

  getSpecialties() {
    return this.getValue().specialties;
  }

  getPanelUsers() {
    return this.getValue().panelUsers;
  }

  getSupportStaffs() {
    return this.getValue().supportStaffs;
  }

  getDocumentations() {
    return this.getValue().documentations;
  }

  getCategories() {
    return this.getValue().categories;
  }

  getParameters() {
    return this.getValue().parameters;
  }

  getMedications() {
    return this.getValue().medications;
  }

  getCompounds() {
    return this.getValue().compounds;
  }

  getSupplies() {
    return this.getValue().supplies;
  }

  getDomain() {
    return this.getValue().domain;
  }

  getServices() {
    return this.getValue().services;
  }

  getQuestionnaires() {
    return this.getValue().questionnaires;
  }

  getInvoices() {
    return this.getValue().invoices;
  }

  getReviews() {
    return this.getValue().reviews;
  }

  getClinicians() {
    return this.getValue().clinicians;
  }

  getStatisticsCacheExpired() {
    const partnerId = this.getPartnerId();
    const { cache } = this.getStatistics();

    if (cache?.partner_id !== partnerId) {
      return true;
    }

    if (!cache?.date) {
      return true;
    }

    const now = new Date();
    const cacheDate = new Date(cache.date);
    const diff = differenceInHours(now, cacheDate);

    if (diff > environment.config.statisticsTimeoutInHours) {
      return true;
    }

    return false;
  }

  getStatistics() {
    return this.getValue().statistics;
  }

  getWebhooks() {
    return this.getValue().webhooks;
  }

  getShopifyProducts() {
    return this.getValue().shopify.products;
  }

  getShopifyAssociations() {
    return this.getValue().shopify.associations;
  }

  getEmailTemplates() {
    return this.getValue().emailTemplates;
  }

  getEmailTemplate(key: string) {
    return this.getValue().emailTemplates.find((item) => item.key === key);
  }

  getQuestionnaire(questionnaireId: string) {
    return this.getValue().questionnaires.find((item) => item.id === questionnaireId);
  }

  selectQuestionnaire(questionnaireId: string) {
    return this.select((state) => state.questionnaires.find((item) => item.id === questionnaireId));
  }

  getParameter(key: string) {
    const params = this.getValue().parameters;

    if (!params) {
      return null;
    }

    if (!Object.prototype.hasOwnProperty.call(params, key)) {
      return null;
    }

    return params[key];
  }
}
