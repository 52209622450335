import { Component } from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { forkJoin } from 'rxjs';
import { ModalService } from 'angular-modal-simple';
import { CookieService } from './core/cookie/states/cookie/cookie.service';
import { SessionService } from './core/state/session/session.service';
import { SessionQuery } from './core/state/session/session.query';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  loading = false;

  constructor(
    private cookieService: CookieService,
    private sessionService: SessionService,
    private sessionQuery: SessionQuery,
    private modalService: ModalService,
    private router: Router
  ) {
    this.initializeRouterLoad();
    this.initializeCookies();
    this.initializeUser();
    this.initializeModal();
  }

  onActivate() {
    window.scrollTo(0, 0);
  }

  private initializeCookies() {
    this.cookieService.init();
  }

  private initializeRouterLoad() {
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  private initializeUser() {
    if (!this.sessionQuery.isLoggedIn()) {
      return;
    }

    forkJoin([this.sessionService.getUser(), this.sessionService.getPartners()]).subscribe();
  }

  private initializeModal() {
    const service = <any>this.modalService;
    service.close = (component: any) => {
      const index = service.activeComponents.findIndex((i) => i.component === component);
      service.activeComponents[index]?.ref?.instance?.close();
    };
  }
}
