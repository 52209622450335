import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AngularModalModule } from 'angular-modal-simple';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { NgOtpInputModule } from 'ng-otp-input';
import { AppRoutingModule } from './app-routing.module';
import { ToastModule } from './core/toasts/toast.module';
import { SharedModule } from './shared/shared.module';
import { HomeModule } from './modules/home/home.module';
import { AppComponent } from './app.component';
import { RollbarService, rollbarFactory } from './core/services/rollbar/rollbar.service';
import { StorageService } from './core/services/storage/storage.service';
import { SessionService } from './core/state/session/session.service';

export function initializeApp(storageService: StorageService, sessionService: SessionService) {
  return (): Promise<any> => {
    // inits storage
    storageService.init();
    return sessionService.getServerOffset();
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    ToastModule,
    HomeModule,
    SharedModule,
    NgOtpInputModule,
    TranslateModule.forChild(),
    AngularModalModule.forRoot(),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [StorageService, SessionService],
    },
    StorageService,
    { provide: RollbarService, useFactory: rollbarFactory },
  ],
})
export class AppModule {}
