<ng-container *ngIf="popoverService.options">
  <div class="e-popover__backdrop" *ngIf="popoverService.options?.length > 0"></div>
  <div class="e-popover" [class.e-popover--bottom]="isBottom" #container [style.left]="x"
    [style.top]="y">
    <div class="e-popover__arrow"></div>
    <div class="e-popover__container" #list>
      <ng-container *ngFor="let button of popoverService.options">
        <ng-container *ngIf="button.separator; else item">
          <div class="e-popover__item e-popover__item--separator"
            [class.u-display--none]="button.visible === false"
            [class.u-show--lg]="button.desktop === false"
            [class.u-show--sm]="button.tablet === false"
            [class.u-hide--sm]="button.mobile === false">
          </div>
        </ng-container>

        <ng-template #item>
          <div class="e-popover__item" (click)="button.handler(); close();"
            [class.u-color--error]="button.role === 'destructive'"
            [class.u-display--none]="button.visible === false"
            [class.u-show--lg]="button.desktop === false"
            [class.u-show--sm]="button.tablet === false"
            [class.u-hide--sm]="button.mobile === false">
            <app-checkmark [checked]="button.checked" *ngIf="button.checked !== undefined">
            </app-checkmark>

            <div class="e-popover__checkmark" *ngIf="button.checkmark !== undefined">
              <app-icon *ngIf="button.checkmark" name="checkmark-outline"></app-icon>
            </div>

            <div class="e-popover__indicator" [style.--indicator]="button.indicator"
              *ngIf="button.indicator !== undefined">
            </div>

            <label>{{ button.text | translate }}</label>

            <span *ngIf="button.extraText">
              {{ button.extraText | translate }}
            </span>

            <div class="e-popover__icon" *ngIf="button.icon">
              <app-icon [name]="button.icon" [src]="button.icon"></app-icon>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </div>
</ng-container>