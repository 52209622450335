<div class="c-section c-section--title u-padding-top--lg">
  <div class="l-wrapper">
    <div class="l-container e-solutions__hero">
      <img alt="Solutions" loading="eager" src="./assets/images/solutions.jpg">
    </div>
  </div>
</div>

<div class="l-wrapper">
  <div class="l-container l-container--sm">
    <div class="c-section c-section--vertical e-solutions__checklist u-padding-top--none">
      <h2 class="c-section__title u-margin-bottom--md">
        {{ 'SOLUTIONS.SECTION_1.TITLE' | translate }}
      </h2>

      <p class="c-section__description u-text-align--center">
        {{ 'SOLUTIONS.SECTION_1.DESCRIPTION' | translate }}
      </p>

      <div class=" l-grid l-grid--3 c-checklist u-margin-top--lg">
        <div class="l-grid__item c-checklist__item">
          <app-checkmark />
          <h6>{{ "SOLUTIONS.SECTION_1.CARD_1" | translate }}</h6>
          <p>{{ "SOLUTIONS.SECTION_1.CARD_1_ALT" | translate }}</p>
        </div>
        <div class="l-grid__item c-checklist__item">
          <app-checkmark />
          <h6>{{ "SOLUTIONS.SECTION_1.CARD_2" | translate }}</h6>
          <p>{{ "SOLUTIONS.SECTION_1.CARD_2_ALT" | translate }}</p>
        </div>
        <div class="l-grid__item c-checklist__item">
          <app-checkmark />
          <h6>{{ "SOLUTIONS.SECTION_1.CARD_3" | translate }}</h6>
          <p>{{ "SOLUTIONS.SECTION_1.CARD_3_ALT" | translate }}</p>
        </div>
        <div class="l-grid__item c-checklist__item">
          <app-checkmark />
          <h6>{{ "SOLUTIONS.SECTION_1.CARD_4" | translate }}</h6>
          <p>{{ "SOLUTIONS.SECTION_1.CARD_4_ALT" | translate }}</p>
        </div>
        <div class="l-grid__item c-checklist__item">
          <app-checkmark />
          <h6>{{ "SOLUTIONS.SECTION_1.CARD_5" | translate }}</h6>
          <p>{{ "SOLUTIONS.SECTION_1.CARD_5_ALT" | translate }}</p>
        </div>
        <div class="l-grid__item c-checklist__item">
          <app-checkmark />
          <h6>{{ "SOLUTIONS.SECTION_1.CARD_6" | translate }}</h6>
          <p>{{ "SOLUTIONS.SECTION_1.CARD_6_ALT" | translate }}</p>
        </div>
      </div>

      <div class="u-display--flex u-margin-top--lg">
        <a [routerLink]="['/', 'contact']" class="c-btn c-btn--secondary">
          {{ 'SOLUTIONS.SECTION_1.CTA' | translate}}
        </a>
      </div>
    </div>
  </div>

  <div class="l-container">
    <!-- section 2 -->
    <div class="e-solutions__solutions c-card c-card--h c-card--primary">
      <div class="l-grid l-grid--2 e-solutions__image">
        <div class="l-grid__item">
          <div class="u-max-width--26rem">
            <h1 class="u-margin-bottom--md">
              {{ 'SOLUTIONS.SECTION_2.TITLE' | translate }}
            </h1>

            <p class="u-opacity--60 u-margin-bottom--lg">
              {{ 'SOLUTIONS.SECTION_2.DESCRIPTION' | translate }}
            </p>

            <div class="u-margin-bottom--md"></div>
          </div>
        </div>
        <div class="l-grid__item">
          <div class="e-clinicians__image e-clinicians__image--lg">
            <img loading="lazy" alt="MDI Portal" src="./assets/images/mdi-portal.png">
          </div>
        </div>
      </div>

      <h6 class="c-section__alt u-color--white u-margin-bottom--lg">
        {{ 'SOLUTIONS.SECTION_2.TITLE_ALT' | translate }}
      </h6>

      <div class="l-grid l-grid--3 u-margin-bottom--md">
        <div class="l-grid__item">
          <div class="u-width--100">
            <app-icon name="responsive-outline" [fill]="true" [stroke]="false"></app-icon>
            <h6>
              {{ 'SOLUTIONS.SECTION_2.ITEM_1' | translate }}
            </h6>
            <p>
              {{ 'SOLUTIONS.SECTION_2.ITEM_1_DESCRIPTION' | translate }}
            </p>
          </div>
        </div>
        <div class="l-grid__item">
          <div class="u-width--100">
            <app-icon name="sparkles-outline"></app-icon>
            <h6>
              {{ 'SOLUTIONS.SECTION_2.ITEM_2' | translate }}
            </h6>
            <p>
              {{ 'SOLUTIONS.SECTION_2.ITEM_2_DESCRIPTION' | translate }}
            </p>
          </div>
        </div>
        <div class="l-grid__item">
          <div class="u-width--100">
            <app-icon name="stopwatch-outline"></app-icon>
            <h6>
              {{ 'SOLUTIONS.SECTION_2.ITEM_3' | translate }}
            </h6>
            <p>
              {{ 'SOLUTIONS.SECTION_2.ITEM_3_DESCRIPTION' | translate }}
            </p>
          </div>
        </div>
        <div class="l-grid__item">
          <div class="u-width--100">
            <app-icon name="code-slash-outline"></app-icon>
            <h6>
              {{ 'SOLUTIONS.SECTION_2.ITEM_4' | translate }}
            </h6>
            <p>
              {{ 'SOLUTIONS.SECTION_2.ITEM_4_DESCRIPTION' | translate }}
            </p>
          </div>
        </div>
        <div class="l-grid__item">
          <div class="u-width--100">
            <app-icon name="chatbubble-ellipses-outline"></app-icon>
            <h6>
              {{ 'SOLUTIONS.SECTION_2.ITEM_5' | translate }}
            </h6>
            <p>
              {{ 'SOLUTIONS.SECTION_2.ITEM_5_DESCRIPTION' | translate }}
            </p>
          </div>
        </div>
        <div class="l-grid__item">
          <div class="u-width--100">
            <app-icon name="phone-portrait-outline"></app-icon>
            <h6>
              {{ 'SOLUTIONS.SECTION_2.ITEM_6' | translate }}
            </h6>
            <p>
              {{ 'SOLUTIONS.SECTION_2.ITEM_6_DESCRIPTION' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Section 3 -->
    <div class="c-section c-section--vertical e-solutions__business u-margin-top--lg">
      <h2 class="c-section__title u-max-width--36rem">
        {{ 'SOLUTIONS.SECTION_3.TITLE' | translate }}
      </h2>

      <ul class="c-checklist c-checklist--spread u-margin-top--lg u-margin-bottom--lg">
        <li class="c-checklist__item">
          <app-checkmark />
          {{ "SOLUTIONS.SECTION_3.ITEM_1" | translate }}
        </li>

        <li class="c-checklist__item">
          <app-checkmark />
          {{ "SOLUTIONS.SECTION_3.ITEM_2" | translate }}
        </li>

        <li class="c-checklist__item">
          <app-checkmark />
          {{ "SOLUTIONS.SECTION_3.ITEM_3" | translate }}
        </li>

        <li class="c-checklist__item">
          <app-checkmark />
          {{ "SOLUTIONS.SECTION_3.ITEM_4" | translate }}
        </li>

        <li class="c-checklist__item">
          <app-checkmark />
          {{ "SOLUTIONS.SECTION_3.ITEM_5" | translate }}
        </li>

        <li class="c-checklist__item">
          <app-checkmark />
          {{ "SOLUTIONS.SECTION_3.ITEM_6" | translate }}
        </li>

        <li class="c-checklist__item">
          <app-checkmark />
          {{ "SOLUTIONS.SECTION_3.ITEM_7" | translate }}
        </li>

        <li class="c-checklist__item">
          <app-checkmark />
          {{ "SOLUTIONS.SECTION_3.ITEM_8" | translate }}
        </li>
      </ul>
    </div>
  </div>

  <div class="e-solutions__hit">
    <div class="l-container">
      <!-- Section 4 -->
      <div class="c-section">
        <h6 class="c-section__alt u-color--black u-margin-bottom--md">
          {{ 'SOLUTIONS.SECTION_4.TITLE_ALT' | translate }}
        </h6>

        <div class="e-solutions__hit-header">
          <h2 class="u-width--100">
            {{ 'SOLUTIONS.SECTION_4.TITLE' | translate }}
          </h2>

          <a [routerLink]=" ['/', 'contact' ]" class="c-btn c-btn--secondary u-margin-left--auto">
            {{ 'SOLUTIONS.SECTION_4.CTA' | translate}}
          </a>
        </div>

        <div class="l-grid l-grid--3 u-margin-top--xl">
          <div class="l-grid__item u-max-width--18rem">
            <h6>
              {{ 'SOLUTIONS.SECTION_4.CARD_1' | translate }}
            </h6>
            <p>
              {{ 'SOLUTIONS.SECTION_4.CARD_1_ALT' | translate }}
            </p>
          </div>
          <div class="l-grid__item u-max-width--18rem">
            <h6>
              {{ 'SOLUTIONS.SECTION_4.CARD_2' | translate }}
            </h6>
            <p>
              {{ 'SOLUTIONS.SECTION_4.CARD_2_ALT' | translate }}
            </p>
          </div>
          <div class="l-grid__item u-max-width--18rem">
            <h6>
              {{ 'SOLUTIONS.SECTION_4.CARD_3' | translate }}
            </h6>
            <p>
              {{ 'SOLUTIONS.SECTION_4.CARD_3_ALT' | translate }}
            </p>
          </div>
        </div>
      </div>

      <!-- Section 5 -->
      <div class="c-section c-section--vertical">
        <h6 class="c-section__alt u-color--black u-margin-bottom--md">
          {{ 'SOLUTIONS.SECTION_5.TITLE_ALT' | translate }}
        </h6>

        <h2>
          {{ 'SOLUTIONS.SECTION_5.TITLE' | translate }}
        </h2>

        <div class="e-solutions__grid e-solutions__features">
          <div class="e-solutions__grid-item c-card c-card--terciary">
            <app-icon name="code-outline"></app-icon>
            <h5>
              {{ 'SOLUTIONS.SECTION_5.CARD_1' | translate }}
            </h5>
            <p>
              {{ 'SOLUTIONS.SECTION_5.CARD_1_ALT' | translate }}
            </p>
          </div>
          <div class="e-solutions__grid-item c-card c-card--terciary">
            <app-icon name="receipt-outline"></app-icon>
            <h5>
              {{ 'SOLUTIONS.SECTION_5.CARD_2' | translate }}
            </h5>
            <p>
              {{ 'SOLUTIONS.SECTION_5.CARD_2_ALT' | translate }}
            </p>
          </div>
          <div class="e-solutions__grid-item c-card c-card--terciary">
            <app-icon name="timer-outline"></app-icon>
            <h5>
              {{ 'SOLUTIONS.SECTION_5.CARD_3' | translate }}
            </h5>
            <p>
              {{ 'SOLUTIONS.SECTION_5.CARD_3_ALT' | translate }}
            </p>
          </div>
        </div>

        <div class="c-card c-card--terciary e-solutions__features u-width--100 u-margin-top--md">
          <div class="l-grid l-grid--4 u-margin-bottom--lg">
            <div class="l-grid__item">
              <app-icon name="cash-outline"></app-icon>
              <h5>
                {{ 'SOLUTIONS.SECTION_5.ITEM_1' | translate }}
              </h5>
            </div>
            <div class="l-grid__item">
              <app-icon name="earth-outline"></app-icon>
              <h5>
                {{ 'SOLUTIONS.SECTION_5.ITEM_2' | translate }}
              </h5>
            </div>
            <div class="l-grid__item">
              <app-icon name="support-outline"></app-icon>
              <h5>
                {{ 'SOLUTIONS.SECTION_5.ITEM_3' | translate }}
              </h5>
            </div>
            <div class="l-grid__item">
              <app-icon name="code-slash-outline"></app-icon>
              <h5>
                {{ 'SOLUTIONS.SECTION_5.ITEM_4' | translate }}
              </h5>
            </div>
          </div>

          <div class="l-grid l-grid--4 u-margin-bottom--lg">
            <div class="l-grid__item">
              <app-icon name="desktop-outline"></app-icon>
              <h5>
                {{ 'SOLUTIONS.SECTION_5.ITEM_5' | translate }}
              </h5>
            </div>
            <div class="l-grid__item">
              <app-icon name="chatbubbles-outline"></app-icon>
              <h5>
                {{ 'SOLUTIONS.SECTION_5.ITEM_6' | translate }}
              </h5>
            </div>
            <div class="l-grid__item">
              <app-icon name="shield-checkmark-outline"></app-icon>
              <h5>
                {{ 'SOLUTIONS.SECTION_5.ITEM_7' | translate }}
              </h5>
            </div>
            <div class="l-grid__item">
              <app-icon name="pricetag-outline"></app-icon>
              <h5>
                {{ 'SOLUTIONS.SECTION_5.ITEM_8' | translate }}
              </h5>
            </div>
          </div>

          <div class="l-grid l-grid--4">
            <div class="l-grid__item">
              <app-icon name="responsive-outline" [fill]="true" [stroke]="false"></app-icon>
              <h5>
                {{ 'SOLUTIONS.SECTION_5.ITEM_9' | translate }}
              </h5>
            </div>
            <div class="l-grid__item">
              <app-icon name="specialty-outline" [fill]="true" [stroke]="false"></app-icon>
              <h5>
                {{ 'SOLUTIONS.SECTION_5.ITEM_10' | translate }}
              </h5>
            </div>
            <div class="l-grid__item">
              <app-icon name="receipt-outline"></app-icon>
              <h5>
                {{ 'SOLUTIONS.SECTION_5.ITEM_11' | translate }}
              </h5>
            </div>
            <div class="l-grid__item">
              <app-icon name="sparkles-outline"></app-icon>
              <h5>
                {{ 'SOLUTIONS.SECTION_5.ITEM_12' | translate }}
              </h5>
            </div>
          </div>
        </div>
      </div>

      <!-- Section 6 -->
      <div class="c-section c-section--vertical u-margin-top--md">
        <h1 class="c-section__title u-margin-bottom--md">
          {{ 'SOLUTIONS.SECTION_6.TITLE' | translate }}
        </h1>

        <p class="c-section__description">
          {{ 'SOLUTIONS.SECTION_6.DESCRIPTION' | translate }}
        </p>

        <div class="u-display--flex u-margin-top--lg">
          <a [routerLink]="['/', 'contact']" class="c-btn c-btn--secondary">
            {{ 'SOLUTIONS.SECTION_6.CTA' | translate}}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>