<div class="c-field" [class.c-field--disabled]="isDisabled" [class.c-field--changed]="changed"
  [class.c-field--search]="type === 'search'">

  <ng-container *ngIf="icon">
    <app-icon class="c-field__icon c-field__icon--left" [name]="icon"></app-icon>
  </ng-container>

  <input class="c-field__input" [type]="type" [placeholder]="(placeholder | translate)"
    [formControl]="ngControl.control" />

  <h6 class="c-field__label">
    {{ label | translate }}

    <span *ngIf="isOptional && showOptional">
      ({{ 'LABEL.OPTIONAL' | translate }})
    </span>
  </h6>

  <div class="c-field__loading c-loading c-loading--xs" *ngIf="loading">
    <app-loading size="sm"></app-loading>
  </div>

  <app-field-error-message class="c-field__notes" [currentControl]="ngControl.control">
  </app-field-error-message>
</div>