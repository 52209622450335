import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { HomePage } from './pages/home/home.page';
import { HomeRoutingModule } from './home-routing.module';
import { AboutPage } from './pages/about/about.page';
import { SolutionsPage } from './pages/solutions/solutions.page';

@NgModule({
  declarations: [HomePage, AboutPage, SolutionsPage],
  imports: [CommonModule, SharedModule, HomeRoutingModule],
  providers: [],
})
export class HomeModule {}
