<div class="c-field c-field--select" [class.c-field--disabled]="isDisabled"
  [class.c-field--changed]="changed">
  <select class="c-field__input c-field__input--select" [formControl]="ngControl.control"
    [disabled]="isDisabled">
    <option *ngFor="let option of options;"
      [value]="option.value !== undefined ? option.value : option">
      {{ (option.label !== undefined ? option.label : option) | translate }}
    </option>
  </select>

  <h6 class="c-field__label">
    {{ label | translate }}

    <span *ngIf="isOptional && showOptional">
      ({{ 'LABEL.OPTIONAL' | translate }})
    </span>
  </h6>

  <app-field-error-message class="c-field__notes" [currentControl]="ngControl.control">
  </app-field-error-message>

</div>