/* eslint-disable no-param-reassign */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { EventCategory } from 'src/app/core/services/analytics/analytics.interface';
import { AnalyticsService } from 'src/app/core/services/analytics/analytics.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePage implements AfterViewInit {
  @ViewChild('heroVideo') heroVideo: ElementRef<HTMLVideoElement>;

  @ViewChild('mapVideo') mapVideo: ElementRef<HTMLVideoElement>;

  privacyLink: string;

  docsLink: string;

  shopifyLink: string;

  demoLink: string;

  showVideo = false;

  toggle = 'patients';

  constructor(private analyticsService: AnalyticsService) {
    this.analyticsService.pageView(EventCategory.Home);
    this.privacyLink = environment.config.privacyLink;
    this.demoLink = environment.config.demoQuestionnaire;
    this.docsLink = environment.config.apiDocumentation;
    this.shopifyLink = environment.config.shopifyPluginURL;
  }

  ngAfterViewInit() {
    // auto plays video after a few seconds
    setTimeout(() => {
      this.heroVideo.nativeElement.muted = true;
      this.heroVideo.nativeElement.play();

      this.mapVideo.nativeElement.muted = true;
      this.mapVideo.nativeElement.play();
    }, 1000);
  }

  setToggle(toggle) {
    this.toggle = toggle;
  }
}
