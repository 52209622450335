<app-modal (close)="dismiss()">
  <h3 class="c-modal__title">
    {{ (isSelf ? 'MODALS.MANAGE_ADMIN_USERS.SELF_TITLE' : 'MODALS.MANAGE_ADMIN_USERS.TITLE') | translate }}
  </h3>

  <form [formGroup]="form">
    <app-field-input class="u-margin-bottom--md" label="MODALS.MANAGE_ADMIN_USERS.FORM.NAME"
      formControlName="name"></app-field-input>

    <app-field-input class="u-margin-bottom--sm" label="MODALS.MANAGE_ADMIN_USERS.FORM.EMAIL"
      formControlName="email"></app-field-input>

    <ng-container *ngIf="!user">
      <p class="u-font-size--sm u-color--gray-500 u-margin-bottom--none">
        {{ 'MODALS.MANAGE_ADMIN_USERS.FORM.PASSWORD_ALT' | translate }}
      </p>
    </ng-container>
  </form>

  <div class="c-modal__footer">
    <app-button (click)="dismiss()" [disabled]="submitting" class="u-hide--xs" variant="secondary">
      {{ 'BUTTONS.CANCEL' | translate }}
    </app-button>

    <app-button class="c-modal__btn" (click)="submit()" [disabled]="submitting || form.invalid"
      [loading]="submitting">
      {{ 'BUTTONS.SAVE' | translate }}
    </app-button>
  </div>
</app-modal>