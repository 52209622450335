/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, of } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import * as h from 'md5';
import { Router } from '@angular/router';
import { differenceInSeconds, parseISO } from 'date-fns';
import { SessionStore } from './session.store';
import { Device, Session, User } from './session.interface';
import { LoggerService } from '../../services/logger/logger.service';
import { environment } from '../../../../environments/environment';
import { StorageService } from '../../services/storage/storage.service';
import { Partner } from '../../../modules/partner/state/partner.interface';
import { ToastService } from '../../toasts/services/toast-service/toast.service';
import { Pagination } from '../metadata/metadata.interface';
import { SessionQuery } from './session.query';

@Injectable({ providedIn: 'root' })
export class SessionService {
  // temporary holds email/pw so it's not saved on the store
  loginEmail = null;

  loginPassword = null;

  timeOffsetSecs = null;

  slackLoading = false;

  constructor(
    private http: HttpClient,
    private loggerService: LoggerService,
    private sessionStore: SessionStore,
    private sessionQuery: SessionQuery,
    private storage: StorageService,
    private router: Router,
    private toastService: ToastService
  ) {}

  post2FA(email: string, password: string, deviceId?: string) {
    const body = { email, password };

    if (deviceId) {
      (<any>body).device_fingerprint = deviceId;
    }

    return this.http.post<Session>(`${environment.api.endpoint}/web/user/passcodes`, body).pipe(
      switchMap((session?: Session) => {
        if (session.access_token) {
          const { device_fingerprint } = session;

          this.sessionStore.update({
            session,
            device_fingerprint,
          });

          return forkJoin([this.getUser(), this.getPartners()]).pipe(map(() => session));
        }

        this.loginEmail = email;
        this.loginPassword = password;

        return of(session);
      }),
      catchError((err) => {
        this.loggerService.error(err);
        throw err;
      })
    );
  }

  postLogin(email: string, password: string, code: string, is_remember_device?: boolean) {
    const body = { email, password, code: code?.toUpperCase(), is_remember_device };
    const url = `${environment.api.endpoint}/web/user/login`;

    return this.http.post<Session>(url, body).pipe(
      tap((session: Session) => {
        this.loginEmail = null;
        this.loginPassword = null;

        const { device_fingerprint } = session;

        this.sessionStore.update({
          session,
          device_fingerprint,
        });
      }),
      switchMap(() => forkJoin([this.getUser(), this.getPartners()])),
      catchError((err) => {
        this.loggerService.error(err);
        throw err;
      })
    );
  }

  postLogout() {
    this.doLogout();

    return this.http.post<{}>(`${environment.api.endpoint}/web/user/logout`, {}).pipe(
      catchError((err) => {
        this.loggerService.error(err);
        return of(null);
      })
    );
  }

  postLogoutAll() {
    this.doLogout();

    return this.http.post<{}>(`${environment.api.endpoint}/web/user/logout/all`, {}).pipe(
      catchError((err) => {
        this.loggerService.error(err);
        return of(null);
      })
    );
  }

  setDeviceFingerprint(id: string) {
    this.sessionStore.update({ device_fingerprint: id });
  }

  getUser() {
    return this.http.get<User>(`${environment.api.endpoint}/web/user`).pipe(
      tap((user) => {
        this.sessionStore.update({ user });
      }),
      catchError((err) => {
        this.loggerService.error(err);
        throw err;
      })
    );
  }

  patchUser(payload: Partial<User>) {
    return this.http.patch<User>(`${environment.api.endpoint}/web/user`, payload).pipe(
      tap((user) => {
        this.sessionStore.update({ user });
      }),
      catchError((err) => {
        this.toastService.error(err.message);
        this.loggerService.error(err);
        throw err;
      })
    );
  }

  getDevices() {
    return this.http.get<Device[]>(`${environment.api.endpoint}/web/user/devices`).pipe(
      tap((devices) => {
        this.sessionStore.update({ devices });
      }),
      catchError((err) => {
        this.loggerService.error(err);
        throw err;
      })
    );
  }

  deleteDevice(deviceId: string) {
    return this.http.delete(`${environment.api.endpoint}/web/user/devices/${deviceId}`).pipe(
      tap(() => {
        this.sessionStore.update((state) => {
          return {
            ...state,
            devices: state.devices.filter((item) => item.id !== deviceId),
          };
        });
      }),
      catchError((err) => {
        this.loggerService.error(err);
        throw err;
      })
    );
  }

  getPartners(page = 1, query = null, sort = 'name', order = 'asc', showOnlyLive = false) {
    const params = { per_page: 25, page, sort, order };

    if (page === 1 && !query) {
      this.sessionStore.update({ partners: [] });
    }

    if (showOnlyLive) {
      (<any>params).status = 'live';
    }

    if (query) {
      (<any>params).search = query;
    }

    return this.http
      .get<Pagination<Partner>>(`${environment.api.endpoint}/web/user/partners`, { params })
      .pipe(
        tap(({ data: response }) => {
          // only update store if not using query param
          if (query?.length > 0) {
            return;
          }

          this.sessionStore.update((state) => {
            const arr = [...state.partners, ...response];
            const partners = arr.filter(
              (value, index, self) => index === self.findIndex((t) => t.id === value.id)
            );

            return {
              partners,
            };
          });
        }),
        catchError((err) => {
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  setupSlack(partnerId: string) {
    if (this.slackLoading) {
      return of({});
    }

    this.slackLoading = true;

    const loadingToast = this.toastService.info('Loading Slack channel...', { timeout: 0 });

    return this.postSetupSlack(partnerId).pipe(
      tap((response) => {
        if (response.slack_channel_id) {
          window.open(
            `https://mdintegrations.slack.com/app_redirect?channel=${response.slack_channel_id}`,
            '_blank'
          );
        } else {
          this.toastService.warning("We couldn't redirect you to a Slack channel at this time");
        }
      }),
      finalize(() => {
        this.slackLoading = false;
        this.toastService.destroy(loadingToast);
      })
    );
  }

  postSetupSlack(partnerId: string) {
    return this.http
      .post<{ success: boolean; slack_channel_id: string }>(
        `${environment.api.endpoint}/web/partners/${partnerId}/slack/channel`,
        {}
      )
      .pipe(
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postForgotPassword(email: string) {
    const body = { email };
    return this.http.post(`${environment.api.endpoint}/web/user/password-resets`, body).pipe(
      catchError((err) => {
        this.toastService.error(err.message);
        this.loggerService.error(err);
        throw err;
      })
    );
  }

  postResetPassword(token: string, email: string, password: string, passwordConfirmation: string) {
    const body = {
      email,
      password,
      password_confirmation: passwordConfirmation,
    };

    return this.http
      .post(`${environment.api.endpoint}/web/user/password-resets/${token}/reset`, body)
      .pipe(
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getSigKey() {
    const key = this.sessionQuery.getSigKey(this.timeOffsetSecs);
    const [noms] = key.split('.');
    const noT = noms.replace('T', ' ');
    const rounded = noT.substring(0, noT.length - 1);
    const leadZero = `${rounded}0`;
    return h(leadZero);
  }

  async getServerOffset() {
    this.timeOffsetSecs = 0;

    try {
      const response = await fetch(environment.api.timeEndpoint);

      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }

      const date = await response.json();

      if (!date) {
        throw new Error(`Not a date`);
      }

      const today = new Date();
      const [dateFormat] = date.split('.');
      const parsedDate = parseISO(`${dateFormat}Z`);

      this.timeOffsetSecs = differenceInSeconds(today, parsedDate);
    } catch (error) {
      // do nothing;
    }

    return true;
  }

  doLogout() {
    const deviceId = this.sessionQuery.getDeviceFingerprint();

    this.sessionStore.reset();

    this.router.navigateByUrl('/', { replaceUrl: true });

    setTimeout(() => {
      this.storage.clearAll();

      // restores device fingerprint
      this.sessionStore.update({ device_fingerprint: deviceId });
    }, 250);
  }
}
