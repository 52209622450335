/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { tap, finalize, catchError, switchMap, map } from 'rxjs/operators';
import { ToastService } from '../../../core/toasts/services/toast-service/toast.service';
import { environment } from '../../../../environments/environment';
import { PartnerStore, createInitialState as PartnerStoreInitialState } from './partner.store';
import { LoggerService } from '../../../core/services/logger/logger.service';
import {
  AuditLog,
  Clinician,
  Compound,
  Contact,
  Credential,
  Documentation,
  EmailDomain,
  EmailStyle,
  Encounter,
  Invoice,
  Medication,
  Offering,
  Option,
  PanelUser,
  Parameter,
  Partner,
  PartnerFile,
  Patient,
  Question,
  Questionnaire,
  Review,
  Rule,
  Service,
  ShopifyAssociation,
  ShopifyPagination,
  Supply,
  SupportStaff,
  Ticket,
  Voucher,
  VoucherPayload,
  Webhook,
  WebhookLog,
} from './partner.interface';
import {
  Category,
  Disease,
  EmailTemplate,
  Pagination,
  Specialty,
  State,
  Tag,
} from '../../../core/state/metadata/metadata.interface';
import { PartnerQuery } from './partner.query';
import { File } from '../../../core/services/files/files.interface';
import { TranslationsService } from '../../../core/services/translations/translations.service';

@Injectable({ providedIn: 'root' })
export class PartnerService {
  constructor(
    private store: PartnerStore,
    private http: HttpClient,
    private loggerService: LoggerService,
    private toastService: ToastService,
    private partnerQuery: PartnerQuery
  ) {}

  getPartner(partnerId: string) {
    this.store.setLoading(true);

    // updates hardcoded properties
    this.store.update((state) => {
      return { ...state, id: partnerId, module: 'partners' };
    });

    return this.http
      .get<Partner>(
        `${environment.api.endpoint}/web/partners/${partnerId}?with_relationships[]=logo&with_relationships[]=contractFile&with_relationships[]=insuranceFile`
      )
      .pipe(
        tap((partner) => {
          this.store.setError(null);
          this.store.update((state) => {
            return { ...state, partner };
          });
        }),
        finalize(() => {
          this.store.setLoading(false);
        }),
        catchError((err) => {
          this.store.setError(err.message);
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  resetPartner() {
    this.store.reset();
  }

  patchPartner(partnerId: string, payload: Partial<Partner>) {
    return this.http
      .patch<Partner>(`${environment.api.endpoint}/web/partners/${partnerId}`, payload)
      .pipe(
        switchMap(() => {
          return this.getPartner(partnerId);
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postPartnerReview(partnerId: string, file) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', file.name);

    return this.http
      .post<File>(`${environment.api.endpoint}/web/partners/${partnerId}/review`, formData)
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            return {
              ...state,
              partner: {
                ...state.partner,
                ...response,
              },
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getFiles(partnerId: string) {
    return this.http
      .get<Pagination<PartnerFile>>(
        `${environment.api.endpoint}/web/partners/${partnerId}/files?page=1&per_page=100`
      )
      .pipe(
        tap(({ data: files }) => {
          this.store.update((state) => {
            return { ...state, files };
          });
        }),
        catchError((err) => {
          this.store.setError(err.message);
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  putFile(partnerId: string, fileId: string, payload) {
    return this.http
      .put<PartnerFile>(
        `${environment.api.endpoint}/web/partners/${partnerId}/files/${fileId}`,
        payload
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            return {
              ...state,
              files: [...state.files, response],
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  deleteFile(partnerId: string, fileId: string) {
    return this.http
      .delete(`${environment.api.endpoint}/web/partners/${partnerId}/files/${fileId}`)
      .pipe(
        tap(() => {
          this.store.update((state) => {
            return {
              ...state,
              files: state.files.filter((item) => item.id !== fileId),
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getSpecialties(partnerId: string) {
    return this.http
      .get<Pagination<Specialty>>(
        `${environment.api.endpoint}/web/partners/${partnerId}/specialties?page=1&per_page=100`
      )
      .pipe(
        tap(({ data: specialties }) => {
          this.store.update((state) => {
            return { ...state, specialties };
          });
        }),
        catchError((err) => {
          this.store.setError(err.message);
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  putAttachSpecialty(partnerId: string, specialtyId: string, payload) {
    return this.http
      .put<Specialty>(
        `${environment.api.endpoint}/web/partners/${partnerId}/specialties/${specialtyId}`,
        payload
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            return {
              ...state,
              specialties: [...state.specialties, response],
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  patchAttachSpecialty(partnerId: string, specialtyId: string, payload) {
    return this.http
      .patch<Specialty>(
        `${environment.api.endpoint}/web/partners/${partnerId}/specialties/${specialtyId}`,
        payload
      )
      .pipe(
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  deleteSpecialty(partnerId: string, specialtyId: string) {
    return this.http
      .delete(`${environment.api.endpoint}/web/partners/${partnerId}/specialties/${specialtyId}`)
      .pipe(
        tap(() => {
          this.store.update((state) => {
            return {
              ...state,
              specialties: state.specialties.filter((item) => item.id !== specialtyId),
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getContacts(partnerId: string) {
    return this.http
      .get<Pagination<Contact>>(
        `${environment.api.endpoint}/web/partners/${partnerId}/contacts?page=1&per_page=100`
      )
      .pipe(
        tap(({ data: contacts }) => {
          this.store.update((state) => {
            return { ...state, contacts };
          });
        }),
        catchError((err) => {
          this.store.setError(err.message);
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postContact(partnerId: string, payload: Partial<Contact>) {
    return this.http
      .post<Contact>(`${environment.api.endpoint}/web/partners/${partnerId}/contacts`, payload)
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            return {
              ...state,
              contacts: [...state.contacts, response],
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  patchContact(partnerId: string, contactId: string, payload: Partial<Contact>) {
    return this.http
      .patch<Contact>(
        `${environment.api.endpoint}/web/partners/${partnerId}/contacts/${contactId}`,
        payload
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const idx = state.contacts.findIndex((item) => item.id === response.id);
            const arr = [...state.contacts];
            arr.splice(idx, 1, response);

            return {
              ...state,
              contacts: arr,
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  deleteContact(partnerId: string, contactId: string) {
    return this.http
      .delete(`${environment.api.endpoint}/web/partners/${partnerId}/contacts/${contactId}`)
      .pipe(
        tap(() => {
          this.store.update((state) => {
            return {
              ...state,
              contacts: state.contacts.filter((item) => item.id !== contactId),
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getInvoices(partnerId: string, page = 1, per_page = 6) {
    const params = {
      page,
      per_page,
    };

    if (page === 1) {
      this.store.update((state) => {
        return { ...state, invoices: [] };
      });
    }

    return this.http
      .get<Pagination<Invoice>>(`${environment.api.endpoint}/web/partners/${partnerId}/invoices`, {
        params,
      })
      .pipe(
        tap(({ data: invoices }) => {
          this.store.update((state) => {
            return { ...state, invoices: [...state.invoices, ...invoices] };
          });
        }),
        catchError((err) => {
          this.store.setError(err.message);
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getCredentials(partnerId: string) {
    return this.http
      .get<Pagination<Credential>>(
        `${environment.api.endpoint}/web/partners/${partnerId}/credentials`
      )
      .pipe(
        tap(({ data: credentials }) => {
          this.store.update((state) => {
            return { ...state, credentials };
          });
        }),
        catchError((err) => {
          this.store.setError(err.message);
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postCredential(partnerId: string, payload: Partial<Credential>) {
    return this.http
      .post<Credential>(
        `${environment.api.endpoint}/web/partners/${partnerId}/credentials`,
        payload
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            return {
              ...state,
              credentials: [...state.credentials, response],
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  deleteCredential(partnerId: string, credentialId: string) {
    return this.http
      .delete(`${environment.api.endpoint}/web/partners/${partnerId}/credentials/${credentialId}`)
      .pipe(
        tap(() => {
          this.store.update((state) => {
            return {
              ...state,
              credentials: state.credentials.filter((item) => item.id !== credentialId),
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getTags(partnerId: string, page = 1, per_page = 25) {
    const params = { per_page, page };

    if (page === 1) {
      this.store.update((state) => {
        return { ...state, tags: [] };
      });
    }

    return this.http
      .get<Pagination<Tag>>(`${environment.api.endpoint}/web/partners/${partnerId}/tags`, {
        params,
      })
      .pipe(
        tap(({ data: tags }) => {
          this.store.update((state) => {
            return { ...state, tags: [...state.tags, ...tags] };
          });
        }),
        catchError((err) => {
          this.store.setError(err.message);
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  patchTag(partnerId: string, tagId: string, payload: Partial<Tag>) {
    return this.http
      .patch<Tag>(`${environment.api.endpoint}/web/partners/${partnerId}/tags/${tagId}`, payload)
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const idx = state.tags.findIndex((item) => item.id === response.id);
            const arr = [...state.tags];
            arr.splice(idx, 1, response);

            return {
              ...state,
              tags: arr,
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postTag(partnerId: string, payload: Partial<Tag>) {
    return this.http
      .post<Tag>(`${environment.api.endpoint}/web/partners/${partnerId}/tags`, payload)
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            return {
              ...state,
              tags: [...state.tags, response],
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  deleteTag(partnerId: string, tagId: string) {
    return this.http
      .delete(`${environment.api.endpoint}/web/partners/${partnerId}/tags/${tagId}`)
      .pipe(
        tap(() => {
          this.store.update((state) => {
            return {
              ...state,
              tags: state.tags.filter((item) => item.id !== tagId),
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getPanelUsers(partnerId: string, page = 1, per_page = 100) {
    const params = { per_page, page };

    if (page === 1) {
      this.store.update((state) => {
        return { ...state, panelUsers: [] };
      });
    }

    return this.http
      .get<Pagination<PanelUser>>(
        `${environment.api.endpoint}/web/partners/${partnerId}/panel-users`,
        {
          params,
        }
      )
      .pipe(
        tap(({ data: panelUsers }) => {
          this.store.update((state) => {
            return { ...state, panelUsers: [...state.panelUsers, ...panelUsers] };
          });
        }),
        catchError((err) => {
          this.store.setError(err.message);
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postPanelUser(partnerId: string, payload: Partial<PanelUser>) {
    const body = { ...payload };

    return this.http
      .post<PanelUser>(`${environment.api.endpoint}/web/partners/${partnerId}/panel-users`, body)
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            return {
              ...state,
              panelUsers: [...state.panelUsers, response],
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  patchPanelUser(userId: string, payload: Partial<PanelUser>) {
    return this.http
      .patch<PanelUser>(`${environment.api.endpoint}/web/panel-users/${userId}`, payload)
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const idx = state.panelUsers.findIndex((item) => item.id === response.id);
            const arr = [...state.panelUsers];
            arr.splice(idx, 1, response);

            return {
              ...state,
              panelUsers: arr,
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  putAttachPanelUser(partnerId: string, email: string) {
    return this.http
      .put<PanelUser>(`${environment.api.endpoint}/web/partners/${partnerId}/panel-users`, {
        email,
      })
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            return {
              ...state,
              panelUsers: [...state.panelUsers, response],
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  deattachPanelUser(partnerId: string, userId: string) {
    return this.http
      .delete(`${environment.api.endpoint}/web/partners/${partnerId}/panel-users/${userId}`)
      .pipe(
        tap(() => {
          this.store.update((state) => {
            return {
              ...state,
              panelUsers: state.panelUsers.filter((item) => item.id !== userId),
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getClinicians(
    partnerId: string,
    page = 1,
    length = 10,
    id?: string,
    email?: string,
    last_name?: string
  ) {
    const query = {
      page,
      per_page: length,
    };

    if (id) {
      (<any>query).id = id;
    }

    if (email) {
      (<any>query).email = email;
    }

    if (last_name) {
      (<any>query).last_name = last_name;
    }

    return this.http
      .get<Pagination<Clinician>>(
        `${environment.api.endpoint}/web/${this.getSharedModule()}/${partnerId}/clinicians`,
        {
          params: query,
        }
      )
      .pipe(
        tap(({ data: clinicians }) => {
          if (!id && !email && !last_name) {
            this.store.update((state) => {
              return { ...state, clinicians };
            });
          }
        }),
        catchError((err) => {
          this.store.setError(err.message);
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postExportClinicians(partnerId: string) {
    return this.http
      .post<{ file: string }>(
        `${environment.api.endpoint}/web/partners/${partnerId}/clinicians/export`,
        {}
      )
      .pipe(
        catchError((err) => {
          this.store.setError(err.message);
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getSupportStaffs(partnerId: string, page = 1, per_page = 100) {
    const params = { per_page, page };

    if (page === 1) {
      this.store.update((state) => {
        return { ...state, supportStaffs: [] };
      });
    }

    return this.http
      .get<Pagination<SupportStaff>>(
        `${environment.api.endpoint}/web/partners/${partnerId}/support-staffs`,
        { params }
      )
      .pipe(
        tap(({ data: supportStaffs }) => {
          this.store.update((state) => {
            return { ...state, supportStaffs: [...state.supportStaffs, ...supportStaffs] };
          });
        }),
        catchError((err) => {
          this.store.setError(err.message);
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postSupportStaff(partnerId: string, payload: Partial<SupportStaff>) {
    const body = {
      ...payload,
      active: true,
      managed_by_partner: true,
    };
    return this.http
      .post<SupportStaff>(
        `${environment.api.endpoint}/web/partners/${partnerId}/support-staffs`,
        body
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            return {
              ...state,
              supportStaffs: [...state.supportStaffs, response],
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  patchSupportStaff(staffId: string, payload: Partial<SupportStaff>) {
    return this.http
      .patch<SupportStaff>(`${environment.api.endpoint}/web/support-staffs/${staffId}`, payload)
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const idx = state.supportStaffs.findIndex((item) => item.id === response.id);
            const arr = [...state.supportStaffs];
            arr.splice(idx, 1, response);

            return {
              ...state,
              supportStaffs: arr,
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  putAttachSupportStaffByEmail(partnerId: string, email: string) {
    return this.http
      .put<SupportStaff>(`${environment.api.endpoint}/web/partners/${partnerId}/support-staffs`, {
        email,
      })
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            return {
              ...state,
              supportStaffs: [...state.supportStaffs, response],
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  putAttachSupportStaff(partnerId: string, supportStaffId: string) {
    return this.http
      .put<SupportStaff>(
        `${environment.api.endpoint}/web/partners/${partnerId}/support-staffs/${supportStaffId}`,
        {}
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            return {
              ...state,
              supportStaffs: [...state.supportStaffs, response],
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  patchPartnerSupportStaff(partnerId: string, staffId: string, payload: Partial<SupportStaff>) {
    return this.http
      .patch<SupportStaff>(
        `${environment.api.endpoint}/web/partners/${partnerId}/support-staffs/${staffId}`,
        payload
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const idx = state.supportStaffs.findIndex((item) => item.id === response.id);
            const arr = [...state.supportStaffs];
            arr.splice(idx, 1, response);

            return {
              ...state,
              supportStaffs: arr,
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  detachSupportStaff(partnerId: string, staffId: string) {
    return this.http
      .delete(`${environment.api.endpoint}/web/partners/${partnerId}/support-staffs/${staffId}`)
      .pipe(
        tap(() => {
          this.store.update((state) => {
            return {
              ...state,
              supportStaffs: state.supportStaffs.filter((item) => item.id !== staffId),
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getDocumentations(partnerId: string) {
    return this.http
      .get<Pagination<Documentation>>(
        `${environment.api.endpoint}/web/partners/${partnerId}/documentations?page=1&per_page=100`
      )
      .pipe(
        tap(({ data: documentations }) => {
          const hash = documentations.reduce((acc, item) => {
            acc[item.key] = item;
            return acc;
          }, {});

          this.store.update((state) => {
            return { ...state, documentations: hash };
          });
        }),
        catchError((err) => {
          this.store.setError(err.message);
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postDocumentation(partnerId: string, payload: Partial<Documentation>) {
    return this.http
      .post<Documentation>(
        `${environment.api.endpoint}/web/partners/${partnerId}/documentations`,
        payload
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            return {
              ...state,
              documentations: {
                ...state.documentations,
                [response.key]: response,
              },
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  patchDocumentation(partnerId: string, documentationId: string, payload: Partial<Documentation>) {
    const body = { ...payload };
    delete body.key;

    return this.http
      .patch<Documentation>(
        `${environment.api.endpoint}/web/partners/${partnerId}/documentations/${documentationId}`,
        body
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            return {
              ...state,
              documentations: {
                ...state.documentations,
                [response.key]: response,
              },
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getCategories(partnerId: string) {
    return this.http
      .get<Pagination<Category>>(
        `${environment.api.endpoint}/web/partners/${partnerId}/categories?page=1&per_page=100`
      )
      .pipe(
        tap(({ data: categories }) => {
          this.store.update((state) => {
            return { ...state, categories };
          });
        }),
        catchError((err) => {
          this.store.setError(err.message);
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  attachCategory(partnerId: string, categoryId: string) {
    return this.http
      .put<Category>(
        `${environment.api.endpoint}/web/partners/${partnerId}/categories/${categoryId}`,
        {}
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            return {
              ...state,
              categories: [...state.categories, response],
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  deattachCategory(partnerId: string, categoryId: string) {
    return this.http
      .delete<Category>(
        `${environment.api.endpoint}/web/partners/${partnerId}/categories/${categoryId}`
      )
      .pipe(
        tap(() => {
          this.store.update((state) => {
            return {
              ...state,
              categories: state.categories.filter((item) => item.id !== categoryId),
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getParameters(partnerId: string) {
    return this.http
      .get<Pagination<Parameter>>(
        `${environment.api.endpoint}/web/partners/${partnerId}/parameters?page=1&per_page=100`
      )
      .pipe(
        tap(({ data: parameters }) => {
          const hash = parameters.reduce((acc, item) => {
            acc[item.key] = item;
            return acc;
          }, {});

          this.store.update((state) => {
            return { ...state, parameters: hash };
          });
        }),
        catchError((err) => {
          this.store.setError(err.message);
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  patchParameter(partnerId: string, parameterId: string, payload: Partial<Parameter>) {
    return this.http
      .patch<Parameter>(
        `${environment.api.endpoint}/web/partners/${partnerId}/parameters/${parameterId}`,
        payload
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            return {
              ...state,
              parameters: {
                ...state.parameters,
                [response.key]: response,
              },
            };
          });
        }),
        catchError(() => {
          return of(true);
        })
      );
  }

  postParameter(partnerId: string, payload: Partial<Parameter>) {
    return this.http
      .post<Parameter>(`${environment.api.endpoint}/web/partners/${partnerId}/parameters`, payload)
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            return {
              ...state,
              parameters: {
                ...state.parameters,
                [response.key]: response,
              },
            };
          });
        }),
        catchError(() => {
          return of(true);
        })
      );
  }

  setParameter(partnerId: string, key: string, value: any) {
    if (this.getSharedModule() === 'specialties') {
      return of({});
    }

    const parameter = this.partnerQuery.getParameter(key);
    const payload = {
      key,
      value: `${value}`,
      type: <any>'web',
    };

    return parameter === null
      ? this.postParameter(partnerId, payload)
      : this.patchParameter(partnerId, parameter.id, payload);
  }

  getMedications(
    partnerId: string,
    page = 1,
    per_page = 30,
    query = null,
    sort = 'order',
    order = 'asc',
    withTrashed = false,
    cache = true
  ) {
    const params = {
      page,
      per_page,
      sort,
      order,
    };

    if (page === 1 && cache) {
      this.store.update((state) => {
        return { ...state, medications: [] };
      });
    }

    if (query) {
      (<any>params).search = query;
    }

    if (withTrashed) {
      (<any>params).trashed = 'with';
    }

    return this.http
      .get<Pagination<Medication>>(
        `${environment.api.endpoint}/web/${this.getSharedModule()}/${partnerId}/medications`,
        { params }
      )
      .pipe(
        tap(({ data: medications }) => {
          if (!cache) {
            return;
          }

          this.store.update((state) => {
            return { ...state, medications: [...state.medications, ...medications] };
          });
        }),
        catchError((err) => {
          this.store.setError(err.message);
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getOfferingTags(partnerId: string, offeringId: string, offeringType: string) {
    return this.http
      .get<Pagination<Tag>>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/${offeringType}/${offeringId}/tags?per_page=100`
      )
      .pipe(
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postOfferingTag(partnerId: string, offeringId: string, offeringType: string, tagId: string) {
    return this.http
      .post<Tag>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/${offeringType}/${offeringId}/tags/${tagId}`,
        {}
      )
      .pipe(
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  deleteOfferingTag(partnerId: string, offeringId: string, offeringType: string, tagId: string) {
    return this.http
      .delete(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/${offeringType}/${offeringId}/tags/${tagId}`
      )
      .pipe(
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getOfferingStates(partnerId: string, offeringId: string, offeringType: string) {
    return this.http
      .get<Pagination<State>>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/${offeringType}/${offeringId}/states?per_page=100`
      )
      .pipe(
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  putOfferingState(partnerId: string, offeringId: string, offeringType: string, stateId: string) {
    return this.http
      .put<State>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/${offeringType}/${offeringId}/states/${stateId}`,
        {}
      )
      .pipe(
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  deleteOfferingState(
    partnerId: string,
    offeringId: string,
    offeringType: string,
    stateId: string
  ) {
    return this.http
      .delete(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/${offeringType}/${offeringId}/states/${stateId}`
      )
      .pipe(
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getOfferingDiseases(partnerId: string, offeringId: string, offeringType: string) {
    return this.http
      .get<Pagination<Disease>>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/${offeringType}/${offeringId}/diseases?per_page=100`
      )
      .pipe(
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postOfferingDisease(
    partnerId: string,
    offeringId: string,
    offeringType: string,
    diseaseId: string
  ) {
    return this.http
      .put<Disease>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/${offeringType}/${offeringId}/diseases/${diseaseId}`,
        {}
      )
      .pipe(
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  deleteOfferingDisease(
    partnerId: string,
    offeringId: string,
    offeringType: string,
    diseaseId: string
  ) {
    return this.http
      .delete(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/${offeringType}/${offeringId}/diseases/${diseaseId}`
      )
      .pipe(
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postRepliacateOffering(partnerId: string, offeringId: string, offeringType: string, payload) {
    return this.http
      .post(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/${offeringType}/${offeringId}/replicate`,
        payload
      )
      .pipe(
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postRepliacateIntake(partnerId: string, payload) {
    const questionnaireId = payload.questionnaire_id;

    return this.http
      .post(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/questionnaires/${questionnaireId}/replicate`,
        payload
      )
      .pipe(
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getMedication(partnerId: string, medicationId: string) {
    return this.http
      .get<Pagination<Medication>>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/medications/${medicationId}`
      )
      .pipe(
        catchError((err) => {
          this.store.setError(err.message);
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postMedication(partnerId: string, payload: Partial<Medication>) {
    return this.http
      .post<Medication>(
        `${environment.api.endpoint}/web/${this.getSharedModule()}/${partnerId}/medications`,
        payload
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            return {
              ...state,
              medications: [...state.medications, response],
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  patchMedication(partnerId: string, medicationId: string, payload: Partial<Medication>) {
    return this.http
      .patch<Medication>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/medications/${medicationId}`,
        payload
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const idx = state.medications.findIndex((item) => item.id === response.id);
            const arr = [...state.medications];
            arr.splice(idx, 1, response);

            return {
              ...state,
              medications: arr,
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  restoreMedication(partnerId: string, medicationId: string) {
    return this.http
      .patch<Medication>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/medications/${medicationId}/restore`,
        {}
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const idx = state.medications.findIndex((item) => item.id === response.id);
            const arr = [...state.medications];
            arr.splice(idx, 1, response);

            return {
              ...state,
              medications: arr,
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  deleteMedication(partnerId: string, medicationId: string, withTrashed = false) {
    return this.http
      .delete(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/medications/${medicationId}`
      )
      .pipe(
        tap((response: any) => {
          this.store.update((state) => {
            const idx = state.medications.findIndex((item) => item.id === medicationId);
            const arr = state.medications;

            if (withTrashed && response.length === 0) {
              // item is deleted but should be kept at array
              response = arr[idx];
              response.deleted_at = new Date();
            }

            if (!response || response.length === 0) {
              arr.splice(idx, 1);
            } else {
              arr.splice(idx, 1, response);
            }

            return {
              ...state,
              medications: arr,
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getCompounds(
    partnerId: string,
    page = 1,
    per_page = 30,
    query = null,
    sort = 'order',
    order = 'asc',
    withTrashed = false,
    cache = true
  ) {
    const params = {
      page,
      per_page,
      sort,
      order,
    };

    if (page === 1 && cache) {
      this.store.update((state) => {
        return { ...state, compounds: [] };
      });
    }

    if (query) {
      (<any>params).search = query;
    }

    if (withTrashed) {
      (<any>params).trashed = 'with';
    }

    return this.http
      .get<Pagination<Compound>>(
        `${environment.api.endpoint}/web/${this.getSharedModule()}/${partnerId}/compounds`,
        { params }
      )
      .pipe(
        tap(({ data: compounds }) => {
          if (!cache) {
            return;
          }

          this.store.update((state) => {
            return { ...state, compounds: [...state.compounds, ...compounds] };
          });
        }),
        catchError((err) => {
          this.store.setError(err.message);
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getCompound(partnerId: string, compoundId: string) {
    return this.http
      .get<Pagination<Medication>>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/compounds/${compoundId}`
      )
      .pipe(
        catchError((err) => {
          this.store.setError(err.message);
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postCompound(partnerId: string, payload: Partial<Compound>) {
    return this.http
      .post<Compound>(
        `${environment.api.endpoint}/web/${this.getSharedModule()}/${partnerId}/compounds`,
        payload
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            return {
              ...state,
              compounds: [...state.compounds, response],
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  patchCompound(partnerId: string, compoundId: string, payload: Partial<Compound>) {
    return this.http
      .patch<Compound>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/compounds/${compoundId}`,
        payload
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const idx = state.compounds.findIndex((item) => item.id === response.id);
            const arr = [...state.compounds];
            arr.splice(idx, 1, response);

            return {
              ...state,
              compounds: arr,
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  restoreCompound(partnerId: string, compoundId: string) {
    return this.http
      .patch<Compound>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/compounds/${compoundId}/restore`,
        {}
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const idx = state.compounds.findIndex((item) => item.id === response.id);
            const arr = [...state.compounds];
            arr.splice(idx, 1, response);

            return {
              ...state,
              compounds: arr,
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  deleteCompound(partnerId: string, compoundId: string, withTrashed = false) {
    return this.http
      .delete(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/compounds/${compoundId}`
      )
      .pipe(
        tap((response: any) => {
          this.store.update((state) => {
            const idx = state.compounds.findIndex((item) => item.id === compoundId);
            const arr = state.compounds;

            if (withTrashed && response.length === 0) {
              // item is deleted but should be kept at array
              response = arr[idx];
              response.deleted_at = new Date();
            }

            if (!response || response.length === 0) {
              arr.splice(idx, 1);
            } else {
              arr.splice(idx, 1, response);
            }

            return {
              ...state,
              compounds: arr,
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getServices(
    partnerId: string,
    page = 1,
    per_page = 30,
    query = null,
    sort = 'order',
    order = 'asc',
    withTrashed = false,
    cache = true
  ) {
    const params = {
      page,
      per_page,
      sort,
      order,
    };

    if (page === 1 && cache) {
      this.store.update((state) => {
        return { ...state, services: [] };
      });
    }

    if (query) {
      (<any>params).search = query;
    }

    if (withTrashed) {
      (<any>params).trashed = 'with';
    }

    return this.http
      .get<Pagination<Service>>(
        `${environment.api.endpoint}/web/${this.getSharedModule()}/${partnerId}/services`,
        {
          params,
        }
      )
      .pipe(
        tap(({ data: services }) => {
          if (!cache) {
            return;
          }

          this.store.update((state) => {
            return { ...state, services: [...state.services, ...services] };
          });
        }),
        catchError((err) => {
          this.store.setError(err.message);
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getService(partnerId: string, serviceId: string) {
    return this.http
      .get<Pagination<Medication>>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/services/${serviceId}`
      )
      .pipe(
        catchError((err) => {
          this.store.setError(err.message);
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postService(partnerId: string, payload: Partial<Service>) {
    return this.http
      .post<Service>(
        `${environment.api.endpoint}/web/${this.getSharedModule()}/${partnerId}/services`,
        payload
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            return {
              ...state,
              services: [...state.services, response],
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  patchService(partnerId: string, serviceId: string, payload: Partial<Service>) {
    return this.http
      .patch<Service>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/services/${serviceId}`,
        payload
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const idx = state.services.findIndex((item) => item.id === response.id);
            const arr = [...state.services];
            arr.splice(idx, 1, response);

            return {
              ...state,
              services: arr,
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  restoreService(partnerId: string, serviceId: string) {
    return this.http
      .patch<Service>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/services/${serviceId}/restore`,
        {}
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const idx = state.services.findIndex((item) => item.id === response.id);
            const arr = [...state.services];
            arr.splice(idx, 1, response);

            return {
              ...state,
              services: arr,
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  deleteService(partnerId: string, serviceId: string, withTrashed = false) {
    return this.http
      .delete(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/services/${serviceId}`
      )
      .pipe(
        tap((response: any) => {
          this.store.update((state) => {
            const idx = state.services.findIndex((item) => item.id === serviceId);
            const arr = state.services;

            if (withTrashed && response.length === 0) {
              // item is deleted but should be kept at array
              response = arr[idx];
              response.deleted_at = new Date();
            }

            if (!response || response.length === 0) {
              arr.splice(idx, 1);
            } else {
              arr.splice(idx, 1, response);
            }

            return {
              ...state,
              services: arr,
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getSupplies(
    partnerId: string,
    page = 1,
    per_page = 30,
    query = null,
    sort = 'order',
    order = 'asc',
    withTrashed = false,
    cache = true
  ) {
    const params = {
      page,
      per_page,
      sort,
      order,
    };

    if (page === 1 && cache) {
      this.store.update((state) => {
        return { ...state, supplies: [] };
      });
    }

    if (query) {
      (<any>params).search = query;
    }

    if (withTrashed) {
      (<any>params).trashed = 'with';
    }

    return this.http
      .get<Pagination<Supply>>(
        `${environment.api.endpoint}/web/${this.getSharedModule()}/${partnerId}/supplies`,
        {
          params,
        }
      )
      .pipe(
        tap(({ data: supplies }) => {
          if (!cache) {
            return;
          }

          this.store.update((state) => {
            return { ...state, supplies: [...state.supplies, ...supplies] };
          });
        }),
        catchError((err) => {
          this.store.setError(err.message);
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getSupply(partnerId: string, supplyId: string) {
    return this.http
      .get<Pagination<Medication>>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/supplies/${supplyId}`
      )
      .pipe(
        catchError((err) => {
          this.store.setError(err.message);
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postSupply(partnerId: string, payload: Partial<Supply>) {
    return this.http
      .post<Supply>(
        `${environment.api.endpoint}/web/${this.getSharedModule()}/${partnerId}/supplies`,
        payload
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            return {
              ...state,
              supplies: [...state.supplies, response],
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  patchSupply(partnerId: string, supplyId: string, payload: Partial<Supply>) {
    return this.http
      .patch<Supply>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/supplies/${supplyId}`,
        payload
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const idx = state.supplies.findIndex((item) => item.id === response.id);
            const arr = [...state.supplies];
            arr.splice(idx, 1, response);

            return {
              ...state,
              supplies: arr,
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  restoreSupply(partnerId: string, supplyId: string) {
    return this.http
      .patch<Supply>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/supplies/${supplyId}/restore`,
        {}
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const idx = state.supplies.findIndex((item) => item.id === response.id);
            const arr = [...state.supplies];
            arr.splice(idx, 1, response);

            return {
              ...state,
              supplies: arr,
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  deleteSupply(partnerId: string, supplyId: string, withTrashed = false) {
    return this.http
      .delete(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/supplies/${supplyId}`
      )
      .pipe(
        tap((response: any) => {
          this.store.update((state) => {
            const idx = state.supplies.findIndex((item) => item.id === supplyId);
            const arr = state.supplies;

            if (withTrashed && response.length === 0) {
              // item is deleted but should be kept at array
              response = arr[idx];
              response.deleted_at = new Date();
            }

            if (!response || response.length === 0) {
              arr.splice(idx, 1);
            } else {
              arr.splice(idx, 1, response);
            }

            return {
              ...state,
              supplies: arr,
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getQuestionnaires(partnerId: string, cache = true) {
    return this.http
      .get<Pagination<Questionnaire>>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/questionnaires?page=1&per_page=100`
      )
      .pipe(
        tap(({ data: questionnaires }) => {
          if (!cache) {
            return;
          }

          this.store.update((state) => {
            return { ...state, questionnaires };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getQuestionnaire(partnerId: string, questionnaireId: string) {
    return this.http
      .get<Questionnaire>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/questionnaires/${questionnaireId}`
      )
      .pipe(
        tap((questionnaire) => {
          this.store.update((state) => {
            const idx = state.questionnaires.findIndex((item) => item.id === questionnaireId);

            if (idx > -1) {
              state.questionnaires[idx] = questionnaire;
            } else {
              state.questionnaires.push(questionnaire);
            }

            return state;
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postQuestionnaire(partnerId: string, payload: Partial<Questionnaire>) {
    return this.http
      .post<Questionnaire>(
        `${environment.api.endpoint}/web/${this.getSharedModule()}/${partnerId}/questionnaires`,
        payload
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            return { ...state, questionnaires: [...state.questionnaires, response] };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  patchQuestionnaire(partnerId: string, questionnaireId: string, payload: Partial<Questionnaire>) {
    return this.http
      .patch<Questionnaire>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/questionnaires/${questionnaireId}`,
        payload
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const idx = state.questionnaires.findIndex((item) => item.id === questionnaireId);
            const questionnaire = state.questionnaires[idx];

            state.questionnaires[idx] = {
              ...questionnaire,
              ...response,
            };

            return state;
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  restoreQuestionnaire(partnerId: string, questionnaireId: string) {
    return this.http
      .patch<Questionnaire>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/questionnaires/${questionnaireId}/restore`,
        {}
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const idx = state.questionnaires.findIndex((item) => item.id === response.id);
            const arr = [...state.questionnaires];
            arr.splice(idx, 1, response);

            return {
              ...state,
              questionnaires: arr,
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  deleteQuestionnaire(partnerId: string, questionnaireId: string) {
    return this.http
      .delete<any>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/questionnaires/${questionnaireId}`
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const idx = state.questionnaires.findIndex((item) => item.id === questionnaireId);
            const questionnaires = [...state.questionnaires];

            if (!response || response.length === 0) {
              questionnaires.splice(idx, 1);
            } else {
              questionnaires.splice(idx, 1, response);
            }

            return { ...state, questionnaires };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getQuestionnaireQuestions(partnerId: string, questionnaireId: string, cache = true) {
    return this.http
      .get<Pagination<Question>>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/questionnaires/${questionnaireId}/questions?page=1&per_page=100&with_relationships[]=files&with_relationships[]=options&with_relationships[]=options.review&with_relationships[]=rules.requirements&with_relationships[]=rules.review&with_relationships[]=rules.requirements.review`
      )
      .pipe(
        tap(({ data: questions }) => {
          if (!cache) {
            return;
          }

          this.store.update((state) => {
            const questionnaire = state.questionnaires.find((item) => item.id === questionnaireId);

            // order question options
            questions.forEach((question) => {
              question.options?.sort((a, b) => a.order - b.order);
            });

            questionnaire.questions = questions;

            return state;
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getQuestionnaireQuestion(partnerId: string, questionnaireId: string, questionId: string) {
    return this.http
      .get<Question>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/questionnaires/${questionnaireId}/questions/${questionId}?with_relationships[]=files&with_relationships[]=options&with_relationships[]=options.review&with_relationships[]=rules.requirements&with_relationships[]=rules.review&with_relationships[]=rules.requirements.review`
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const questionnaire = state.questionnaires.find((item) => item.id === questionnaireId);
            const idx = questionnaire.questions.findIndex((item) => item.id === questionId);
            const question = questionnaire.questions[idx];

            // order question options
            response.options?.sort((a, b) => a.order - b.order);

            questionnaire.questions[idx] = {
              ...question,
              ...response,
            };

            return state;
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postQuestionnaireQuestion(
    partnerId: string,
    questionnaireId: string,
    payload: Partial<Question>
  ) {
    return this.http
      .post<Question>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/questionnaires/${questionnaireId}/questions`,
        payload
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const questionnaire = state.questionnaires.find((item) => item.id === questionnaireId);

            const question = {
              rules: [],
              options: [],
              ...response,
            };

            questionnaire.questions.sort((a, b) => a.order - b.order);
            questionnaire.questions.push(question);

            return state;
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  patchQuestionnaireQuestion(
    partnerId: string,
    questionnaireId: string,
    questionId: string,
    payload: Partial<Question>
  ) {
    return this.http
      .patch<Question>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/questionnaires/${questionnaireId}/questions/${questionId}`,
        payload
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const questionnaire = state.questionnaires.find((item) => item.id === questionnaireId);
            const idx = questionnaire.questions.findIndex((item) => item.id === questionId);
            const question = questionnaire.questions[idx];

            questionnaire.questions[idx] = {
              ...question,
              ...response,
            };

            questionnaire.questions.sort((a, b) => a.order - b.order);

            return state;
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  deleteQuestionnaireQuestion(partnerId: string, questionnaireId: string, questionId: string) {
    return this.http
      .delete<any>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/questionnaires/${questionnaireId}/questions/${questionId}`
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const questionnaire = state.questionnaires.find((item) => item.id === questionnaireId);
            const idx = questionnaire.questions.findIndex((item) => item.id === questionId);
            const arr = questionnaire.questions;

            if (!response || response.length === 0) {
              arr.splice(idx, 1);
            } else {
              arr.splice(idx, 1, response);
            }

            return state;
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  putQuestionnaireQuestionFile(
    partnerId: string,
    questionnaireId: string,
    questionId: string,
    fileId: string
  ) {
    return this.http
      .put<Question>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/questionnaires/${questionnaireId}/questions/${questionId}/files/${fileId}`,
        { title: 'file' }
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const questionnaire = state.questionnaires.find((item) => item.id === questionnaireId);
            const idx = questionnaire.questions.findIndex((item) => item.id === questionId);
            const question = questionnaire.questions[idx];

            questionnaire.questions[idx] = {
              ...question,
              ...response,
            };

            return state;
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  deleteQuestionnaireQuestionFile(
    partnerId: string,
    questionnaireId: string,
    questionId: string,
    fileId: string
  ) {
    return this.http
      .delete<Question>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/questionnaires/${questionnaireId}/questions/${questionId}/files/${fileId}`
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const questionnaire = state.questionnaires.find((item) => item.id === questionnaireId);
            const idx = questionnaire.questions.findIndex((item) => item.id === questionId);
            const question = questionnaire.questions[idx];

            questionnaire.questions[idx] = {
              ...question,
              ...response,
            };

            return state;
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getQuestionnaireQuestionOptions(partnerId: string, questionnaireId: string, questionId: string) {
    return this.http
      .get<Option[]>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/questionnaires/${questionnaireId}/questions/${questionId}/options`
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const questionnaire = state.questionnaires.find((item) => item.id === questionnaireId);
            const question = questionnaire.questions.find((item) => item.id === questionId);

            question.options = response;

            // order question options
            question.options?.sort((a, b) => a.order - b.order);

            return state;
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postQuestionnaireQuestionOption(
    partnerId: string,
    questionnaireId: string,
    questionId: string,
    payload: Partial<Option>
  ) {
    return this.http
      .post<Option>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/questionnaires/${questionnaireId}/questions/${questionId}/options`,
        payload
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const questionnaire = state.questionnaires.find((item) => item.id === questionnaireId);
            const question = questionnaire.questions.find((item) => item.id === questionId);

            question.options?.push(response);

            // order question options
            question.options?.sort((a, b) => a.order - b.order);

            return state;
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  patchQuestionnaireQuestionOption(
    partnerId: string,
    questionnaireId: string,
    questionId: string,
    optionId: string,
    payload: Partial<Option>
  ) {
    return this.http
      .patch<Option>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/questionnaires/${questionnaireId}/questions/${questionId}/options/${optionId}`,
        payload
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const questionnaire = state.questionnaires.find((item) => item.id === questionnaireId);
            const question = questionnaire.questions.find((item) => item.id === questionId);
            const idx = question.options.findIndex((item) => item.id === optionId);
            const option = question.options[idx];

            question.options[idx] = {
              ...option,
              ...response,
            };

            // order question options
            question.options?.sort((a, b) => a.order - b.order);

            return state;
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  deleteQuestionnaireQuestionOption(
    partnerId: string,
    questionnaireId: string,
    questionId: string,
    optionId: string
  ) {
    return this.http
      .delete<any>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/questionnaires/${questionnaireId}/questions/${questionId}/options/${optionId}`
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const questionnaire = state.questionnaires.find((item) => item.id === questionnaireId);
            const question = questionnaire.questions.find((item) => item.id === questionId);
            const arr = question.options;
            const idx = arr.findIndex((item) => item.id === optionId);

            if (!response || response.length === 0) {
              arr.splice(idx, 1);
            } else {
              arr.splice(idx, 1, response);
            }

            return state;
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getQuestionnaireQuestionRules(partnerId: string, questionnaireId: string, questionId: string) {
    const params = {
      page: 1,
      per_page: 100,
      'with_relationships[]': 'requirements',
    };

    return this.http
      .get<Pagination<Rule>>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/questionnaires/${questionnaireId}/questions/${questionId}/rules`,
        { params }
      )
      .pipe(
        tap(({ data: rules }) => {
          this.store.update((state) => {
            const questionnaire = state.questionnaires.find((item) => item.id === questionnaireId);
            const question = questionnaire.questions.find((item) => item.id === questionId);

            question.rules = rules;

            return state;
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  saveQuestionnaireQuestionRulesAndRequirements(
    partnerId: string,
    questionnaireId: string,
    questionId: string,
    rules: Rule[]
  ) {
    const payload = {
      rules,
    };

    return this.http
      .post(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/questionnaires/${questionnaireId}/questions/${questionId}/rules/save`,
        payload
      )
      .pipe(
        switchMap(() => {
          return this.getQuestionnaireQuestionRules(partnerId, questionnaireId, questionId);
        }),
        map((response) => response.data),
        tap((response) => {
          this.store.update((state) => {
            const questionnaire = state.questionnaires.find((item) => item.id === questionnaireId);
            const question = questionnaire.questions.find((item) => item.id === questionId);

            question.rules = response;

            return state;
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getQuestionnaireOfferings(partnerId: string, questionnaireId: string) {
    const params = {
      page: 1,
      per_page: 100,
    };

    return this.http
      .get<Pagination<Offering>>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/questionnaires/${questionnaireId}/offerings?with_relationships[]=rules.requirements&with_relationships[]=rules.review&with_relationships[]=rules.requirements.review`,
        { params }
      )
      .pipe(
        tap(({ data: offerings }) => {
          this.store.update((state) => {
            const questionnaire = state.questionnaires.find((item) => item.id === questionnaireId);
            questionnaire.offerings = offerings;

            return state;
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postQuestionnaireOffering(partnerId: string, questionnaireId: string, payload: any) {
    return this.http
      .post<Offering>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/questionnaires/${questionnaireId}/offerings`,
        payload
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const questionnaire = state.questionnaires.find((item) => item.id === questionnaireId);
            questionnaire.offerings.push(response);

            return state;
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  patchQuestionnaireOffering(
    partnerId: string,
    questionnaireId: string,
    offeringId: string,
    payload: Partial<Question>
  ) {
    return this.http
      .patch<Offering>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/questionnaires/${questionnaireId}/offerings/${offeringId}`,
        payload
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const questionnaire = state.questionnaires.find((item) => item.id === questionnaireId);
            const idx = questionnaire.offerings.findIndex((item) => item.id === offeringId);
            const offering = questionnaire.offerings[idx];
            questionnaire.offerings[idx] = {
              ...offering,
              ...response,
            };

            return state;
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  deleteQuestionnaireOffering(partnerId: string, questionnaireId: string, offeringId: string) {
    return this.http
      .delete<any>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/questionnaires/${questionnaireId}/offerings/${offeringId}`
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const questionnaire = state.questionnaires.find((item) => item.id === questionnaireId);
            const arr = questionnaire.offerings;
            const idx = arr.findIndex((item) => item.id === offeringId);

            if (!response || response.length === 0) {
              arr.splice(idx, 1);
            } else {
              arr.splice(idx, 1, response);
            }

            return state;
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getQuestionnaireOfferingRules(partnerId: string, questionnaireId: string, offeringId: string) {
    const params = {
      page: 1,
      per_page: 100,
      'with_relationships[]': 'requirements',
    };

    return this.http
      .get<Pagination<Rule>>(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/questionnaires/${questionnaireId}/offerings/${offeringId}/rules`,
        { params }
      )
      .pipe(
        tap(({ data: rules }) => {
          this.store.update((state) => {
            const questionnaire = state.questionnaires.find((item) => item.id === questionnaireId);
            const offering = questionnaire.offerings.find((item) => item.id === offeringId);

            offering.rules = rules;

            return state;
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  saveQuestionnaireOfferingRulesAndRequirements(
    partnerId: string,
    questionnaireId: string,
    offeringId: string,
    rules: Rule[]
  ) {
    const payload = {
      rules,
    };

    return this.http
      .post(
        `${
          environment.api.endpoint
        }/web/${this.getSharedModule()}/${partnerId}/questionnaires/${questionnaireId}/offerings/${offeringId}/rules/save`,
        payload
      )
      .pipe(
        switchMap(() => {
          return this.getQuestionnaireOfferingRules(partnerId, questionnaireId, offeringId);
        }),
        map((response) => response.data),
        tap((response) => {
          this.store.update((state) => {
            const questionnaire = state.questionnaires.find((item) => item.id === questionnaireId);
            const offering = questionnaire.offerings.find((item) => item.id === offeringId);

            offering.rules = response;

            return state;
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getPatients(
    partnerId: string,
    environments: string[] = [],
    page = 1,
    length = 10,
    id?: string,
    email?: string,
    last_name?: string,
    first_name?: string
  ) {
    const query = {
      page,
      'environments[]': environments,
      per_page: length,
    };

    if (id) {
      (<any>query).id = id;
    }

    if (email) {
      (<any>query).email = email;
    }

    if (last_name) {
      (<any>query).last_name = last_name;
    }

    if (first_name) {
      (<any>query).first_name = first_name;
    }

    return this.http
      .get<Pagination<Patient>>(`${environment.api.endpoint}/web/partners/${partnerId}/patients`, {
        params: query,
      })
      .pipe(
        catchError((err) => {
          if (id || email || last_name) {
            return of({
              data: [],
              meta: { current_page: 0, from: 0, to: 0, total: 0, last_page: 0 },
            });
          }

          this.store.setError(err.message);
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getAuditLogs(partnerId: string, page = 1, length = 10) {
    const params = {
      page,
      per_page: length,
    };

    return this.http
      .get<Pagination<AuditLog>>(`${environment.api.endpoint}/web/partners/${partnerId}/audits`, {
        params,
      })
      .pipe(
        catchError((err) => {
          this.store.setError(err.message);
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getWebhookLogs(
    partnerId: string,
    environments: string[] = [],
    page = 1,
    length = 10,
    query?: string
  ) {
    const params = {
      page,
      per_page: length,
      'environments[]': environments,
    };

    if (query) {
      (<any>params).model_id = query;
    }

    return this.http
      .get<Pagination<WebhookLog>>(
        `${environment.api.endpoint}/web/partners/${partnerId}/webhooks`,
        { params }
      )
      .pipe(
        catchError((err) => {
          if (query) {
            return of({ data: [], meta: { last_page: 0 } });
          }

          this.store.setError(err.message);
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getWebhookLog(partnerId: string, webhookId: string) {
    return this.http
      .get<WebhookLog>(
        `${environment.api.endpoint}/web/partners/${partnerId}/webhooks/${webhookId}`
      )
      .pipe(
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postWebhookResend(partnerId: string, webhookId: string) {
    return this.http
      .post<WebhookLog>(
        `${environment.api.endpoint}/web/partners/${partnerId}/webhooks/${webhookId}/resend`,
        {}
      )
      .pipe(
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getEncounters(
    partnerId: string,
    environments: string[] = [],
    page = 1,
    length = 10,
    id?: string
  ) {
    const query = {
      page,
      'environments[]': environments,
      per_page: length,
    };

    if (id) {
      (<any>query).id = id;
    }

    return this.http
      .get<Pagination<Encounter>>(
        `${environment.api.endpoint}/web/partners/${partnerId}/encounters`,
        {
          params: query,
        }
      )
      .pipe(
        catchError((err) => {
          if (id) {
            return of({
              data: [],
              meta: { current_page: 0, from: 0, to: 0, total: 0, last_page: 0 },
            });
          }

          this.store.setError(err.message);
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postExportEncounters(partnerId: string, payload) {
    return this.http
      .post<File>(
        `${environment.api.endpoint}/web/partners/${partnerId}/encounters/export`,
        payload
      )
      .pipe(
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  putAttachClinician(id: string, clinicianId: string, payload: { priority: string }) {
    let url = `${environment.api.endpoint}/web/partners/${id}/clinicians/${clinicianId}`;

    if (this.getSharedModule() === 'specialties') {
      url = `${environment.api.endpoint}/web/clinicians/${clinicianId}/specialties/${id}`;
    }

    return this.http.put<Clinician>(url, payload).pipe(
      tap((response) => {
        this.store.update((state) => {
          return {
            ...state,
            clinicians: [...state.clinicians, response],
          };
        });
      }),
      catchError((err) => {
        this.toastService.error(err.message);
        this.loggerService.error(err);
        throw err;
      })
    );
  }

  patchAttachClinician(id: string, clinicianId: string, payload: { priority: string }) {
    let url = `${environment.api.endpoint}/web/partners/${id}/clinicians/${clinicianId}`;

    if (this.getSharedModule() === 'specialties') {
      url = `${environment.api.endpoint}/web/clinicians/${clinicianId}/specialties/${id}`;
    }

    return this.http.patch<Clinician>(url, payload).pipe(
      tap((response) => {
        this.store.update((state) => {
          const idx = state.clinicians.findIndex((item) => item.id === response.id);
          const arr = [...state.clinicians];
          arr.splice(idx, 1, response);

          return {
            ...state,
            clinicians: arr,
          };
        });
      }),
      catchError((err) => {
        this.toastService.error(err.message);
        this.loggerService.error(err);
        throw err;
      })
    );
  }

  detachClinician(id: string, clinicianId: string) {
    let url = `${environment.api.endpoint}/web/partners/${id}/clinicians/${clinicianId}`;

    if (this.getSharedModule() === 'specialties') {
      url = `${environment.api.endpoint}/web/clinicians/${clinicianId}/specialties/${id}`;
    }

    return this.http.delete(url).pipe(
      tap(() => {
        this.store.update((state) => {
          const idx = state.clinicians.findIndex((item) => item.id === clinicianId);
          const arr = state.clinicians.splice(idx, 1);

          return {
            ...state,
            clinician: arr,
          };
        });
      }),
      catchError((err) => {
        this.toastService.error(err.message);
        this.loggerService.error(err);
        throw err;
      })
    );
  }

  getVouchers(partnerId: string, page = 1, length = 10, query?: string) {
    const params = {
      page,
      per_page: length,
    };

    if (query) {
      (<any>params).voucher_id = query;
    }

    return this.http
      .get<Pagination<Voucher>>(`${environment.api.endpoint}/web/partners/${partnerId}/vouchers`, {
        params,
      })
      .pipe(
        tap(({ data: vouchers }) => {
          this.store.update((state) => {
            return { ...state, vouchers };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);

          return of({ data: [], meta: { last_page: 0 } });
        })
      );
  }

  getVoucher(partnerId: string, voucherId: string) {
    return this.http
      .get<Voucher>(`${environment.api.endpoint}/web/partners/${partnerId}/vouchers/${voucherId}`)
      .pipe(
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postVoucher(partnerId: string, payload: VoucherPayload) {
    return this.http
      .post<Voucher>(`${environment.api.endpoint}/web/partners/${partnerId}/vouchers`, payload)
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            return {
              ...state,
              vouchers: [...state.vouchers, response],
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  deleteVoucher(partnerId: string, voucherId: string) {
    return this.http
      .delete(`${environment.api.endpoint}/web/partners/${partnerId}/vouchers/${voucherId}`)
      .pipe(
        tap(() => {
          this.store.update((state) => {
            return {
              ...state,
              vouchers: state.vouchers.filter((item) => item.id !== voucherId),
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getReviews(partnerId: string, page = 1, length = 10) {
    return this.http
      .get<Pagination<Review<any>>>(
        `${environment.api.endpoint}/web/partners/${partnerId}/reviews?page=${page}&per_page=${length}&with_relationships[]=model`
      )
      .pipe(
        tap(({ data: reviews }) => {
          this.store.update((state) => {
            return { ...state, reviews };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  deleteReview(reviewId: string) {
    this.store.update((state) => {
      return {
        ...state,
        reviews: state.reviews.filter((item) => item.id !== reviewId),
      };
    });
  }

  getTickets(partnerId: string, page = 1, length = 10) {
    return this.http
      .get<Pagination<Ticket>>(
        `${environment.api.endpoint}/web/partners/${partnerId}/tickets?page=${page}&per_page=${length}`
      )
      .pipe(
        tap(({ data: tickets }) => {
          this.store.update((state) => {
            return { ...state, tickets };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getShopifyProducts(partnerId: string, nextPageUUID?: string) {
    const params = {
      per_page: 150,
    };

    if (nextPageUUID) {
      (<any>params).page_info = nextPageUUID;
    }

    return this.http
      .get<ShopifyPagination>(
        `${environment.api.endpoint}/web/partners/${partnerId}/shopify/products`,
        { params }
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const products = nextPageUUID ? state.shopify.products : [];
            products.push(...response.data);

            return {
              ...state,
              shopify: {
                ...state.shopify,
                products,
              },
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getShopifyAssociations(partnerId: string, page = 1) {
    const params = {
      page,
      per_page: 100,
    };

    return this.http
      .get<Pagination<ShopifyAssociation>>(
        `${environment.api.endpoint}/web/partners/${partnerId}/shopify/associate-products?with_relationships[]=questionnaire&with_relationships[]=offerings`,
        { params }
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            return {
              ...state,
              shopify: {
                ...state.shopify,
                associations: response.data,
              },
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postShopifyProductAssociation(partnerId: string, payload: Partial<ShopifyAssociation>) {
    return this.http
      .post<ShopifyAssociation>(
        `${environment.api.endpoint}/web/partners/${partnerId}/shopify/associate-products`,
        payload
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            return {
              ...state,
              shopify: {
                ...state.shopify,
                associations: [...state.shopify.associations, response],
              },
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  patchShopifyProductAssociation(
    partnerId: string,
    associationId: string,
    payload: Partial<ShopifyAssociation>
  ) {
    return this.http
      .patch<ShopifyAssociation>(
        `${environment.api.endpoint}/web/partners/${partnerId}/shopify/associate-products/${associationId}`,
        payload
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const idx = state.shopify.associations.findIndex((item) => item.id === associationId);
            const arr = [...state.shopify.associations];
            arr.splice(idx, 1, response);

            return {
              ...state,
              shopify: {
                ...state.shopify,
                associations: arr,
              },
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  deleteShopifyProductAssociation(partnerId: string, associationId: string) {
    return this.http
      .delete(
        `${environment.api.endpoint}/web/partners/${partnerId}/shopify/associate-products/${associationId}`
      )
      .pipe(
        tap(() => {
          this.store.update((state) => {
            const arr = state.shopify.associations.filter((item) => item.id !== associationId);

            return {
              ...state,
              shopify: {
                ...state.shopify,
                associations: arr,
              },
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getDomain(partnerId: string) {
    return this.http
      .get<EmailDomain>(`${environment.api.endpoint}/web/partners/${partnerId}/email-domain`)
      .pipe(
        tap((domain) => {
          this.store.update((state) => {
            return {
              ...state,
              domain,
            };
          });
        }),
        catchError((err) => {
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postEmailDomain(partnerId: string, payload: EmailDomain) {
    return this.http
      .post<EmailDomain>(
        `${environment.api.endpoint}/web/partners/${partnerId}/email-domain`,
        payload
      )
      .pipe(
        tap((domain) => {
          this.store.update((state) => {
            return {
              ...state,
              domain,
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postEmailDomainTest(partnerId: string, payload: { email: string }) {
    return this.http
      .post(`${environment.api.endpoint}/web/partners/${partnerId}/email-domain/send`, payload)
      .pipe(
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postEmailDomainValidate(partnerId: string) {
    return this.http
      .post<EmailDomain>(
        `${environment.api.endpoint}/web/partners/${partnerId}/email-domain/validate`,
        {}
      )
      .pipe(
        tap((domain) => {
          this.store.update((state) => {
            return {
              ...state,
              domain,
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  deleteEmailDomain(partnerId: string) {
    return this.http
      .delete(`${environment.api.endpoint}/web/partners/${partnerId}/email-domain`)
      .pipe(
        tap(() => {
          this.store.update((state) => {
            return {
              ...state,
              domain: null,
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getTicket(partnerId: string, ticketId: string) {
    return this.http
      .get<Ticket>(`${environment.api.endpoint}/web/partners/${partnerId}/tickets/${ticketId}`)
      .pipe(
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postTicket(partnerId: string, payload: Partial<Ticket>) {
    return this.http
      .post<Ticket>(`${environment.api.endpoint}/web/partners/${partnerId}/tickets`, payload)
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            return {
              ...state,
              tickets: [...state.tickets, response],
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  deleteTicket(partnerId: string, ticketId: string) {
    return this.http
      .delete(`${environment.api.endpoint}/web/partners/${partnerId}/tickets/${ticketId}`)
      .pipe(
        tap(() => {
          this.store.update((state) => {
            return {
              ...state,
              tickets: state.tickets.filter((item) => item.id !== ticketId),
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getWebhookEnvironments(partnerId: string) {
    return this.http
      .get<Pagination<Webhook>>(
        `${environment.api.endpoint}/web/partners/${partnerId}/environments?page=1&per_page=100`
      )
      .pipe(
        map(({ data: envs }) => {
          return envs;
        }),
        tap((webhooks) => this.store.update({ webhooks }))
      );
  }

  postWebhookEnvironment(partnerId: string, payload: Partial<Webhook>) {
    return this.http
      .post<Webhook>(`${environment.api.endpoint}/web/partners/${partnerId}/environments`, payload)
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            return {
              ...state,
              webhooks: [...state.webhooks, response],
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  patchWebhookEnvironment(partnerId: string, webhookId: string, payload: Partial<Webhook>) {
    return this.http
      .patch<Webhook>(
        `${environment.api.endpoint}/web/partners/${partnerId}/environments/${webhookId}`,
        payload
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const idx = state.webhooks.findIndex((item) => item.id === response.id);
            const arr = [...state.webhooks];
            arr.splice(idx, 1, response);

            return {
              ...state,
              webhooks: arr,
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  deleteWebhookEnvironment(partnerId: string, environmentId: string) {
    return this.http
      .delete(`${environment.api.endpoint}/web/partners/${partnerId}/environments/${environmentId}`)
      .pipe(
        tap(() => {
          this.store.update((state) => {
            return {
              ...state,
              webhooks: state.webhooks.filter((item) => item.id !== environmentId),
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getEmailTemplates(partnerId: string) {
    return this.http
      .get<Pagination<EmailTemplate>>(
        `${environment.api.endpoint}/web/partners/${partnerId}/email-templates?page=1&per_page=100`
      )
      .pipe(
        tap(({ data: emailTemplates }) => this.store.update({ emailTemplates })),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getEmailTemplate(partnerId: string, templateId: string) {
    return this.http
      .get<EmailTemplate>(
        `${environment.api.endpoint}/web/partners/${partnerId}/email-templates/${templateId}`
      )
      .pipe(
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postEmailTemplate(partnerId: string, payload: Partial<EmailTemplate>) {
    return this.http
      .post<EmailTemplate>(
        `${environment.api.endpoint}/web/partners/${partnerId}/email-templates`,
        payload
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            return {
              ...state,
              emailTemplates: [...state.emailTemplates, response],
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  patchEmailTemplate(partnerId: string, templateId: string, payload: Partial<EmailTemplate>) {
    return this.http
      .patch<EmailTemplate>(
        `${environment.api.endpoint}/web/partners/${partnerId}/email-templates/${templateId}`,
        payload
      )
      .pipe(
        tap((response) => {
          this.store.update((state) => {
            const idx = state.emailTemplates.findIndex((item) => item.id === response.id);
            const arr = [...state.emailTemplates];
            arr.splice(idx, 1, response);

            return {
              ...state,
              emailTemplates: arr,
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  deleteEmailTemplate(partnerId: string, templateId: string) {
    return this.http
      .delete(`${environment.api.endpoint}/web/partners/${partnerId}/email-templates/${templateId}`)
      .pipe(
        tap(() => {
          this.store.update((state) => {
            return {
              ...state,
              emailTemplates: state.emailTemplates.filter((item) => item.id !== templateId),
            };
          });
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postEmailTemplateTest(partnerId: string, payload: { key: string; email: string }) {
    return this.http
      .post(`${environment.api.endpoint}/web/partners/${partnerId}/email-templates/send`, payload)
      .pipe(
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  getEmailStyle(partnerId: string) {
    return this.http
      .get<EmailStyle>(`${environment.api.endpoint}/web/partners/${partnerId}/email-style`)
      .pipe(
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  postEmailStyle(partnerId: string, payload: { style: string }) {
    return this.http
      .post<EmailStyle>(
        `${environment.api.endpoint}/web/partners/${partnerId}/email-style`,
        payload
      )
      .pipe(
        catchError((err) => {
          this.toastService.error(err.message);
          this.loggerService.error(err);
          throw err;
        })
      );
  }

  resetStatistics() {
    const initial = PartnerStoreInitialState();

    this.store.update((state) => {
      return {
        ...state,
        statistics: initial.statistics,
      };
    });
  }

  setStatisticsCache(partnerId: string, startDate: Date, endDate: Date) {
    this.store.update((state) => {
      return {
        ...state,
        statistics: {
          ...state.statistics,
          cache: {
            date: new Date().toString(),
            startDate: startDate.toString(),
            endDate: endDate.toString(),
            partner_id: partnerId,
          },
        },
      };
    });
  }

  getStatistics(partnerId: string, query: string, payload = {}, cache = true, cacheKey = null) {
    const params = payload;
    const identifier = query?.split('?')[0];

    return this.http
      .get<{ result: any }>(
        `${environment.api.endpoint}/web/partners/${partnerId}/statistics/${query}`,
        { params }
      )
      .pipe(
        map(({ result: response }) => {
          return response;
        }),
        map((response) => {
          if (identifier.indexOf('message_dismissed_ratio_by_user_type') > -1) {
            return this.messageDismissedRatioMap(response, true);
          }

          if (identifier.indexOf('message_dismissed_ratio') > -1) {
            return this.messageDismissedRatioMap(response, false);
          }

          if (identifier.indexOf('churn_rate') > -1) {
            return this.churnRateMap(response);
          }

          if (identifier.indexOf('patient_gender') > -1) {
            return this.mainMap(response, 'count_patients');
          }

          if (identifier.indexOf('patient_bmi') > -1) {
            return this.bmiMap(response);
          }

          if (identifier.indexOf('patient_age') > -1) {
            return this.mainMap(response, 'count_patients');
          }

          if (identifier.indexOf('case_prescription_and_service') > -1) {
            return this.mainMap(response, 'count_offerables');
          }

          if (identifier.indexOf('case_diseases') > -1) {
            return this.mainMap(response, 'count_diseases');
          }

          if (identifier.indexOf('analytics_browsers') > -1) {
            return this.mainMap(response, 'count_browsers');
          }

          if (identifier.indexOf('analytics_devices') > -1) {
            return this.mainMap(response, 'count_devices');
          }

          if (identifier.indexOf('patient_states') > -1) {
            return this.mainMap(response, 'count_patients');
          }

          return response;
        }),
        tap((result) => {
          if (!cache) {
            return;
          }

          this.store.update((state) => {
            let newState = {
              ...state.statistics[cacheKey || identifier],
              ...result,
            };

            if (Array.isArray(result)) {
              newState = result;
            }

            return {
              ...state,
              statistics: {
                ...state.statistics,
                [cacheKey || identifier]: newState,
              },
            };
          });
        })
      );
  }

  private messageDismissedRatioMap(response, graph) {
    const rtn = {};
    Object.keys(response).forEach((key) => {
      const patient = response[key].filter((item) => item.channel === 'patient');
      const support = response[key].filter((item) => item.channel === 'support');

      if (graph) {
        rtn[key] = {
          patient,
          support,
        };

        return;
      }

      rtn[key] = {
        patient: {
          ...(patient[0] || {}),
          category: key,
        },
        support: {
          ...(support[0] || {}),
          category: key,
        },
      };
    });

    return rtn;
  }

  private mainMap(response, key) {
    const main = response[key]?.reduce(
      (acc, item) => {
        if (item.value > acc.value) {
          return item;
        }

        return acc;
      },
      { value: 0 }
    );

    const normalizedData = response[key]?.map((item) => {
      return {
        ...item,
        value: Math.round(item.value),
      };
    });

    return {
      [key]: normalizedData,
      main,
    };
  }

  private bmiMap(response) {
    const bmi = response.count_patients?.reduce(
      (acc, item) => {
        if (item.gender_name === 'male') {
          acc.male.push(item);

          if (item.value > acc.mainMale.value) {
            acc.mainMale = item;
          }
        }

        if (item.gender_name === 'female') {
          acc.female.push(item);

          if (item.value > acc.mainFemale.value) {
            acc.mainFemale = item;
          }
        }

        return acc;
      },
      { male: [], female: [], mainMale: { value: 0 }, mainFemale: { value: 0 } }
    );

    const percentage = response.percentage_patients?.reduce(
      (acc, item) => {
        if (item.gender_name === 'male') {
          acc.percentage_male.push(item);
        }

        if (item.gender_name === 'female') {
          acc.percentage_female.push(item);
        }

        return acc;
      },
      { percentage_male: [], percentage_female: [] }
    );

    return {
      ...bmi,
      ...percentage,
    };
  }

  private churnRateMap(response) {
    const inactiveUsers = response.count_patients
      ?.filter((item) => item.patient_status === 'inactive')
      ?.map((item) => {
        return {
          ...item,
          patient_status_category: this.getChurnRateCategory(item.patient_status_category),
        };
      });

    const percInactiveUsers = response.perc_patients_per_status
      ?.filter((item) => item.patient_status === 'inactive')
      ?.map((item) => {
        return {
          ...item,
          patient_status_category: this.getChurnRateCategory(item.patient_status_category),
        };
      });

    return {
      count_patients: inactiveUsers,
      perc_patients_per_status: percInactiveUsers,
    };
  }

  private getSharedModule() {
    return this.store.getValue()?.module;
  }

  private getChurnRateCategory(str) {
    if (!str) {
      return '';
    }

    if (str.indexOf('>') > -1) {
      return `Over ${str.split('.>')[1]}m`;
    }

    return `Up to ${str.split('.<=')[1]}m`;
  }
}
