<app-modal (close)="dismiss()">
  <h3 class="c-modal__title">
    {{ 'MODALS.CREATE_TICKET.TITLE' | translate }}
  </h3>

  <form [formGroup]="form">
    <app-field-input class="u-margin-bottom--md" label="MODALS.CREATE_TICKET.FORM.TITLE"
      formControlName="title">
    </app-field-input>

    <app-field-textarea class="u-margin-bottom--md" label="MODALS.CREATE_TICKET.FORM.DESCRIPTION"
      formControlName="description">
    </app-field-textarea>

    <div class="l-grid l-grid--2">
      <div class="l-grid__item">
        <app-field-select class="u-margin-bottom--md" label="MODALS.CREATE_TICKET.FORM.TYPE"
          formControlName="type" [options]="types">
        </app-field-select>
      </div>
      <div class="l-grid__item">
        <app-field-select class="u-margin-bottom--md" label="MODALS.CREATE_TICKET.FORM.PRODUCT"
          formControlName="product" [options]="products">
        </app-field-select>
      </div>
    </div>

    <app-field-input class="u-margin-bottom--md" label="MODALS.CREATE_TICKET.FORM.REQUESTER"
      formControlName="requester_email" type="email">
    </app-field-input>
  </form>

  <div class="c-modal__footer">
    <app-button (click)="dismiss()" [disabled]="submitting" class="u-hide--xs" variant="secondary">
      {{ 'BUTTONS.CANCEL' | translate }}
    </app-button>

    <app-button class="c-modal__btn" (click)="submit()" [disabled]="submitting || form.invalid"
      [loading]="submitting">
      {{ 'BUTTONS.SAVE' | translate }}
    </app-button>
  </div>
</app-modal>