import { InjectionToken } from '@angular/core';
import * as Rollbar from 'rollbar';
import { environment } from '../../../../environments/environment';

/*
  Documentation: https://docs.rollbar.com/docs/angular
 */

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

export function rollbarFactory() {
  return new Rollbar({
    accessToken: environment.log.accessTokenPublic,
    environment: environment.environment,
    captureIp: 'anonymize',
    ignoredMessages: [
      'Too many requests. Wait a few seconds before trying again',
      'The selected email is invalid.',
      "We couldn't find what you requested.",
      'The status date to field is required when status date from is present.',
      'Verification code has already been verified.',
      'Case is already processing.',
    ],
  });
}
