<div class="c-section c-section--title">
  <div class="l-wrapper">
    <div class="e-about__video">
      <video playsinline controls poster="./assets/images/about.webp">
        <source src="./assets/images/about.mp4" type="video/mp4">
      </video>
    </div>
  </div>
</div>

<div class="l-wrapper u-margin-top--lg">
  <!-- Section 1 -->
  <div class="l-container l-container--xxs e-about__hero">
    <div class="c-section c-section--vertical u-padding-bottom--none">
      <h2 class=" c-section__title u-margin-bottom--md">
        {{ 'ABOUT.SECTION_1.TITLE' | translate }}
      </h2>

      <p class="c-section__description u-text-align--center">
        {{ 'ABOUT.SECTION_1.DESCRIPTION' | translate }}
      </p>
    </div>
  </div>

  <div class="l-container l-container--xs e-about__statistics">
    <div class="c-section c-section--vertical">
      <div class=" l-grid l-grid--3">
        <div class="l-grid__item">
          <h4>{{ "ABOUT.SECTION_1.ITEM_1" | translate }}</h4>
          <h6 class="c-section__alt">{{ "ABOUT.SECTION_1.ITEM_1_ALT" | translate }}</h6>
        </div>
        <div class="l-grid__item">
          <h4>{{ "ABOUT.SECTION_1.ITEM_2" | translate }}</h4>
          <h6 class="c-section__alt">{{ "ABOUT.SECTION_1.ITEM_2_ALT" | translate }}</h6>
        </div>
        <div class="l-grid__item">
          <h4>{{ "ABOUT.SECTION_1.ITEM_3" | translate }}</h4>
          <h6 class="c-section__alt">{{ "ABOUT.SECTION_1.ITEM_3_ALT" | translate }}</h6>
        </div>
      </div>
    </div>
  </div>

  <!-- Section 2 -->
  <div class="l-container e-about__mission">
    <h2 class="c-section__title u-margin-bottom--lg">
      {{ 'ABOUT.SECTION_2.TITLE' | translate }}
    </h2>

    <div class="l-grid l-grid--3 u-margin-bottom--lg">
      <div class="l-grid__item">
        <h6>{{ 'ABOUT.SECTION_2.ITEM_1' | translate }}</h6>
        <p>{{ 'ABOUT.SECTION_2.ITEM_1_ALT' | translate }}</p>
      </div>
      <div class="l-grid__item">
        <h6>{{ 'ABOUT.SECTION_2.ITEM_2' | translate }}</h6>
        <p>{{ 'ABOUT.SECTION_2.ITEM_2_ALT' | translate }}</p>
      </div>
      <div class="l-grid__item">
        <h6>{{ 'ABOUT.SECTION_2.ITEM_3' | translate }}</h6>
        <p>{{ 'ABOUT.SECTION_2.ITEM_3_ALT' | translate }}</p>
      </div>
    </div>
  </div>

  <div class="e-about__values">
    <div class="l-container">
      <!-- Section 3 -->
      <div class="c-section">
        <h6 class="c-section__alt u-color--black u-margin-top--none">
          {{ 'ABOUT.SECTION_3.TITLE_ALT' | translate }}
        </h6>

        <h2>
          {{ 'ABOUT.SECTION_3.TITLE' | translate }}
        </h2>

        <div class="l-grid l-grid--3 u-margin-top--lg">
          <div class="l-grid__item c-card c-card--terciary">
            <app-icon name="accessibility-outline"></app-icon>
            <h6>
              {{ 'ABOUT.SECTION_3.ITEM_1' | translate }}
            </h6>
            <p>
              {{ 'ABOUT.SECTION_3.ITEM_1_ALT' | translate }}
            </p>
          </div>
          <div class="l-grid__item c-card c-card--terciary">
            <app-icon name="compassion-outline" [fill]="true" [stroke]="false"></app-icon>
            <h6>
              {{ 'ABOUT.SECTION_3.ITEM_2' | translate }}
            </h6>
            <p>
              {{ 'ABOUT.SECTION_3.ITEM_2_ALT' | translate }}
            </p>
          </div>
          <div class="l-grid__item c-card c-card--terciary">
            <app-icon name="bulb-outline"></app-icon>
            <h6>
              {{ 'ABOUT.SECTION_3.ITEM_3' | translate }}
            </h6>
            <p>
              {{ 'ABOUT.SECTION_3.ITEM_3_ALT' | translate }}
            </p>
          </div>
          <div class="l-grid__item c-card c-card--terciary">
            <app-icon name="people-outline"></app-icon>
            <h6>
              {{ 'ABOUT.SECTION_3.ITEM_4' | translate }}
            </h6>
            <p>
              {{ 'ABOUT.SECTION_3.ITEM_4_ALT' | translate }}
            </p>
          </div>
          <div class="l-grid__item c-card c-card--terciary">
            <app-icon name="quality-outline" [fill]="true" [stroke]="false"></app-icon>
            <h6>
              {{ 'ABOUT.SECTION_3.ITEM_5' | translate }}
            </h6>
            <p>
              {{ 'ABOUT.SECTION_3.ITEM_5_ALT' | translate }}
            </p>
          </div>
          <div class="l-grid__item c-card c-card--terciary">
            <app-icon name="handshake-outline" [fill]="true" [stroke]="false"></app-icon>
            <h6>
              {{ 'ABOUT.SECTION_3.ITEM_6' | translate }}
            </h6>
            <p>
              {{ 'ABOUT.SECTION_3.ITEM_6_ALT' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Section 4 -->
  <div class="e-about__team">
    <div class="l-container">
      <h2 class="c-section__title u-margin-bottom--md">
        {{ 'ABOUT.SECTION_4.TITLE' | translate }}
      </h2>
    </div>

    <div class="c-carousel c-carousel--flat l-container">
      <label class="e-about__team-member">
        <input type="checkbox">
        <div class="e-about__team-item">
          <img loading="lazy" alt="Serota" src="./assets/images/team/serota.jpg">
          <div class="e-about__team-text">
            <p>{{ 'ABOUT.SECTION_4.ITEM_3_DESCRIPTION' | translate }}</p>
          </div>
        </div>

        <h6>
          {{ 'ABOUT.SECTION_4.ITEM_3' | translate }}
          <app-icon name="add-circle-outline" class="u-margin-left--auto"></app-icon>
        </h6>
        <p>{{ 'ABOUT.SECTION_4.ITEM_3_ALT' | translate }}</p>
      </label>

      <label class="e-about__team-member">
        <input type="checkbox">
        <div class="e-about__team-item">
          <img loading="lazy" alt="Yari" src="./assets/images/team/yari.jpg">
          <div class="e-about__team-text">
            <p>{{ 'ABOUT.SECTION_4.ITEM_2_DESCRIPTION' | translate }}</p>
          </div>
        </div>

        <h6>
          {{ 'ABOUT.SECTION_4.ITEM_2' | translate }}
          <app-icon name="add-circle-outline" class="u-margin-left--auto"></app-icon>
        </h6>
        <p>{{ 'ABOUT.SECTION_4.ITEM_2_ALT' | translate }}</p>
      </label>

      <label class="e-about__team-member">
        <input type="checkbox">
        <div class="e-about__team-item">
          <img loading="lazy" alt="Tadra" src="./assets/images/team/tadra.jpg">
          <div class="e-about__team-text">
            <p>{{ 'ABOUT.SECTION_4.ITEM_4_DESCRIPTION' | translate }}</p>
          </div>
        </div>

        <h6>
          {{ 'ABOUT.SECTION_4.ITEM_4' | translate }}
          <app-icon name="add-circle-outline" class="u-margin-left--auto"></app-icon>
        </h6>
        <p>{{ 'ABOUT.SECTION_4.ITEM_4_ALT' | translate }}</p>
      </label>

      <label class="e-about__team-member">
        <input type="checkbox">
        <div class="e-about__team-item">
          <img loading="lazy" alt="Cleman" src="./assets/images/team/cleman.jpg">
          <div class="e-about__team-text">
            <p>{{ 'ABOUT.SECTION_4.ITEM_5_DESCRIPTION' | translate }}</p>
          </div>
        </div>

        <h6>
          {{ 'ABOUT.SECTION_4.ITEM_5' | translate }}
          <app-icon name="add-circle-outline" class="u-margin-left--auto"></app-icon>
        </h6>
        <p>{{ 'ABOUT.SECTION_4.ITEM_5_ALT' | translate }}</p>
      </label>

      <label class="e-about__team-member">
        <input type="checkbox">
        <div class="e-about__team-item">
          <img loading="lazy" alt="Yari" src="./assets/images/team/conrad.jpg">
          <div class="e-about__team-text">
            <p>{{ 'ABOUT.SECTION_4.ITEM_1_DESCRIPTION' | translate }}</p>
          </div>
        </div>

        <h6>
          {{ 'ABOUT.SECTION_4.ITEM_1' | translate }}
          <app-icon name="add-circle-outline" class="u-margin-left--auto"></app-icon>
        </h6>
        <p>{{ 'ABOUT.SECTION_4.ITEM_1_ALT' | translate }}</p>
      </label>

      <label class="e-about__team-member">
        <input type="checkbox">
        <div class="e-about__team-item">
          <img loading="lazy" alt="Ramos" src="./assets/images/team/ramos.jpg">
          <div class="e-about__team-text">
            <p>{{ 'ABOUT.SECTION_4.ITEM_8_DESCRIPTION' | translate }}</p>
          </div>
        </div>

        <h6>
          {{ 'ABOUT.SECTION_4.ITEM_8' | translate }}
          <app-icon name="add-circle-outline" class="u-margin-left--auto"></app-icon>
        </h6>
        <p>{{ 'ABOUT.SECTION_4.ITEM_8_ALT' | translate }}</p>
      </label>

      <label class="e-about__team-member">
        <input type="checkbox">
        <div class="e-about__team-item">
          <img loading="lazy" alt="Corey" src="./assets/images/team/corey.jpg">
          <div class="e-about__team-text">
            <p>{{ 'ABOUT.SECTION_4.ITEM_6_DESCRIPTION' | translate }}</p>
          </div>
        </div>

        <h6>
          {{ 'ABOUT.SECTION_4.ITEM_6' | translate }}
          <app-icon name="add-circle-outline" class="u-margin-left--auto"></app-icon>
        </h6>
        <p>{{ 'ABOUT.SECTION_4.ITEM_6_ALT' | translate }}</p>
      </label>

      <label class="e-about__team-member">
        <input type="checkbox">
        <div class="e-about__team-item">
          <img loading="lazy" alt="Tacey" src="./assets/images/team/tacey.jpg">
          <div class="e-about__team-text">
            <p>{{ 'ABOUT.SECTION_4.ITEM_7_DESCRIPTION' | translate }}</p>
          </div>
        </div>

        <h6>
          {{ 'ABOUT.SECTION_4.ITEM_7' | translate }}
          <app-icon name="add-circle-outline" class="u-margin-left--auto"></app-icon>
        </h6>
        <p>{{ 'ABOUT.SECTION_4.ITEM_7_ALT' | translate }}</p>
      </label>
    </div>
  </div>

  <div class="e-about__testimonials">
    <div class="l-container">
      <!-- Section 5 -->
      <div class="c-section">
        <h6 class="c-section__alt u-margin-bottom--xl">
          {{ 'ABOUT.SECTION_5.TITLE' | translate }}
        </h6>

        <div class=" l-grid l-grid--2 u-margin-bottom--md">
          <div class="l-grid__item">
            <app-icon name="quotes-outline"></app-icon>
            <div class="u-width--100">
              <h5>
                {{ 'ABOUT.SECTION_5.ITEM_1' | translate }}
              </h5>
              <h5 class="u-margin-top--md">
                — {{ 'ABOUT.SECTION_5.ITEM_1_ALT' | translate }}
              </h5>
            </div>
          </div>
          <div class="l-grid__item"></div>
        </div>

        <div class="l-grid l-grid--2">
          <div class="l-grid__item"></div>
          <div class="l-grid__item">
            <app-icon name="quotes-outline"></app-icon>
            <div class="u-width--100">
              <h5>
                {{ 'ABOUT.SECTION_5.ITEM_2' | translate }}
              </h5>
              <h5 class="u-margin-top--md">
                — {{ 'ABOUT.SECTION_5.ITEM_2_ALT' | translate }}
              </h5>
            </div>
          </div>
        </div>
      </div>

      <!-- Section 6 -->
      <div class="e-about__careers c-card c-card--white c-card--h u-margin-top--lg">
        <div class="l-grid l-grid--2">
          <div class="l-grid__item">
            <img loading="lazy" alt="Careers" src="./assets/images/careers.webp">
          </div>
          <div class="l-grid__item">
            <div class="u-max-width--26rem">
              <h1 class="c-section__title u-margin-bottom--md">
                {{ 'ABOUT.SECTION_6.TITLE' | translate }}
              </h1>

              <p class="c-section__description">
                {{ 'ABOUT.SECTION_6.DESCRIPTION' | translate }}
              </p>

              <div class="u-display--flex u-margin-top--lg">
                <a [routerLink]="['/', 'contact']" class="c-btn c-btn--secondary">
                  {{ 'ABOUT.SECTION_6.CTA' | translate}}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>