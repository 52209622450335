import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'data',
    loadChildren: () => import('./modules/privacy/privacy.module').then((m) => m.PrivacyModule),
  },
  {
    path: 'partner',
    data: {
      header: 'partner',
    },
    loadChildren: () => import('./modules/partner/partner.module').then((m) => m.PartnerModule),
  },
  {
    path: 'admin',
    data: {
      header: 'admin',
    },
    loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: '',
    loadChildren: () => import('./modules/home/home-ext.module').then((m) => m.HomeExtModule),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: NoPreloading,
      anchorScrolling: 'enabled',
      scrollOffset: [0, 64],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
