<div class="c-avatar" [class.c-avatar--full]="size === 'full'"
  [class.c-avatar--sm2]="size === 'sm2'" [class.c-avatar--sm]="size === 'sm'"
  [class.c-avatar--xs]="size === 'xs'" [class.c-avatar--online]="online">
  <img *ngIf="!src && !name; else picture" src="assets/images/placeholder-user.png" />

  <ng-template #picture>
    <img [src]="src" onerror="this.style.display='none'" />
  </ng-template>

  <span class="c-avatar__name" *ngIf="name">
    {{ avatarPlaceholder }}
  </span>

  <div *ngIf="loading" class="c-avatar__loading">
    <app-loading size="bare"></app-loading>
  </div>
</div>