import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  Renderer2,
} from '@angular/core';
import { ModalService } from 'angular-modal-simple';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements AfterViewInit, OnDestroy {
  @Input() hasClose = true;

  @Output() close: EventEmitter<any> = new EventEmitter();

  mouseDownListener;

  mouseEngaged = false;

  constructor(
    private modalService: ModalService,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {}

  @HostListener('document:keydown.escape', ['$event'])
  dismiss() {
    this.close.emit();
  }

  ngAfterViewInit() {
    this.setupEvents();
  }

  ngOnDestroy() {
    if (this.mouseDownListener) {
      this.mouseDownListener();
    }
  }

  mouseDownOnBody(e) {
    if (['INPUT', 'SELECT', 'TEXTAREA', 'BUTTON'].indexOf(document.activeElement.nodeName) > -1) {
      e.stopPropagation();
    }

    this.setDismissable(false);
  }

  private setupEvents() {
    const parent = this.elementRef.nativeElement.closest('.ngm-modal-container');

    this.mouseDownListener = this.renderer.listen(parent, 'mousedown', () => {
      this.setDismissable(true);
    });
  }

  // taps into inner functions to enable/disable mask
  private setDismissable(flag: boolean) {
    (<any>this.modalService).activeComponents.forEach((modal) => {
      // eslint-disable-next-line no-param-reassign
      modal.ref.instance.dismissibleMask = flag;
    });
  }
}
