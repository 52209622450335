<app-modal class="e-ticket-details-modal" (close)="dismiss()">
  <h3 class="c-modal__title">
    {{ 'MODALS.TICKET_DETAILS.TITLE' | translate }}
  </h3>

  <h6 class="e-ticket-details-modal__label">
    {{ 'MODALS.TICKET_DETAILS.FORM.TITLE' | translate }}
  </h6>
  <p>
    {{ ticket.title }}
  </p>

  <h6 class="e-ticket-details-modal__label">
    {{ 'MODALS.TICKET_DETAILS.FORM.DESCRIPTION' | translate }}
  </h6>
  <p>
    {{ ticket.description }}
  </p>

  <div class="l-grid l-grid--2">
    <div class="l-grid__item">
      <h6 class="e-ticket-details-modal__label">
        {{ 'MODALS.TICKET_DETAILS.FORM.ENVIRONMENT' | translate }}</h6>
      <p>{{ ticket.environment }}</p>
    </div>

    <div class="l-grid__item">
      <h6 class="e-ticket-details-modal__label">
        {{ 'MODALS.TICKET_DETAILS.FORM.PRODUCT' | translate }}</h6>
      <p>{{ ticket.product }}</p>
    </div>
  </div>

  <h6 class="e-ticket-details-modal__label">
    {{ 'MODALS.TICKET_DETAILS.FORM.REQUESTER' | translate }}
  </h6>
  <p>
    {{ ticket.requester_email }}
  </p>

  <div class="l-grid l-grid--2">
    <div class="l-grid__item">
      <h6 class="e-ticket-details-modal__label">
        {{ 'MODALS.TICKET_DETAILS.FORM.CREATED_AT' | translate }}</h6>
      <p>{{ ticket.created_at | date:'MM/dd/yyyy h:mm a' }}</p>
    </div>

    <div class="l-grid__item">
      <h6 class="e-ticket-details-modal__label">
        {{ 'MODALS.TICKET_DETAILS.FORM.STATUS' | translate }}</h6>
      <p>{{ ticket.status }}</p>
    </div>
  </div>

  <div class="c-modal__footer">
    <app-button class="c-modal__footer-alt" (click)="deleteTicket()" [loading]="loading"
      variant="secondary">
      {{ 'BUTTONS.REMOVE' | translate }}
    </app-button>

    <app-button (click)="dismiss()" variant="secondary">
      {{ 'BUTTONS.CLOSE' | translate }}
    </app-button>
  </div>
</app-modal>